// Package dependencies.
import { Typography } from "@mui/material";
import React, { ReactNode } from "react";

// Local dependencies.
import { OrderType, ProductSelections } from "../../store/engines";
import { Buyer } from "../../types/UsageInformation";
import OrderLeadForm from "./OrderLeadForm";

interface OrderLeadProps {
    buyer: Buyer;
    Information: ReactNode;
    interestedInLandBasedCharger: boolean;
    interestedInRangeExtender: boolean;
    isMobile: boolean;
    orderType: OrderType;
    productSelections: ProductSelections;
}

export default OrderLead;

function OrderLead({
    buyer,
    Information,
    interestedInLandBasedCharger,
    interestedInRangeExtender,
    isMobile,
    orderType,
    productSelections,
}: OrderLeadProps) {
    return (
        <>
            {isMobile && Information}
            <Typography component="h2" variant="h5">
                YOUR <b>CONTACT INFORMATION</b>
            </Typography>
            {(orderType === OrderType.Order || orderType === OrderType.PreOrder) && (
                <Typography>
                    Your information is about to be registered with Evoy and you will be contacted by our sales team
                    within a few business days.
                </Typography>
            )}
            <OrderLeadForm
                buyer={buyer}
                interestedInLandBasedCharger={interestedInLandBasedCharger}
                interestedInRangeExtender={interestedInRangeExtender}
                orderType={orderType}
                productSelections={productSelections}
            />
        </>
    );
}
