export enum CountryCode {
    Austria = "AT",
    // Australia = "AU",
    Belgium = "BE",
    // Canada = "CA",
    Croatia = "HR",
    Czechia = "CZ",
    Denmark = "DK",
    Estonia = "EE",
    France = "FR",
    Germany = "DE",
    Greece = "GR",
    Iceland = "IS",
    Ireland = "IE",
    Italy = "IT",
    Netherlands = "NL",
    // NewZealand = "NZ",
    Norway = "NO",
    Poland = "PL",
    Portugal = "PT",
    Spain = "ES",
    Sweden = "SW",
    UnitedKingdom = "GB",
    // UnitedStates = "US",
}

export enum ProductsConfigCode {
    Norway = "EU", // TODO: set to NO when prices in NOK should be enabled.
    Europe = "EU",
}

export interface Region {
    code: RegionCode;
    name: string;
    countries: Country[];
}

export enum RegionCode {
    Asia = "AS",
    Europe = "EU",
    NorthAmerica = "NA",
    Pacific = "PA",
    MiddleEast = "ME",
}

export interface Country {
    code: CountryCode;
    name: string;
    language: string; // TODO: should be a list of supported languages.
    region: RegionCode;
    products: ProductsConfigCode;
}
