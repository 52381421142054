// Package dependencies.
import { Alert, Button, CircularProgress } from "@mui/material";
import React, { memo, useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

// Local dependencies.
import { FormErrors, getOrderValidationErrors, submitOrderRequest } from "../../common/orderHelper";
import { findOrderType } from "../../common/utils";
import { ApplicationState } from "../../store";
import { changeContactInfo, OrderType, ProductSelections } from "../../store/engines";
import { Buyer } from "../../types/UsageInformation";
import ContactInformation from "./ContactInformation";
import { useDispatch } from "react-redux";
import { useLocalization } from "../Localization/LocalizationProvider";

interface OrderLeadFormProps {
    buyer: Buyer;
    interestedInLandBasedCharger: boolean;
    interestedInRangeExtender: boolean;
    orderType: OrderType;
    productSelections: ProductSelections;
}

export default memo(OrderLeadForm);

function OrderLeadForm({
    buyer,
    interestedInLandBasedCharger,
    interestedInRangeExtender,
    orderType,
    productSelections,
}: OrderLeadFormProps) {
    const { contactInformation, formErrors, isSubmitting, orderSubmitError, submit } = useOrderLeadForm();
    const onSubmit = () => submit(productSelections, buyer, interestedInLandBasedCharger, interestedInRangeExtender);
    const dispatch = useDispatch();
    const onChangeFormField = useCallback(
        (name: string, value: string | boolean) => dispatch(changeContactInfo([name, value])),
        [dispatch]
    );
    return (
        <>
            <ContactInformation
                contactInformation={contactInformation}
                defaultCountry="NO"
                formErrors={formErrors}
                onChangeFormField={onChangeFormField}
            />
            {orderSubmitError && (
                <Alert severity="error">Could not send the {orderType.toLowerCase()}. Please try again later.</Alert>
            )}
            <Button disabled={isSubmitting} onClick={onSubmit} size="large" sx={{ marginTop: 4 }} variant="contained">
                {isSubmitting && (
                    <>
                        <CircularProgress color="inherit" size={20} />
                        &nbsp;
                    </>
                )}
                {orderType === OrderType.Lead ? "KEEP ME UPDATED" : "CONTACT ME"}
            </Button>
        </>
    );
}

function useOrderLeadForm() {
    const { currency } = useLocalization();
    const [orderSubmitError, setOrderSubmitError] = useState<unknown>(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formErrors, setFormErrors] = useState<FormErrors>({});
    const { contactInformation } = useSelector((state: ApplicationState) => state.evoyState);
    const navigate = useNavigate();
    const submit = useCallback(
        async (
            productSelections: ProductSelections,
            buyer: Buyer,
            interestedInLandBasedCharger: boolean,
            interestedInRangeExtender: boolean
        ) => {
            // Start submit.
            setIsSubmitting(true);

            // Check for form errors.
            const orderType = findOrderType(productSelections);
            const formErrors = getOrderValidationErrors(contactInformation, orderType);
            setFormErrors(formErrors);

            // Cancel the order request if form contains errors.
            if (Object.keys(formErrors).length !== 0) {
                setIsSubmitting(false);
                return;
            }

            // Perform the order request.
            try {
                await submitOrderRequest(
                    productSelections,
                    buyer,
                    contactInformation,
                    interestedInLandBasedCharger,
                    interestedInRangeExtender,
                    currency.code
                );

                // Redirect to confirmation page on success.
                await navigate("/confirmation");
            } catch (error) {
                // Catch and return potential errors from the order request.
                setOrderSubmitError(error);
            }

            // Submit has completed.
            setIsSubmitting(false);
        },
        [contactInformation]
    );

    // Return states and submit handler.
    return {
        formErrors,
        orderSubmitError,
        isSubmitting,
        contactInformation,
        submit,
    };
}
