import O_BS_MAIN from "../assets/motors/O_BS_main.png";
import O_BS_THUMBNAIL from "../assets/motors/O_BS_thumbnail.png";
import O_GS_MAIN from "../assets/motors/O_GS_main.png";
import O_GS_THUMBNAIL from "../assets/motors/O_GS_thumbnail.png";
import O_HS_MAIN from "../assets/motors/O_HS_main.png";
import O_HS_THUMBNAIL from "../assets/motors/O_HS_thumbnail.png";
import O_SS_MAIN from "../assets/motors/O_SS_main.png";
import O_SS_THUMBNAIL from "../assets/motors/O_SS_thumbnail.png";
import I_MAIN from "../assets/inboard_motor-cropped.png";
import I_THUMBNAIL from "../assets/inboard_small.png";
import I_BS_MAIN from "../assets/motors/I_BS_main.png";
import I_BS_THUMBNAIL from "../assets/motors/I_BS_thumbnail.png";
import I_SS_MAIN from "../assets/motors/I_SS_main.png";
import I_SS_THUMBNAIL from "../assets/motors/I_SS_thumbnail.png";
import { MotorPlacementCode, MotorSeriesCode } from "../store/engines/motor";

interface MotorTypeImages {
    main: string;
    thumbnail: string;
}

const MOTOR_IMAGES: { [key: string]: MotorTypeImages } = {
    I: {
        main: I_MAIN,
        thumbnail: I_THUMBNAIL,
    },
    "I-BS": {
        main: I_BS_MAIN,
        thumbnail: I_BS_THUMBNAIL,
    },
    "I-SS": {
        main: I_SS_MAIN,
        thumbnail: I_SS_THUMBNAIL,
    },
    "O-BS": {
        main: O_BS_MAIN,
        thumbnail: O_BS_THUMBNAIL,
    },
    "O-GS": {
        main: O_GS_MAIN,
        thumbnail: O_GS_THUMBNAIL,
    },
    "O-HS": {
        main: O_HS_MAIN,
        thumbnail: O_HS_THUMBNAIL,
    },
    "O-SS": {
        main: O_SS_MAIN,
        thumbnail: O_SS_THUMBNAIL,
    },
    O: {
        main: O_BS_MAIN,
        thumbnail: O_BS_THUMBNAIL,
    },
};

export function getMotorImageURL(
    placement: MotorPlacementCode,
    series: MotorSeriesCode,
    type: "main" | "thumbnail"
): string {
    const key = `${placement}-${series}`;
    const images = key in MOTOR_IMAGES ? MOTOR_IMAGES[key as keyof typeof MOTOR_IMAGES] : MOTOR_IMAGES[placement];
    return images[type];
}
