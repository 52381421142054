// Package dependencies.
import OpenInNew from "@mui/icons-material/OpenInNew";
import { Grid, Link, Typography } from "@mui/material";
import { MuiTelInputCountry } from "mui-tel-input";
import { memo } from "react";

// Local dependencies.
import { FormErrors } from "../../common/orderHelper";
import { ContactInformation as IContactInformation } from "../../store/engines";
import FormField from "../Form/FormField";
import { FormFieldProps } from "../Form/types";

interface ContactInformationProps {
    contactInformation: IContactInformation;
    defaultCountry?: MuiTelInputCountry;
    formErrors: FormErrors;
    onChangeFormField?: (name: string, value: string | boolean) => void;
}

const PrivacyPolicyConsent = (
    <Typography>
        I have read and accepted the&nbsp;
        <Link color="inherit" href="https://www.evoy.no/privacy-policy/" target="_blank">
            Privacy Policy
            <OpenInNew sx={{ verticalAlign: "middle" }} />
        </Link>
    </Typography>
);

export default memo(ContactInformation);

function ContactInformation({
    contactInformation,
    defaultCountry,
    formErrors,
    onChangeFormField,
}: ContactInformationProps) {
    const fields: FormFieldProps[] = [
        {
            type: "text",
            error: formErrors.Firstname && formErrors.Firstname[0],
            label: "First Name",
            name: "firstname",
            required: true,
            value: contactInformation.firstname || undefined,
            grid: { xs: 12, md: 6 },
        },
        {
            type: "text",
            error: formErrors.Lastname && formErrors.Lastname[0],
            label: "Last Name",
            name: "lastname",
            required: true,
            value: contactInformation.lastname || undefined,
            grid: { xs: 12, md: 6 },
        },
        {
            type: "text",
            error: formErrors.Businessname && formErrors.Businessname[0],
            label: "Company",
            name: "businessname",
            required: true,
            value: contactInformation.businessname || undefined,
        },
        {
            type: "text",
            error: formErrors.Email && formErrors.Email[0],
            label: "Email",
            name: "email",
            required: true,
            value: contactInformation.email || undefined,
            grid: { xs: 12, md: 6 },
        },
        {
            defaultCountry,
            error: formErrors.Phone && formErrors.Phone[0],
            label: "Phone Number",
            name: "phone",
            required: true,
            type: "phone",
            value: contactInformation.phone || undefined,
            grid: { xs: 12, md: 6 },
        },
        {
            type: "text",
            error: formErrors.Description && formErrors.Description[0],
            label: "Describe the boat - brand, model, type, size",
            name: "boatDescription",
            required: true,
            value: contactInformation.boatDescription || undefined,
            rows: 4,
            grid: { xs: 12 },
        },
        {
            type: "select",
            error: formErrors.LeadSource && formErrors.LeadSource[0],
            label: "Where have you heard about us?",
            name: "leadSource",
            required: true,
            value: contactInformation.leadSource || "",
            grid: { xs: 12 },
            other: {
                type: "other",
                error: formErrors.LeadSourceOther && formErrors.LeadSourceOther[0],
                label: "Please specify where",
                name: "leadSourceOther",
                required: true,
                value: contactInformation.leadSourceOther || undefined,
            },
        },
        {
            error: formErrors.PrivacyPolicy && formErrors.PrivacyPolicy[0],
            label: PrivacyPolicyConsent,
            name: "privacyPolicy",
            type: "checkbox",
            value: contactInformation.privacyPolicy,
        },
        {
            error: formErrors.SubscribeToNewsletter && formErrors.SubscribeToNewsletter[0],
            label: "Sign up for Evoy newsletter.",
            name: "subscribeToNewsletter",
            type: "checkbox",
            grid: { rowSpacing: 1 },
            gridClass: "checkbox-field",
            value: contactInformation.subscribeToNewsletter,
        },
    ];
    return (
        <Grid container spacing={2}>
            {fields.map((field) => (
                <FormField key={field.name} {...field} onChange={onChangeFormField} />
            ))}
        </Grid>
    );
}
