// Package dependencies.
import { Input, Slider } from "@mui/material";
import React, { useState } from "react";

interface InputSliderProps {
    max: number;
    min: number;
    onChange: (value: number) => void;
    step: number;
    suffix: string;
    value: number;
}

// Export InputSlider as default component.
export default InputSlider;

/**
 * Renders a slider and input element.
 *
 * @param props The related component props.
 */
function InputSlider({ max, min, onChange, step, suffix, value }: InputSliderProps): JSX.Element {
    const [inputValue, setInputValue] = useState(value);

    return (
        <div className="row center-align slider-block">
            <Slider
                value={inputValue}
                onChange={(_, value) => setInputValue(range(flattenNumber(value), min, max))}
                onChangeCommitted={(_, value) => onChange(flattenNumber(value))}
                aria-labelledby="input-slider"
                min={min}
                max={max}
                step={step}
                marks={[
                    { value: min, label: min },
                    { value: max, label: max },
                ]}
                sx={{ width: "70%", mr: 2 }}
            />
            <Input
                value={inputValue}
                margin="dense"
                onChange={({ target: { value } }) => setInputValue(range(parseFloat(value), min, max))}
                onBlur={({ target: { value } }) => onChange(parseFloat(value))}
                inputProps={{
                    step,
                    min,
                    max,
                    type: "number",
                    "aria-labelledby": "input-slider",
                }}
            />
            <span className="input-suffix">{suffix}</span>
        </div>
    );
}

/**
 * Limits a given value to within a range. Returns max if value is above max,
 * returns min if value is below min, or returns value if it is within the range
 * of min and max.
 *
 * @param value The desired value.
 * @param min The minimum value allowed in range.
 * @param max The maximum value allowed in range.
 */
function range(value: number, min: number, max: number): number {
    return value > max ? max : value < min ? min : value;
}

/**
 * Flattens the array to a number if the provided input is an array.
 *
 * @param input The provided input value.
 */
function flattenNumber(input: number | number[]): number {
    return typeof input === "number" ? input : input[0];
}
