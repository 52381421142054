// Package dependencies.
import React, { ComponentType } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { Navigate } from "react-router-dom";
import { getLocalizedUrl, useLocalization } from "../components/Localization/LocalizationProvider";

// Local dependencies.
import { useProductSelections } from "../components/Providers/ProductSelectionsProvider";
import { ApplicationState } from "../store";
import { PageLocation } from "./page";

export function withDirectAccessGuard<Props extends object>(
    WrappedComponent: ComponentType<Props>
): (props: Props) => JSX.Element {
    return function DirectAccessGuard(props: Props): JSX.Element {
        const { pathname } = useLocation();
        const {
            Engine: hasVisitedLandingPage,
            Information,
            Summary,
        } = useSelector(({ navigationState }: ApplicationState) => navigationState);
        const { hasPreconfiguredDesign } = useProductSelections();
        const { country } = useLocalization();

        // Allow access to page if one of the following conditions are met.
        const allowAccess = Boolean(
            pathname === getLocalizedUrl(country, PageLocation.Engine)
                ? // On landing page, no need to redirect to it.
                  true
                : pathname === getLocalizedUrl(country, PageLocation.Confirmation)
                ? // On confirmation page and has completed order, preorder, or
                  // lead.
                  Information || Summary
                : pathname === getLocalizedUrl(country, PageLocation.Summary)
                ? // On summary page and has visited landing page, or completed
                  // payment.
                  hasVisitedLandingPage ||
                  hasPreconfiguredDesign ||
                  new URLSearchParams(window.location.search).get("payment_intent_client_secret")
                : // On any other page and has visited landing page, or has a
                  // preconfigured design and visited summary page.
                  hasVisitedLandingPage || (hasPreconfiguredDesign && Summary)
        );

        // Redirect the user if necessary, or render the original component.
        return allowAccess ? (
            <WrappedComponent {...props} />
        ) : (
            <Navigate replace to={getLocalizedUrl(country, PageLocation.Engine)} />
        );
    };
}
