import Grid from "@mui/material/Grid";
import { memo } from "react";

import CheckboxField from "./CheckboxField";
import PhoneField from "./PhoneField";
import TextField from "./TextField";
import SelectField from "./SelectField";
import { FormFieldProps } from "./types";
import "./form.scss";

export default memo(FormField);

function FormField(props: FormFieldProps) {
    const grid = props.grid || { xs: 12 };
    const gridClass = props.gridClass || "";
    return (
        <>
            {props.type === "select" ? (
                <SelectField {...props} />
            ) : (
                <Grid item {...grid} className={gridClass}>
                    {props.type === "phone" ? (
                        <PhoneField {...props} />
                    ) : props.type === "checkbox" ? (
                        <CheckboxField {...props} />
                    ) : props.type === "text" ? (
                        <TextField {...props} />
                    ) : (
                        <></>
                    )}
                </Grid>
            )}
        </>
    );
}
