// Package dependencies.
import MuiTextField from "@mui/material/TextField";
import { ChangeEvent } from "react";

// Local dependencies.
import { defaultTextFieldProps } from "./helper";
import { TextFieldProps } from "./types";

export default TextField;

function TextField({ error, label, name, onChange, required, rows, value }: TextFieldProps) {
    const props = {
        ...defaultTextFieldProps,
        error: !!error,
        helperText: error,
        label,
        multiline: !!rows,
        name,
        onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            onChange && onChange(name, event.target.value),
        required,
        rows,
        value,
    };
    return <MuiTextField {...props} />;
}
