export interface ChargerType {
    code: ChargerTypeCode;
    description: string;
    name: string;
}

export enum ChargerTypeCode {
    AC1 = "AC1",
    AC2 = "AC2",
    AC3 = "AC3",
    AC4 = "AC4",
    AC5 = "AC5",
}

export const chargerTypes: Record<ChargerTypeCode, ChargerType> = {
    [ChargerTypeCode.AC1]: {
        code: ChargerTypeCode.AC1,
        description: "Requires 1 phase – 32 Amp – 230 VAC for full effect",
        name: "6,6 kW",
    },
    [ChargerTypeCode.AC2]: {
        code: ChargerTypeCode.AC2,
        description: "Requires 3 phase – 32 Amp – 230 VAC for full effect",
        name: "11 kW",
    },
    [ChargerTypeCode.AC3]: {
        code: ChargerTypeCode.AC3,
        description: "Requires 3 phase – 32 Amp – 400 VAC for full effect",
        name: "22 kW",
    },
    [ChargerTypeCode.AC4]: {
        code: ChargerTypeCode.AC4,
        description: "Requires 3 phase – 32 Amp – 400 VAC for full effect",
        name: "22 kW",
    },
    [ChargerTypeCode.AC5]: {
        code: ChargerTypeCode.AC5,
        description: "Requires 3 phase – 63 Amp – 400 VAC for full effect",
        name: "44 kW",
    },
};
