import * as React from "react";
import { BatteryProduct, ChargerProduct, ScreenProduct, OrderType, MotorProduct } from "../../store/engines";
import "../buttonlist.scss";
import "./summaryListItem.scss";
import SummaryListItem from "./SummaryListItem";
import { Buyer } from "../../types/UsageInformation";
import { batteryTypes } from "../../store/engines/battery";
import { motorPlacements } from "../../store/engines/motor";

type Props = {
    currencySymbol: string;
    motor: MotorProduct;
    battery: BatteryProduct;
    screen: ScreenProduct;
    acCharger: ChargerProduct;
    buyer: Buyer;
    totalPrice: number;
    orderType: OrderType;
};

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type SummaryListItemProps = Parameters<typeof SummaryListItem>[0];

const SummaryList = ({ acCharger, battery, buyer, currencySymbol, motor, orderType, screen, totalPrice }: Props) => {
    const shouldDisplayPrices = orderType !== OrderType.Lead;
    const summaryListItems = getSummaryListItems(
        acCharger,
        battery,
        buyer,
        currencySymbol,
        motor,
        screen,
        shouldDisplayPrices
    );

    // TODO: should total price really be a SummaryListItem? It differs quite a
    // lot visually from the rest
    return (
        <>
            <div className="buttonlist summary-list">
                {summaryListItems.map((props) => (
                    <SummaryListItem {...props} />
                ))}
                {shouldDisplayPrices && (
                    <SummaryListItem
                        currencySymbol={currencySymbol}
                        price={totalPrice}
                        nav={""}
                        type={"totalprice"}
                        selectedBuyer={buyer}
                        included={false}
                    ></SummaryListItem>
                )}
            </div>
        </>
    );
};

export default SummaryList;

/**
 * Construct the full list of properties for all the SummaryListItem components.
 *
 * @param acCharger
 * @param battery
 * @param batterySize
 * @param buyer
 * @param motor
 * @param motorType
 * @param screen
 * @param screenSize
 * @param shouldDisplayPrices
 */
function getSummaryListItems(
    acCharger: Props["acCharger"],
    battery: Props["battery"],
    buyer: Props["buyer"],
    currencySymbol: Props["currencySymbol"],
    motor: Props["motor"],
    screen: Props["screen"],
    shouldDisplayPrices: boolean
): SummaryListItemProps[] {
    const batteryType = batteryTypes[battery.type];
    const batteryPrice = battery.price;
    const motorPlacement = motorPlacements[motor.placement];
    const summaryListItems: PartialBy<SummaryListItemProps, "currencySymbol" | "included" | "selectedBuyer">[] = [
        {
            id: motor.code,
            nav: "/",
            price: motor.price,
            primaryTextBottom: motorPlacement.name,
            primaryTextTopBold: motor.name.replace("Series", ""),
            type: "motor",
        },
        {
            id: battery.code,
            nav: "/battery",
            price: batteryPrice,
            primaryTextBottom: battery.kwh + " KWH battery ",
            primaryTextTopBold: batteryType.name,
            type: "battery",
        },
        {
            id: screen.code,
            nav: "/options",
            type: "screen",
            primaryTextTopBold: screen.name,
            primaryTextBottom: "Touchscreen",
            price: screen.price,
            included: screen.price === 0,
        },
        {
            id: acCharger.code,
            nav: "/charger",
            type: "charger",
            primaryTextTopBold: "Onboard " + acCharger.name,
            primaryTextBottom: "AC charger",
            price: acCharger.price,
            included: acCharger.price === 0,
        },
    ];

    // Merge the list items with the default values and return the final list.
    return summaryListItems.map((props) => ({
        // Add default values for currencySymbol, included, key, and
        // selectedBuyer.
        currencySymbol,
        included: false,
        key: props.id,
        selectedBuyer: buyer,
        // Add item props
        ...props,
        // Set price to -1 if it should not be displayed.
        price: shouldDisplayPrices ? props.price : -1,
    }));
}
