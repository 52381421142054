// Package dependencies.
import React from "react";

// Local depedencies.
import navigationScreen from "../../assets/navigation-screen.png";
import screenLogo from "../../assets/screen.png";
import { ScreenType } from "../../store/engines/screen";

interface ScreenImagesProps {
    screen: ScreenType;
}

// Export ScreenImages as default component.
export default ScreenImages;

/**
 * Renders images for a given touch screen system.
 *
 * @param props The related component props.
 */
function ScreenImages({ screen }: ScreenImagesProps) {
    const alt = screen.name;
    const width = screen.name.includes("16") ? "50%" : "40%";
    const style = { transition: "width 0.1s linear 0s", width };

    // Define which images should be displayed.
    const images = [{ src: screenLogo }];
    if (screen.dual) {
        images.push({ src: navigationScreen });
    }

    // Render images.
    return (
        <div>
            {images.map(({ src }, index) => (
                <img style={style} key={`screen-image-${index}`} src={src} alt={alt} />
            ))}
        </div>
    );
}
