// Package dependencies.
import React, { createContext, ReactNode, useContext, useMemo, useReducer } from "react";

// Local dependencies.
import { configuration } from "../store/engines/configuration";
import { MotorPlacementCode, MotorSeriesCode } from "../store/engines/motor";
import { BoatCode, Buyer, BuyerCode, FuelCode, HullCode, UsageInformation } from "../types/UsageInformation";
import { convertQueryToConfig, getDefaultConfig } from "./constants";

type Action =
    | { type: "resetDefault"; placement: MotorPlacementCode; series: MotorSeriesCode | undefined }
    | { type: "setBoatCode"; value: BoatCode | string }
    | { type: "setBuyerCode"; value: BuyerCode | string }
    | { type: "setConsumption"; value: number }
    | { type: "setFuelCode"; value: FuelCode | string }
    | { type: "setHours"; value: number }
    | { type: "setHullCode"; value: HullCode }
    | { type: "setSpeed"; value: number };

interface ConfigurationContext {
    configuration: UsageInformation;
    resetDefault: (placement: MotorPlacementCode, series: MotorSeriesCode | undefined) => void;
    setBoatCode: (value: BoatCode | string) => void;
    setBuyerCode: (value: BuyerCode | string) => void;
    setConsumption: (value: number) => void;
    setFuelCode: (value: FuelCode | string) => void;
    setHours: (value: number) => void;
    setHullCode: (value: HullCode) => void;
    setSpeed: (value: number) => void;
}

interface ConfigurationProviderProps {
    children?: ReactNode;
    placement?: MotorPlacementCode;
    series?: MotorSeriesCode;
}

interface State {
    configuration: UsageInformation;
    userHasMadeConfigChanges: boolean;
}

const noop = () => {};
const { boat: boats, buyer: buyers } = configuration;
const initialState = getInitialState(MotorPlacementCode.Outboard, MotorSeriesCode.BreezeSeries);
const ConfigurationContext = createContext<ConfigurationContext>({
    configuration: initialState.configuration,
    resetDefault: noop,
    setBoatCode: noop,
    setBuyerCode: noop,
    setConsumption: noop,
    setFuelCode: noop,
    setHours: noop,
    setHullCode: noop,
    setSpeed: noop,
});

export function ConfigurationProvider({ children, placement, series }: ConfigurationProviderProps): JSX.Element {
    // Create the configuration state and dispatcher.
    const [state, dispatch] = useReducer(reducerConfiguration, getInitialState(placement, series));

    const handlers: Omit<ConfigurationContext, "configuration"> = useMemo(() => {
        return {
            resetDefault: (placement: MotorPlacementCode, series: MotorSeriesCode | undefined) =>
                dispatch({ type: "resetDefault", placement, series }),
            setBoatCode: (value: BoatCode | string) => dispatch({ type: "setBoatCode", value }),
            setBuyerCode: (value: BuyerCode | string) => dispatch({ type: "setBuyerCode", value }),
            setConsumption: (value: number) => dispatch({ type: "setConsumption", value }),
            setFuelCode: (value: FuelCode | string) => dispatch({ type: "setFuelCode", value }),
            setHours: (value: number) => dispatch({ type: "setHours", value }),
            setHullCode: (value: HullCode) => dispatch({ type: "setHullCode", value }),
            setSpeed: (value: number) => dispatch({ type: "setSpeed", value }),
        };
    }, [dispatch]);

    // Memoize context value reference and only update it if state changes.
    const value: ConfigurationContext = useMemo(() => {
        return {
            configuration: state.configuration,
            ...handlers,
        };
    }, [state, handlers]);

    // Render the context provider with values.
    return <ConfigurationContext.Provider value={value}>{children}</ConfigurationContext.Provider>;
}

function reducerConfiguration(state: State, action: Action): State {
    if (action.type === "resetDefault") {
        if (!state.userHasMadeConfigChanges) {
            const configuration = getDefaultConfig(action.placement, action.series);
            return updateConfiguration(state, configuration, false);
        }
    } else if (action.type === "setBoatCode") {
        const {
            boatCode,
            hull: [{ hullCode }],
        } = boats.find(({ boatCode }) => boatCode === action.value) ?? { hull: [{}] };
        if (boatCode && hullCode) {
            return updateConfiguration(state, { ...state.configuration, boatCode, hullCode });
        }
    } else if (action.type === "setBuyerCode") {
        const buyerCode: BuyerCode | null = action.value in BuyerCode ? (action.value as BuyerCode) : null;
        if (buyerCode) {
            return updateConfiguration(state, { ...state.configuration, buyerCode });
        }
    } else if (action.type === "setConsumption") {
        return updateConfiguration(state, { ...state.configuration, consumption: action.value });
    } else if (action.type === "setFuelCode") {
        const value: FuelCode | null = action.value in FuelCode ? (action.value as FuelCode) : null;
        if (value) {
            return updateConfiguration(state, { ...state.configuration, fuelCode: value });
        }
    } else if (action.type === "setHours") {
        return updateConfiguration(state, { ...state.configuration, hours: action.value });
    } else if (action.type === "setHullCode") {
        return updateConfiguration(state, { ...state.configuration, hullCode: action.value });
    } else if (action.type === "setSpeed") {
        return updateConfiguration(state, { ...state.configuration, speed: action.value });
    }

    // Return the current state if no changes are made.
    return state;
}

function getInitialState(placement?: MotorPlacementCode, series?: MotorSeriesCode): State {
    const url = new URL(window.location.href);
    const query = url.searchParams.get("configuration") || undefined;
    const configuration = convertQueryToConfig(query);

    // Return the initial state.
    return configuration
        ? { configuration, userHasMadeConfigChanges: true }
        : {
              configuration: getDefaultConfig(placement || MotorPlacementCode.Outboard, series),
              userHasMadeConfigChanges: false,
          };
}

function updateConfiguration(state: State, configuration: UsageInformation, updatedByUser: boolean = true): State {
    return {
        ...state,
        configuration,
        userHasMadeConfigChanges: updatedByUser || state.userHasMadeConfigChanges,
    };
}

export function useConfiguration(): ConfigurationContext {
    return useContext(ConfigurationContext);
}

export function useBuyer(): Buyer {
    const {
        configuration: { buyerCode },
    } = useConfiguration();
    return useMemo(() => {
        return buyers.find((buyer) => buyerCode === buyer.buyerCode)!;
    }, [buyerCode]);
}

export function useShouldApplyVat(): boolean {
    const { vat } = useBuyer();
    return vat;
}
