// Package dependencies.
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Tooltip from "@mui/material/Tooltip";
import React, { useState } from "react";
import { useSelector } from "react-redux";

// Local dependencies.
import { ApplicationState } from "../../store";
import { DischargeType } from "../../store/engines";
import { motorTypes } from "../../store/engines/motor";
import { useSelectedMotorTypeCode } from "../Providers/ProductSelectionsProvider";
import SelectDischargeType from "../SelectDischargeType/SelectDischargeType";
import Specification from "./Specification";

// Export SystemSpecPower as default component.
export default SystemSpecPower;

function SystemSpecPower() {
    const state = useSelector((state: ApplicationState) => state.evoyState);
    const motorTypeCode = useSelectedMotorTypeCode();
    const { continuousHP, continuousKW, peakHP, peakKW } = motorTypes[motorTypeCode];
    const useContinuous = state.dischargeType === DischargeType.Continuous;

    const power = useContinuous ? continuousHP : peakHP;
    const effect = useContinuous ? continuousKW : peakKW;

    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const handleTooltipClose = () => {
        setIsTooltipOpen(false);
    };

    const handleTooltipToggle = () => {
        setIsTooltipOpen(!isTooltipOpen);
    };

    const titlePower = `${power ?? "-"}${power && useContinuous ? "+" : ""} hp`;
    const titleEffect = `${effect ?? "—"}${effect && useContinuous ? "+" : ""} kW`;

    return (
        <Specification subtitle={titleEffect} title={titlePower}>
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <Tooltip
                    placement="top-start"
                    onClose={handleTooltipClose}
                    open={isTooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={<SelectDischargeType onSelected={handleTooltipClose} />}
                >
                    <button onClick={handleTooltipToggle}>{state.dischargeType}</button>
                </Tooltip>
            </ClickAwayListener>
            <span>&nbsp;Power</span>
        </Specification>
    );
}
