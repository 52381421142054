// Package dependencies.
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import { ReactNode } from "react";

// Local dependencies.
import gothamFontFaces from "../Fonts/Gotham";

interface ProviderProps {
    children?: ReactNode;
}

export const colors = {
    charcoal: "#131b1d",
    lightning: "#fff4b6",
    nightMode1: "#ff0000",
    nightMode2: "#000000",
    sage: "#6a6d6a",
    sea1: "#ccf1ff",
    sea2: "#00B0F3",
    speed: "#fad815",
    pearl: "#eef2f1",
    white: "#ffffff",
};

export const fontFamily = "Gotham, Sans-serif";

const theme = createTheme({
    components: {
        MuiAppBar: {
            variants: [
                {
                    props: { color: "default" },
                    style: {
                        color: colors.pearl,
                        backgroundColor: colors.charcoal,
                        borderBottom: `1px solid ${colors.pearl}`,
                        "a.active": {
                            color: colors.speed,
                        },
                    },
                },
            ],
        },
        MuiCssBaseline: {
            styleOverrides: gothamFontFaces,
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    color: colors.pearl,
                    backgroundColor: colors.charcoal,
                    ".MuiDivider-root": {
                        borderColor: colors.pearl,
                    },
                    ".MuiListItemButton-root": {
                        "&.Mui-selected": {
                            backgroundColor: colors.speed,
                            color: colors.charcoal,
                        },
                        "&:hover, &.Mui-focusVisible": {
                            backgroundColor: "rgba(255, 255, 255, 0.08)",
                            color: "inherit",
                        },
                    },
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    "&.MuiInputLabel-root.Mui-focused": {
                        color: "inherit",
                    },
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.white,
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    "&.MuiFilledInput-root, &.MuiInput-root": {
                        ":after": {
                            borderBottom: `2px solid ${colors.speed}`,
                        },
                        backgroundColor: colors.white,
                        ":hover, &.Mui-focused": {
                            backgroundColor: colors.white,
                        },
                    },
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    color: colors.speed,
                },
                thumb: {
                    ":active, :focus, :hover": {
                        boxShadow: "0px 0px 0px 8px #fad81529",
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: "white",
                    color: "rgba(0, 0, 0, 0.87)",
                    boxShadow: "0px 3px 6px #00000029;",
                    fontSize: 11,
                    maxWidth: "none",
                    border: "1px solid #B1B1B1",
                    padding: "20px",
                    margin: 0,
                },
            },
        },
    },
    palette: {
        background: {
            default: colors.pearl,
        },
        primary: {
            main: colors.speed,
            contrastText: colors.nightMode2,
        },
    },
    typography: {
        fontFamily,
    },
});

export default Provider;

function Provider({ children }: ProviderProps): JSX.Element {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </ThemeProvider>
    );
}
