import React from "react";
import { Provider } from "react-redux";
import { Store } from "redux";
import { ApplicationState } from "./store";
import Layout from "./components/Layout/Layout";
import Engine from "./components/Engine/Engine";
import Battery from "./components/Battery/Battery";
import Touchscreen from "./components/Touchscreen/Touchscreen";
import Charger from "./components/Charger/Charger";
import Summary from "./components/Summary/Summary";
import Confirmation from "./components/Confirmation/Confirmation";
import Information from "./components/Information/Information";
import { PageLocation } from "./common/page";
import { ConfigurationProvider } from "./common/configuration";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

// Local dependencies.
import { getLocalizedUrl, LocalizationProvider, useLocalization } from "./components/Localization/LocalizationProvider";
import ThemeProvider from "./components/Providers/ThemeProvider";
import { MotorPlacementCode, MotorSeriesCode } from "./store/engines/motor";
import ProductSelectionProvider from "./components/Providers/ProductSelectionsProvider";

// Any additional component props go here.
interface AppProps {
    store: Store<ApplicationState>;
}

// Create an intersection type of the component props and our Redux props.
const App: React.FC<AppProps> = ({ store }) => {
    // TODO: get placement and series defaults from ProductSelectionProvider.
    const placement = MotorPlacementCode.Outboard;
    const series = MotorSeriesCode.BreezeSeries;
    return (
        <BrowserRouter>
            <Provider store={store}>
                <LocalizationProvider>
                    <ProductSelectionProvider>
                        <ConfigurationProvider placement={placement} series={series}>
                            <ThemeProvider>
                                <Layout>
                                    <LocalizedRoutes />
                                </Layout>
                            </ThemeProvider>
                        </ConfigurationProvider>
                    </ProductSelectionProvider>
                </LocalizationProvider>
            </Provider>
        </BrowserRouter>
    );
};

function LocalizedRoutes() {
    const { country } = useLocalization();
    return (
        <Routes>
            <Route path={getLocalizedUrl(country, PageLocation.Engine)} element={<Engine />} />
            <Route path={getLocalizedUrl(country, PageLocation.Battery)} element={<Battery />} />
            <Route path={getLocalizedUrl(country, PageLocation.Screen)} element={<Touchscreen />} />
            <Route path={getLocalizedUrl(country, PageLocation.Information)} element={<Information />} />
            <Route path={getLocalizedUrl(country, PageLocation.Charger)} element={<Charger />} />
            <Route path={getLocalizedUrl(country, PageLocation.Summary)} element={<Summary />} />
            <Route path={getLocalizedUrl(country, PageLocation.Confirmation)} element={<Confirmation />} />
            {/* TODO: display a 404 page if no matching route is found.  */}
            {/* <Route path="*" element={<Navigate to={PageLocation.Engine} />} /> */}
        </Routes>
    );
}

// Normally you wouldn't need any generics here (since types infer from the passed functions).
// But since we pass some props from the `index.js` file, we have to include them.
// For an example of a `connect` function without generics, see `./containers/LayoutContainer`.
export default App;
