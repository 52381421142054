import React, { FunctionComponent, ReactNode, useState } from "react";
import NumberFormat from "react-number-format";
import ReactModal from "react-modal";
import "./BatteryComparison.scss";
import { BatteryPackCode, batteryPacks, batteryTypes } from "../../store/engines/battery";
import { motorPlacements, motorTypes } from "../../store/engines/motor";
import { useSelectedMotorTypeCode } from "../Providers/ProductSelectionsProvider";

type BatteryComparisonProps = {
    onRequestClose?(event: React.MouseEvent | React.KeyboardEvent): void;
};

type ContentKeys =
    | "headers"
    | "energySizes"
    | "units"
    | "dimensions"
    | "weight"
    | "lifetimeCycles"
    | "certification"
    | "peakDischarge"
    | "continuousDischarge"
    | "continuousCharge";

type TableContents = Record<ContentKeys, ReactNode[]>;

const BatteryComparison: FunctionComponent<BatteryComparisonProps> = ({ onRequestClose }) => {
    const selectedMotor = useSelectedMotorTypeCode();
    const { batteries, name, placement } = motorTypes[selectedMotor];
    const contents: TableContents = getTableContents(batteries);

    const motorPlacement = motorPlacements[placement];
    const motorName = `${motorPlacement.name} ${name}`.toUpperCase();

    const headers = contents.headers;
    const rows = [
        contents.energySizes,
        contents.units,
        contents.dimensions,
        contents.weight,
        contents.lifetimeCycles,
        contents.certification,
        contents.peakDischarge,
        contents.continuousDischarge,
        contents.continuousCharge,
    ];

    // TODO: replace table with a generic table component.
    return (
        <div className="battery-comparison">
            <button id="close-button" onClick={onRequestClose}>
                &times;
            </button>
            <div className="battery-comparison-inner">
                <h1>
                    <span>EVOY BATTERIES</span>&nbsp; FOR {motorName}
                </h1>
                <div className="table-container">
                    <table>
                        <thead>
                            <tr>{headers.map(renderHeader)}</tr>
                        </thead>
                        <tbody>{rows.map(renderRow)}</tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

function getTableContents(batteries: BatteryPackCode[]): TableContents {
    return batteries.reduce(
        (contents: TableContents, batteryPackCode) => {
            const { kwh, name, type, units } = batteryPacks[batteryPackCode];
            const {
                certification,
                continuousCharge,
                continuousDischarge,
                depthOfDischarge,
                dimensions,
                lifetimeCycles,
                name: batteryTypeName,
                peakDischarge,
                weight,
                weightAdditional,
            } = batteryTypes[type];

            contents.headers.push(`${batteryTypeName} ${name}`);
            contents.energySizes.push(
                <>
                    <span>{kwh}</span>
                    <span className="unit">kWh</span>
                </>
            );
            contents.units.push(<span>{units}</span>);
            contents.dimensions.push(<span>{dimensions}</span>);
            contents.weight.push(
                <>
                    <span>{`${units * weight} + ${weightAdditional}`}</span>
                    <span className="unit">kg</span>
                </>
            );
            contents.lifetimeCycles.push(
                <>
                    <span>
                        <NumberFormat
                            value={lifetimeCycles}
                            displayType={"text"}
                            thousandSeparator={"."}
                            decimalSeparator={","}
                        />
                    </span>
                    <span>
                        {" "}
                        (<span>{depthOfDischarge}%</span>)
                    </span>
                </>
            );
            contents.certification.push(<span>{certification}</span>);
            contents.peakDischarge.push(
                <>
                    <span>{peakDischarge}</span>
                    <span className="unit">C</span>
                </>
            );
            contents.continuousDischarge.push(
                <>
                    <span>{continuousDischarge}</span>
                    <span className="unit">C</span>
                </>
            );
            contents.continuousCharge.push(
                <>
                    <span>{continuousCharge}</span>
                    <span className="unit">C</span>
                </>
            );

            return contents;
        },
        {
            headers: [<></>],
            energySizes: [<>Energy in Pack</>],
            units: [<>Battery units in Pack</>],
            dimensions: [
                <>
                    Size per battery unit <br />L x W x H (mm)
                </>,
            ],
            weight: [<>Weight</>],
            lifetimeCycles: [<>Est. Battery cycle life (DOD / 25° C)</>],
            certification: [<>Certified</>],
            peakDischarge: [<>Peak Discharge</>],
            continuousDischarge: [<>Continuous Discharge</>],
            continuousCharge: [<>Continuous Charge</>],
        }
    );
}

function renderHeader(content: ReactNode, index: number) {
    return <th key={index}>{content}</th>;
}

function renderRow(row: ReactNode[], index: number) {
    return <tr key={`row-${index}`}>{row.map(renderCell)}</tr>;
}

function renderCell(content: ReactNode, index: number) {
    return (
        <td key={index} className={index % 2 === 1 ? "even" : ""}>
            {content}
        </td>
    );
}

const BatteryComparisonToggle: FunctionComponent = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="battery-comparison-toggle">
            <button onClick={toggle}>Battery Specifications</button>
            <ReactModal className="modal-battery-comparison" isOpen={isOpen} onRequestClose={toggle}>
                <BatteryComparison onRequestClose={toggle}></BatteryComparison>
            </ReactModal>
        </div>
    );
};

export default BatteryComparisonToggle;
