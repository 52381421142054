import { ProductSelections, OrderType, Availability, Products, EvoyState } from "../store/engines";
import { BatteryPackCode } from "../store/engines/battery";
import { ChargerTypeCode } from "../store/engines/charger";
import { boats, buyers, fuels } from "../store/engines/configuration";
import { MotorTypeCode } from "../store/engines/motor";
import { ScreenTypeCode } from "../store/engines/screen";
import { Boat, Buyer, Fuel, Hull, UsageInformation } from "../types/UsageInformation";

/**
 * TODO: deprecate and replace with Config.
 */
interface ConfigurationSelections {
    boat: Boat;
    hull: Hull;
    fuel: Fuel;
    speed: number;
    hoursUsage: number;
    fuelConsumption: number;
    buyer: Buyer;
}

export const applyVatIfNeeded = (price: number, vat: boolean): number => {
    if (price <= 0) return price;
    if (vat) {
        return price * 1.25;
    }
    return price;
};

export const findOrderType = ({ battery, charger, motor, screen }: ProductSelections): OrderType => {
    const products = [battery, charger, motor, screen];

    if (products.some(({ availability }) => availability === Availability.None)) {
        return OrderType.Lead;
    } else if (products.some(({ availability }) => availability === Availability.PreOrder)) {
        return OrderType.PreOrder;
    } else {
        return OrderType.Order;
    }
};

export const sanitizeEmail = (emailInput: string): string => {
    let emailWithoutSpaces = emailInput ? emailInput.replace(/\s/g, "") : "";
    return emailWithoutSpaces;
};

export const calculatePrice = ({ battery, charger, motor, screen }: ProductSelections): number => {
    return battery.price + charger.price + motor.price + screen.price;
};

export const getAvailabilitySortOrder = (availability: Availability): number => {
    switch (availability) {
        case Availability.Available:
            return 0;
        case Availability.PreOrder:
            return 1;
        case Availability.None:
            return 2;
        default:
            throw new Error(`Unexpected availability type: ${availability}`);
    }
};

export function getCurrentProductSelections(
    {
        battery: batteryPackCode,
        charger: chargerTypeCode,
        motor: motorTypeCode,
        screen: screenTypeCode,
    }: { battery: BatteryPackCode; charger: ChargerTypeCode; motor: MotorTypeCode; screen: ScreenTypeCode },
    { batteries, chargers, motors, screens }: Products
): ProductSelections {
    // Find motor type.
    const motor = motors.find(({ code }) => code === motorTypeCode)!;

    // Find battery pack type.
    const battery = batteries.find(({ code }) => code === batteryPackCode)!;

    // Find screen type.
    const screen = screens.find(({ code }) => code === screenTypeCode)!;

    // Find charger
    const charger = chargers.find(({ code }) => code === chargerTypeCode)!;

    return {
        battery,
        charger,
        motor,
        screen,
    };
}

export function getCurrentConfigurationSelections({
    boatCode,
    buyerCode,
    consumption,
    fuelCode,
    hours,
    hullCode,
    speed,
}: UsageInformation): ConfigurationSelections {
    const boat = boats.find((x) => x.boatCode === boatCode)!;
    const hull = boat.hull.find((x) => x.hullCode === hullCode) || boat.hull[0];
    const fuel = fuels.find((x) => x.fuelCode === fuelCode)!;
    const buyer = buyers.find((x) => x.buyerCode === buyerCode)!;

    return {
        boat,
        hull,
        fuel,
        speed,
        hoursUsage: hours,
        fuelConsumption: consumption,
        buyer,
    };
}

/**
 * Creates a description of the system availability, based on the availability
 * and expected release year. Returns the resulting description as a string.
 *
 * @param availability The availability of the system.
 * @param releaseYear The year which the system was (or is expected to be)
 * released.
 */
export function getAvailabilityDescription(availability: Availability, releaseYear: number | undefined): string {
    return availability === Availability.Available
        ? "Available"
        : availability === Availability.PreOrder
        ? `Preorder: Available ${releaseYear || "soon"}`
        : `Available ${releaseYear || "soon"}`;
}

/**
 * Constructs a query string from the product and configuration selections.
 *
 * @param cs The current configurations set by the user.
 * @param ps The current products selected by the user.
 * @param interestedInLandBasedCharger Wether the user is interested in land based charger.
 * @param interestedInRangeExtender Wether the user is interested in range extender.
 * @returns
 */
export function getSaveDesignQueryString(
    configuration: UsageInformation,
    { battery, charger, motor, screen }: ProductSelections,
    interestedInLandBasedCharger: boolean,
    interestedInRangeExtender: boolean
) {
    // Configuration values.
    const configurations = [
        configuration.boatCode,
        configuration.hullCode,
        configuration.fuelCode,
        configuration.hours,
        configuration.speed,
        configuration.consumption,
        configuration.buyerCode,
    ];

    // Product values.
    const products = [motor.code, battery.type, battery.category, screen.code, charger.code];

    // Get the domain and query parameters.
    const SEPARATOR_VALUES = "_";
    const SEPARATOR_PARAMETERS = "&";
    const domain = window.location.href.split("?")[0];
    const parameters = [
        `products=${products.join(SEPARATOR_VALUES)}`,
        `configuration=${configurations.join(SEPARATOR_VALUES)}`,
        `ilbc=${interestedInLandBasedCharger}`,
        `ire=${interestedInRangeExtender}`,
    ];

    // Return the resulting query string.
    return `${domain}?${parameters.join(SEPARATOR_PARAMETERS)}`;
}
