// Package dependencies.
import { Box, SxProps } from "@mui/material";
import { CSSProperties } from "react";

interface InformationImageProps {
    alt: string;
    src: string;
    useGradient?: boolean;
}

const styleImageNoGradient: CSSProperties = { maxHeight: "300px", maxWidth: "50%" };
const styleImageWithGradient: CSSProperties = {
    maxHeight: "500px",
    maxWidth: "60%", // Maximum available width (2 * 30%)
    position: "absolute",
    right: "30%", // Position image at gradient line.
    transform: "translate(50%, 0)", // Center image
};
const sxBoxImage: SxProps = {
    display: "flex",
    height: "100%",
    justifyContent: "center",
};
const sxParentContainerNoGradient: SxProps = {
    background: "inherit",
};
const sxParentContainerWithGradient: SxProps = {
    background: "linear-gradient(90deg,#fff 70%,#eef2f1 0)",
    position: "relative", // Position image relative to box.
};

export default InformationImage;

function InformationImage({ alt, src, useGradient = true }: InformationImageProps) {
    const styleImage: CSSProperties = useGradient ? styleImageWithGradient : styleImageNoGradient;
    return (
        <Box sx={sxBoxImage}>
            <img alt={alt} src={src} style={styleImage} />
        </Box>
    );
}

export function getParentContainerSx(useGradient: boolean = true) {
    return useGradient ? sxParentContainerWithGradient : sxParentContainerNoGradient;
}
