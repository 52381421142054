import { action } from "typesafe-actions";
import { ConfigurationPopupState, DischargeType, EnginesActionTypes } from "./types";

export const setConfigurationPopupState = (state: ConfigurationPopupState) =>
    action(EnginesActionTypes.SET_CONFIG_POPUP_STATE, state);

export const setDischargeType = (dischargeType: DischargeType) =>
    action(EnginesActionTypes.SET_DISCHARGE_TYPE, dischargeType);
export const changeContactInfo = (payload: [string, string | boolean]) =>
    action(EnginesActionTypes.CHANGE_CONTACT_INFO, payload);
