import React from "react";

// import proBatteryImage from "../../assets/pro_battery.png";
import whiteBatteryImage from "../../assets/white_battery.png";
import { getMotorImageURL } from "../../common/motorImages";
import { BatteryPack } from "../../store/engines/battery";
import { MotorPlacementCode, motorPlacements, MotorType } from "../../store/engines/motor";
import "./MotorWithBatteries.scss";

interface MotorWithBatteriesProps {
    battery: BatteryPack;
    motor: MotorType;
}

// Export MotorWithBatteries as default component.
export default MotorWithBatteries;

function MotorWithBatteries({
    battery: { category: batterySizeCategory },
    motor: { placement, series },
}: MotorWithBatteriesProps) {
    const motorImage = getMotorImageURL(placement, series, "main");
    const motorPlacement = motorPlacements[placement];
    const motorClassName = placement === MotorPlacementCode.Inboard ? "inboard" : "outboard";

    const batteryImage = whiteBatteryImage;
    const batteryTypeClassName = "white";
    const batteryAltText = `${batteryTypeClassName} battery indicating ${batterySizeCategory} battery size`;

    return (
        <div className="motor-with-batteries">
            <div className={`motor-container ${motorClassName}`}>
                <img src={motorImage} alt={`${motorPlacement.name} motor`} />
            </div>
            <div className={`batteries-container ${batteryTypeClassName} ${batterySizeCategory}`}>
                <img src={batteryImage} alt={batteryAltText} />
                <img src={batteryImage} alt={batteryAltText} />
                <img src={batteryImage} alt={batteryAltText} />
            </div>
        </div>
    );
}
