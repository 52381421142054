import Box from "@mui/material/Box";
import React, { useState, useEffect } from "react";
import { applyVatIfNeeded, getCurrentConfigurationSelections } from "../../common/utils";
import { ProductSelections, setConfigurationPopupState } from "../../store/engines";
import NumberFormat from "react-number-format";
import { useDispatch } from "react-redux";
import { getApiClient } from "../../common/apiHelper";
import { useBuyer, useConfiguration } from "../../common/configuration";
import { useLocalization } from "../Localization/LocalizationProvider";

interface Props {
    productSelections: ProductSelections;
}

interface Savings {
    lifetimeSavings: number;
    lifetimeCarbonDioxideSavings: number;
}

interface SavingsRequest {
    segment: string;
    energyCarrier: string;
    operatingHoursPerYear: number;
    fuelConsumptionPerNauticalMile: number;
    knots: number;
    horsePower: number;
    electricalSystemCost: number | null;
    batteryCost: number | null;
}

const apiClient = getApiClient();
const defaultSavings: Savings = { lifetimeSavings: 0, lifetimeCarbonDioxideSavings: 0 };

const SavingsComponent: React.FC<Props> = ({
    productSelections: {
        battery: { price: batteryPrice },
        motor: { continuousHP: horsePower, price: motorPrice },
    },
}) => {
    const [savings, setSavings] = useState<Savings>(defaultSavings);
    const { configuration } = useConfiguration();
    const { currency } = useLocalization();
    const buyer = useBuyer();
    const dispatch = useDispatch();
    const openConfigurationPopup = () => dispatch(setConfigurationPopupState({ isOpen: true, page: 1 }));
    const shouldApplyVat = buyer.vat;

    useEffect(() => {
        const { buyer, fuel, fuelConsumption, hoursUsage, speed } = getCurrentConfigurationSelections(configuration);
        if (fuel && buyer && hoursUsage > 0 && fuelConsumption > 0 && horsePower) {
            const savingsRequest: SavingsRequest = {
                segment: buyer.value,
                energyCarrier: fuel.text,
                operatingHoursPerYear: hoursUsage,
                fuelConsumptionPerNauticalMile: fuelConsumption,
                knots: speed,
                horsePower,
                electricalSystemCost: motorPrice < 0 ? null : applyVatIfNeeded(motorPrice, shouldApplyVat),
                batteryCost: batteryPrice < 0 ? null : applyVatIfNeeded(batteryPrice, shouldApplyVat),
            };

            const fetchData = async () => {
                try {
                    const response = await apiClient.post<Savings>("/savings", savingsRequest);
                    setSavings(response.data);
                } catch (error) {
                    setSavings(defaultSavings);
                }
            };
            fetchData();
        }
    }, [configuration, batteryPrice, horsePower, motorPrice, shouldApplyVat]);

    let lifetime;
    if (savings.lifetimeSavings > 0) {
        lifetime = (
            <p style={{ opacity: "1" }} className="large-msg">
                <Box component="span" sx={{ fontSize: { xs: "12px", md: "14px" } }}>
                    {currency.symbol}{" "}
                </Box>
                <NumberFormat
                    value={savings.lifetimeSavings}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />{" "}
            </p>
        );
    } else {
        lifetime = <p className="large-msg">- {currency.symbol} </p>;
    }

    let lifetimeCarbonDioxide;
    if (savings.lifetimeCarbonDioxideSavings > 0) {
        lifetimeCarbonDioxide = (
            <p style={{ opacity: "1" }} className="large-msg yellow-text-mobile">
                {" "}
                <NumberFormat
                    value={savings.lifetimeCarbonDioxideSavings}
                    displayType={"text"}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                />{" "}
                kg CO2
            </p>
        );
    } else {
        lifetimeCarbonDioxide = <p className="large-msg">- CO2</p>;
    }

    return (
        <div className="savings footer-item">
            <p className="small-msg">Lifetime savings</p>
            <div>
                {lifetime}
                <span className="large-msg invisible-small">&nbsp;/&nbsp;</span>
                {lifetimeCarbonDioxide}
            </div>
            {buyer && (
                <div className="ul invisible-small yellow-text change-input-link">
                    <span onClick={openConfigurationPopup}>Change input to see your savings</span>
                </div>
            )}
        </div>
    );
};

export default SavingsComponent;
