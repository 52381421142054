// Package dependencies.
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

// Local dependencies.
import image from "../../assets/lader.png";

const description = "Charge time from 30m to +24h depending on AC outlet and pack size. Comes standard with Evoy.";

// Export ChargerInformation
export default ChargerInformation;

/**
 * Renders information for a given charger system.
 *
 * @param props The related component props.
 */
function ChargerInformation() {
    return (
        <Box display="flex" flexDirection="column" sx={{ "& img": { alignSelf: "center", maxHeight: "200px" } }}>
            <img alt="Charger" src={image} />
            <Typography fontSize="2em" textTransform="uppercase">
                Evoy's <b>Super AC Charging* </b>Can Charge On Any AC Outlet
            </Typography>
            <Typography>{description}</Typography>
        </Box>
    );
}
