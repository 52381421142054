// Package dependencies.
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Local dependencies.
import { useConfiguration } from "../../common/configuration";
import { findEstimatedRange, Range } from "../../common/rangeApiClient";
import { setConfigurationPopupState } from "../../store/engines";
import { batteryPacks } from "../../store/engines/battery";
import { fuels } from "../../store/engines/configuration";
import { useSelectedBatteryPackCode } from "../Providers/ProductSelectionsProvider";
import Specification from "./Specification";

// Export SystemSpecRange as default component.
export default SystemSpecRange;

function SystemSpecRange() {
    const batteryPackCode = useSelectedBatteryPackCode();
    const {
        configuration: { consumption, fuelCode, speed },
    } = useConfiguration();
    const [range, setRange] = useState<Range | null>(null);

    useEffect(() => {
        const batteryPack = batteryPacks[batteryPackCode];
        const fetchRange = async () => {
            try {
                const fuelType = fuels.find((fuel) => fuel.fuelCode === fuelCode)!.type;
                const estimatedRange = await findEstimatedRange(fuelType, consumption, batteryPack.kwh);
                setRange(estimatedRange);
            } catch (error) {
                setRange(null);
            }
        };
        fetchRange();
    }, [batteryPackCode, consumption, fuelCode]);

    const dispatch = useDispatch();
    const openConfigurationPopup = () => dispatch(setConfigurationPopupState({ isOpen: true, page: 4 }));
    const title = `${range?.rangeNm ?? "—"} nm`;
    const subtitle = `${range?.rangeKm ?? "—"} km`;
    return (
        <Specification subtitle={subtitle} title={title}>
            Range at <button onClick={openConfigurationPopup}>{speed} knots</button>**
        </Specification>
    );
}
