// Local dependencies.
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { getLocalizedUrl, useLocalization } from "../components/Localization/LocalizationProvider";
import { CountryCode } from "../localization/helper";

export type NavigationState = Record<PageName, boolean>;

interface Page {
    next?: PageName;
    previous?: PageName;
    title: string;
    url: PageLocation;
}

export enum PageLocation {
    Battery = "/battery",
    Charger = "/charger",
    Confirmation = "/confirmation",
    Engine = "/",
    Information = "/information",
    Screen = "/options",
    Summary = "/summary",
}

type PageName = keyof typeof PageLocation;

/**
 * The initial navigation state when the application starts.
 */
const initialNavigationState: NavigationState = {
    Battery: false,
    Charger: false,
    Confirmation: false,
    Engine: false,
    Information: false,
    Screen: false,
    Summary: false,
};

/**
 * Map of page names to a page configuration.
 */
export const mapNameToPage: Record<PageName, Page> = {
    Battery: {
        next: "Screen",
        previous: "Engine",
        title: "Battery",
        url: PageLocation.Battery,
    },
    Charger: {
        next: "Summary",
        previous: "Screen",
        title: "Charger",
        url: PageLocation.Charger,
    },
    Confirmation: {
        title: "Confirmation",
        url: PageLocation.Confirmation,
    },
    Engine: {
        next: "Battery",
        title: "Motor",
        url: PageLocation.Engine,
    },
    Information: {
        title: "Information",
        url: PageLocation.Information,
    },
    Screen: {
        next: "Charger",
        previous: "Battery",
        title: "Options",
        url: PageLocation.Screen,
    },
    Summary: {
        previous: "Charger",
        title: "Summary",
        url: PageLocation.Summary,
    },
};

/**
 * Returns a page config for the next page after the provided pathname, returns
 * null if no matching page is found.
 *
 * @param pathname The pathname of current page.
 */
export function getNextPageFromPathname(country: CountryCode, pathname: string): Page | null {
    const { next } = getPageFromPathname(country, pathname) || {};
    return next ? mapNameToPage[next] : null;
}

/**
 * Returns a page config object based on its pathname, returns null if no
 * matching page is found.
 *
 * @param pathname The pathname of the relevant page.
 */
export function getPageFromPathname(country: CountryCode, pathname: string): Page | null {
    const name = getPageNameFromPathName(country, pathname);
    return name ? mapNameToPage[name] : null;
}

/**
 * Finds the name of the page matching the given pathname. Returns the page
 * name, or null if no matching page is found.
 *
 * @param pathname The pathname of the relevant page.
 */
export function getPageNameFromPathName(country: CountryCode, pathname: string): PageName | null {
    const names: PageName[] = Object.keys(mapNameToPage) as PageName[];
    const name = names.find((name: PageName) => getLocalizedUrl(country, mapNameToPage[name].url) === pathname);
    return name ?? null;
}

/**
 * Returns a page config for the previous page before the provided pathname,
 * returns null if no matching page is found.
 *
 * @param pathname The pathname of current page.
 */
export function getPreviousPageFromPathname(country: CountryCode, pathname: string): Page | null {
    const { previous } = getPageFromPathname(country, pathname) || {};
    return previous ? mapNameToPage[previous] : null;
}

/**
 * Handles updates to the navigation state. Returns the application state
 * containing the updated navigation state.
 *
 * @param state The current navigation state.
 * @param action The action which is dispatched.
 */
export function reducerNavigationState(
    state: NavigationState = initialNavigationState,
    action: { type: "@@NAVIGATION_STATE/ON_PAGE_LOAD"; page: PageName }
): NavigationState {
    if (action.type === "@@NAVIGATION_STATE/ON_PAGE_LOAD") {
        return { ...state, [action.page]: true };
    }
    return state;
}

/**
 * Handles common actions and navigation state on page load. Scrolls window to
 * top, and stores navigation state.
 */
export function useOnPageLoadEffect() {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const { country } = useLocalization();
    useEffect(() => {
        window.scrollTo(0, 0);
        const page = getPageNameFromPathName(country, pathname);
        if (page) {
            dispatch({ type: "@@NAVIGATION_STATE/ON_PAGE_LOAD", page });
        }
    }, [dispatch, pathname]);
}
