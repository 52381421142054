// Package dependencies.
import { Typography } from "@mui/material";
import React from "react";

// Local dependencies.
import { useShouldApplyVat } from "../../common/configuration";
import { withDirectAccessGuard } from "../../common/directAccessGuard";
import { Availability, BatteryProduct } from "../../store/engines";
import { BatteryPackCode, batteryTypes } from "../../store/engines/battery";
import BatteryComparisonToggle from "../BatteryComparison/BatteryComparison";
import ProductChoiceButtonLists, { ProductList } from "../ChoiceButton/ProductChoiceButtonLists";
import LayoutProducts from "../Layout/LayoutProduct";
import { useLocalization } from "../Localization/LocalizationProvider";
import ProductInformation from "../ProductInformation";
import { useProductSelections } from "../Providers/ProductSelectionsProvider";

export default withDirectAccessGuard(BatteryComponent);

function BatteryComponent(): JSX.Element {
    const {
        currency,
        products: { batteries, motors },
    } = useLocalization();
    const shouldApplyVat = useShouldApplyVat();
    const { battery: selectedBatteryCode, motor: selectedMotorTypeCode, selectBatteryPack } = useProductSelections();
    const selectedMotor = motors.find(({ code }) => code === selectedMotorTypeCode)!;
    const selectedBattery = batteries.find(({ code }) => code === selectedBatteryCode)!;
    const selectedMotorBatteries = selectedMotor.batteries;
    const lists = getBatteryProductLists(batteries, selectedMotorBatteries);

    return (
        <LayoutProducts
            columnInformation={
                <ProductInformation selectedBattery={selectedBattery} selectedMotor={selectedMotor} type="battery" />
            }
            columnSelections={
                <>
                    <Typography variant="h4" component="h1">
                        SELECT <b>BATTERY</b>
                    </Typography>
                    <BatteryComparisonToggle />
                    <ProductChoiceButtonLists
                        currencySymbol={currency.symbol}
                        getDescription={getBatteryDescription}
                        getName={getBatteryName}
                        lists={lists}
                        onClick={selectBatteryPack}
                        selectedProductCode={selectedBatteryCode}
                        shouldApplyVat={shouldApplyVat}
                    />
                </>
            }
            xsReverseDirection
        />
    );
}

/**
 * Filters a list of batteries on availability and product codes. Returns the
 * resulting filtered list.
 *
 * @param batteries The complete list of batteries.
 * @param productCodes List of valid product codes.
 * @param availabilities List of valid availability values.
 */
function getBatteriesByAvailabilityAndProductCodeSorted(
    batteries: BatteryProduct[],
    productCodes: BatteryPackCode[],
    availabilities: Availability[]
) {
    return batteries
        .filter(({ availability, code }) => productCodes.includes(code) && availabilities.includes(availability))
        .sort(sortByKwh);
}

function getBatteryDescription({ kwh }: BatteryProduct): string {
    return `${kwh} kWh`;
}
function getBatteryName({ name: batteryPackName, type }: BatteryProduct): string {
    const batteryType = batteryTypes[type];
    return `${batteryType.name} ${batteryPackName}`;
}

function getBatteryProductLists(
    batteries: BatteryProduct[],
    selectedMotorBatteries: BatteryPackCode[]
): ProductList<BatteryProduct>[] {
    return [
        [
            `battery-choice-button-list-available`,
            getBatteriesByAvailabilityAndProductCodeSorted(batteries, selectedMotorBatteries, [
                Availability.Available,
                Availability.PreOrder,
            ]),
            undefined,
        ],
        [
            `battery-choice-button-list-none`,
            getBatteriesByAvailabilityAndProductCodeSorted(batteries, selectedMotorBatteries, [Availability.None]),
            <>
                <span style={{ fontWeight: "normal" }}>COMING</span> LATER
            </>,
        ],
    ];
}

/**
 * Compares two batteries and sorts them descending by kWh.
 *
 * @param a The first Battery to compare.
 * @param b The second Battery to compare.
 */
function sortByKwh(a: BatteryProduct, b: BatteryProduct): number {
    return (a.kwh || 0) - (b.kwh || 0);
}
