import axios from "axios";

export const getApiClient = () => {
    return axios.create({
        baseURL: "/api",
        responseType: "json",
        headers: {
            "Content-Type": "application/json",
        },
    });
};
