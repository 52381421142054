// Package dependencies.
import React, { useMemo } from "react";
import ReactModal from "react-modal";
import { connect } from "react-redux";

// Local dependencies.
import close from "../../assets/close.svg";
import { useConfiguration } from "../../common/configuration";
import { useConfigurationPopup } from "../../common/useConfigurationPopup";
import { getCurrentConfigurationSelections } from "../../common/utils";
import { boats, buyers, Configuration, fuels } from "../../store/engines/configuration";
import "./modal.scss";
import ModalBoatPage from "./ModalBoatPage";
import ModalBuyerPage from "./ModalBuyerPage";
import ModalCostPage from "./ModalCostPage";
import ModalUsagePage from "./ModalUsagePage";

interface NavigationElement {
    name: string;
    page: number;
}

const navigationElements: NavigationElement[] = [
    {
        name: "Type of buyer",
        page: 1,
    },
    {
        name: "Type of boat",
        page: 2,
    },
    {
        name: "Type of use",
        page: 3,
    },
    {
        name: "Cost",
        page: 4,
    },
];

// Bind the modal to the dom element.
ReactModal.setAppElement("#root");

// Export ModalPopUp with redux store as default component.
export default ModalPopUp;

/**
 * Renders a modal popup with customer configurations.
 */
function ModalPopUp(): JSX.Element {
    const { currentPage, isOpen, navigate } = useConfigurationPopup();
    const { configuration } = useConfiguration();
    const {
        boat: selectedBoat,
        buyer: selectedBuyer,
        fuelConsumption,
        fuel: selectedFuel,
        hoursUsage,
        hull: selectedHull,
        speed,
    } = useMemo(() => getCurrentConfigurationSelections(configuration), [configuration]);

    return (
        <ReactModal className="modal-settings" isOpen={isOpen} onRequestClose={() => navigate()}>
            <div className="modal-container">
                <img className="close-button" onClick={() => navigate()} src={close} alt="close" />
                <div className="column menu">
                    <ol>
                        {navigationElements.map(({ name, page }) => (
                            <li
                                key={`navigation-element-${page}`}
                                className={page === currentPage ? "active" : ""}
                                onClick={() => navigate(page)}
                            >
                                {name}
                            </li>
                        ))}
                    </ol>
                </div>

                <div className={`column content ${currentPage === 4 ? "content-space" : ""}`}>
                    {currentPage === 1 ? (
                        <ModalBuyerPage buyers={buyers} selectedBuyer={selectedBuyer} />
                    ) : currentPage === 2 ? (
                        <ModalBoatPage
                            boats={boats}
                            selectedBoat={selectedBoat}
                            selectedBuyer={selectedBuyer}
                            selectedHull={selectedHull}
                        />
                    ) : currentPage === 3 ? (
                        <ModalUsagePage hoursUsage={hoursUsage} />
                    ) : currentPage === 4 ? (
                        <ModalCostPage
                            fuels={fuels}
                            fuelConsumption={fuelConsumption}
                            selectedFuel={selectedFuel}
                            speed={speed}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </ReactModal>
    );
}
