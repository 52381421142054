// Package dependencies.
import Typography from "@mui/material/Typography";
import React, { ReactNode } from "react";

// Local dependencies.
import { ProductDetails } from "../../store/engines";
import "../buttonlist.scss";
import ProductChoiceButton from "../ChoiceButton/ProductChoiceButton";

interface ProductChoiceButtonListProps<ProductType extends ProductDetails> {
    currencySymbol: string;
    getDescription: (product: ProductType) => string;
    getName: (product: ProductType) => string;
    onClick: (code: ProductType["code"]) => void;
    products: ProductType[];
    selectedProductCode?: ProductType["code"];
    shouldApplyVat: boolean;
    title?: ReactNode;
}

// Export ProductChoiceButtonList as default component.
export default ProductChoiceButtonList;

/**
 * Renders a choice button list for a given list of products, with a title if
 * provided. Renders an empty fragment if no products are provided.
 *
 * @param props The related component props.
 */
function ProductChoiceButtonList<ProductType extends ProductDetails>({
    currencySymbol,
    getDescription,
    getName,
    onClick,
    products,
    selectedProductCode,
    shouldApplyVat,
    title,
}: ProductChoiceButtonListProps<ProductType>) {
    return products.length ? (
        <>
            {title && (
                <Typography component="h2" variant="h5" mt={2}>
                    {title}
                </Typography>
            )}
            <div className="buttonlist vertical-buttonlist">
                {products.map((product) => (
                    <ProductChoiceButton
                        key={`choice-button-${product.code}`}
                        currencySymbol={currencySymbol}
                        getDescription={getDescription}
                        getName={getName}
                        onClick={onClick}
                        product={product}
                        selectedProductCode={selectedProductCode}
                        shouldApplyVat={shouldApplyVat}
                    />
                ))}
            </div>
        </>
    ) : (
        <></>
    );
}
