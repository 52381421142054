// Package dependencies.
import { Typography } from "@mui/material";
import React from "react";

// Local dependencies.
import { useConfiguration, useShouldApplyVat } from "../../common/configuration";
import { Availability, MotorProduct } from "../../store/engines";
import { MotorTypeCode, motorTypes } from "../../store/engines/motor";
import ProductChoiceButtonLists, { ProductList } from "../ChoiceButton/ProductChoiceButtonLists";
import LayoutProducts from "../Layout/LayoutProduct";
import { useLocalization } from "../Localization/LocalizationProvider";
import MotorPlacementSelector from "../MotorPlacementSelector/MotorPlacementSelector";
import ProductInformation from "../ProductInformation";
import { useProductSelections } from "../Providers/ProductSelectionsProvider";

export default EngineComponent;

function EngineComponent(): JSX.Element {
    const {
        currency,
        products: { batteries, motors: motorProducts },
    } = useLocalization();
    const {
        battery: selectedBatteryCode,
        motor: selectedMotorTypeCode,
        motorPlacement,
        selectMotorType,
    } = useProductSelections();
    const motors = motorProducts.filter(({ placement }) => placement === motorPlacement);
    const selectedBattery = batteries.find(({ code }) => code === selectedBatteryCode)!;
    const selectedMotor = motors.find(({ code }) => code === selectedMotorTypeCode)!;
    const shouldApplyVat = useShouldApplyVat();
    const { resetDefault } = useConfiguration();
    const lists = getMotorProductLists(motors);

    return (
        <LayoutProducts
            columnInformation={
                <ProductInformation selectedBattery={selectedBattery} selectedMotor={selectedMotor} type="motor" />
            }
            columnSelections={
                <>
                    <Typography variant="h4" component="h1">
                        SELECT YOUR <b>MOTOR</b>
                    </Typography>
                    <MotorPlacementSelector />
                    <ProductChoiceButtonLists
                        currencySymbol={currency.symbol}
                        getDescription={getMotorDescription}
                        getName={getMotorName}
                        lists={lists}
                        onClick={(code: MotorTypeCode) => {
                            const motor = motorTypes[code];
                            selectMotorType(code);
                            resetDefault(motor.placement, motor.series);
                        }}
                        selectedProductCode={selectedMotorTypeCode}
                        shouldApplyVat={shouldApplyVat}
                    />
                </>
            }
            xsReverseDirection
        />
    );
}

// TODO: move to mockData when Motor specification are generated instead of
// created manually.
function getMotorDescription({ continuousHP, continuousKW }: MotorProduct) {
    return `${continuousHP}+ hp // ${continuousKW}+ kW`;
}
function getMotorName({ name }: MotorProduct): string {
    return name;
}

function getMotorProductLists(motors: MotorProduct[]): ProductList<MotorProduct>[] {
    return [
        [
            `motor-choice-button-list-available`,
            getMotorsByAvailabilityAndSorted(motors, [Availability.Available, Availability.PreOrder]),
            undefined,
        ],
        [
            `motor-choice-button-list-none`,
            getMotorsByAvailabilityAndSorted(motors, [Availability.None]),
            <>
                COMING <b>LATER</b>
            </>,
        ],
    ];
}

/**
 * Filters a list of motors by availability and sorts them by continuous
 * horsepower. Returns the filtered and sorted list.
 *
 * @param motors The list of motors that should be filtered.
 * @param availability The desired availibility that should be filtered on.
 * @returns
 */
function getMotorsByAvailabilityAndSorted(motors: MotorProduct[], availability: Availability[]): MotorProduct[] {
    return motors.filter((motor) => availability.includes(motor.availability)).sort(sortByContinuousHP);
}

/**
 * Compares two motors and sorts them descending by continuous horsepower.
 *
 * @param a The first Motor to compare.
 * @param b The second Motor to compare.
 */
function sortByContinuousHP(a: MotorProduct, b: MotorProduct): number {
    return (b.continuousHP || 0) - (a.continuousHP || 0);
}
