import { FuelType } from "../types/UsageInformation";
import { getApiClient } from "./apiHelper";

interface RangeRequest {
    energyCarrier: string;
    fuelConsumption: number;
    batterySize: number;
}

export type Range = {
    rangeNm: number;
    rangeKm: number;
};

export const findEstimatedRange = async (fuelType: FuelType, fuelConsumption: number, batterySizeInKwh: number) => {
    if (fuelConsumption <= 0 || batterySizeInKwh <= 0) {
        throw new Error(
            `Unable to estimate range for fuel consumption: ${fuelConsumption} l/nm and battery size: ${batterySizeInKwh} kWh`
        );
    }

    var apiClient = getApiClient();

    let rangeRequest: RangeRequest = {
        energyCarrier: fuelType,
        fuelConsumption: fuelConsumption,
        batterySize: batterySizeInKwh,
    };

    try {
        var response = await apiClient.post<Range>("/range", rangeRequest);
        return response.data;
    } catch (error) {
        throw new Error(`Error when trying to calculate range: ${error}`);
    }
};
