import { createContext, ReactNode, useContext, useEffect, useMemo, useReducer } from "react";
import { PageLocation } from "../../common/page";
import {
    currencies,
    Currency,
    CurrencyCode,
    defaultLocale,
    getLocale,
    Locale,
    Products,
} from "../../localization/helper";
import { CountryCode } from "./types";

type LocalizationAction =
    | { type: "setLocale"; locale: Locale }
    | { type: "setCountry"; code: CountryCode }
    | { type: "setCurrency"; code: CurrencyCode }
    | { type: "setIsLoading"; value: boolean }
    | { type: "setProducts"; products: Products };

interface LocalizationContext {
    country: CountryCode;
    currency: Currency;
    language: "en";
    products: Products;
}

interface LocalizationProviderProps {
    children?: ReactNode;
}

interface LocalizationState {
    isLoading: boolean;
    country: CountryCode;
    currency: CurrencyCode;
    language: "en";
    products: Products;
}

const initialState: LocalizationState = {
    country: defaultLocale.country,
    currency: defaultLocale.currency,
    isLoading: true,
    language: "en",
    products: defaultLocale.products,
};

const LocalizationContext = createContext<LocalizationContext>({
    country: initialState.country,
    currency: currencies[initialState.currency],
    language: initialState.language,
    products: initialState.products,
});

export function LocalizationProvider({ children }: LocalizationProviderProps): JSX.Element {
    // Create the configuration state and dispatcher.
    const [state, dispatch] = useReducer(reducerLocalization, initialState);

    const setCountry = useMemo(() => getSetCountry(dispatch), [dispatch]);

    useEffect(() => {
        const [, locale] = new URL(window.location.href).pathname.split("/");
        const [, country] = (locale || "").split("_");
        const countryCode =
            (country && Object.values(CountryCode).find((code) => code === country)) || defaultLocale.country;
        setCountry(countryCode);
    }, [setCountry]);

    // Memoize context value reference and only update it if state changes.
    const value: LocalizationContext = useMemo(() => {
        return {
            country: state.country,
            currency: currencies[state.currency],
            language: state.language,
            products: state.products,
        };
    }, [state]);

    // Render the context provider with values.
    return state.isLoading ? (
        <></>
    ) : (
        <LocalizationContext.Provider value={value}>{children}</LocalizationContext.Provider>
    );
}

function reducerLocalization(state: LocalizationState, action: LocalizationAction): LocalizationState {
    if (action.type === "setLocale") {
        const { country, currency, products } = action.locale;
        return {
            country,
            currency,
            isLoading: false,
            language: "en",
            products,
        };
    } else if (action.type === "setIsLoading" && action.value !== state.isLoading) {
        return { ...state, isLoading: action.value };
    }
    return state;
}

export function useLocalization(): LocalizationContext {
    return useContext(LocalizationContext);
}

export function getLocalizedUrl(country: CountryCode, page: PageLocation = PageLocation.Engine): string {
    const prefix = country === defaultLocale.country ? "" : `/en_${country}`;
    return `${prefix}${page}`;
}

function getSetCountry(dispatch: (action: LocalizationAction) => void): (code: CountryCode) => Promise<void> {
    return async (code: CountryCode) => {
        const locale = await getLocale(code);
        if (locale) {
            dispatch({ type: "setLocale", locale });
        } else {
            dispatch({ type: "setIsLoading", value: false });
            // TODO: locale does not exist, add error response for user.
        }
    };
}
