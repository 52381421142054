// Package dependencies.
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

// Local dependencies.
import { ApplicationState } from "../store";
import { ConfigurationPopupState, EnginesActionTypes } from "../store/engines";

interface ConfigurationPopup {
    currentPage: number;
    isOpen: boolean;
    navigate: (page?: number) => void;
}

export function useConfigurationPopup(): ConfigurationPopup {
    const dispatch = useDispatch();
    const state = useSelector(
        ({ evoyState: { configurationPopupState } }: ApplicationState) => configurationPopupState
    );

    return useMemo(
        () => ({
            currentPage: state.page,
            isOpen: state.isOpen,
            navigate: (page?: number) => {
                const payload: ConfigurationPopupState = { isOpen: typeof page === "number", page: page || 0 };
                dispatch({ type: EnginesActionTypes.SET_CONFIG_POPUP_STATE, payload });
            },
        }),
        [dispatch, state]
    );
}
