// Package dependencies.
import React, { ReactNode } from "react";

// Local dependencies.
import { ProductDetails } from "../../store/engines";
import ProductChoiceButtonList from "../ChoiceButton/ProductChoiceButtonList";

export type ProductList<ProductType extends ProductDetails> = [string, ProductType[], ReactNode];

interface ProductChoiceButtonListsProps<ProductType extends ProductDetails> {
    currencySymbol: string;
    getDescription: (product: ProductType) => string;
    getName: (product: ProductType) => string;
    lists: ProductList<ProductType>[];
    onClick: (code: ProductType["code"]) => void;
    selectedProductCode: ProductType["code"];
    shouldApplyVat: boolean;
}

// Export ProductChoiceButtonLists as default component.
export default ProductChoiceButtonLists;

/**
 * Renders multiple product choice button lists. Renders an empty fragment if no
 * lists are provided.
 */
function ProductChoiceButtonLists<ProductType extends ProductDetails>({
    currencySymbol,
    getDescription,
    getName,
    lists,
    onClick,
    selectedProductCode,
    shouldApplyVat,
}: ProductChoiceButtonListsProps<ProductType>) {
    return (
        <>
            {lists.map(([key, batteries, title]: ProductList<ProductType>) => (
                <ProductChoiceButtonList
                    currencySymbol={currencySymbol}
                    getDescription={getDescription}
                    getName={getName}
                    key={key}
                    onClick={onClick}
                    products={batteries}
                    selectedProductCode={selectedProductCode}
                    shouldApplyVat={shouldApplyVat}
                    title={title}
                />
            ))}
        </>
    );
}
