// Package dependencies.
import React from "react";

// Local dependencies.
import { getSystemWeightInKg } from "../../common/batteryHelper";
import { batteryPacks } from "../../store/engines/battery";
import { motorTypes } from "../../store/engines/motor";
import { useProductSelections } from "../Providers/ProductSelectionsProvider";
import Specification from "./Specification";

interface SystemSpecWeightProps {
    type?: "battery" | "motor" | "system";
}

const POUNDS_IN_KILOGRAMS = 2.20462262;

// Export SystemSpecWeight as default component.
export default SystemSpecWeight;

function SystemSpecWeight({ type = "system" }: SystemSpecWeightProps) {
    const { battery, motor } = useProductSelections();
    const batteryPack = batteryPacks[battery];
    const motorType = motorTypes[motor];

    // Calculate the system weight in both kilograms and pounds.
    const systemWeights = getSystemWeightInKg(batteryPack, motorType);
    const displayWeights: number[] =
        type === "motor"
            ? [systemWeights.motor, systemWeights.motorEquipment]
            : type === "battery"
            ? [systemWeights.battery, systemWeights.batteryAdditional]
            : [systemWeights.systemTotal];

    // Determine display title based on spec type.
    const title = `${type === "motor" ? "Motor" : "Battery"} Weight*`;

    return (
        <Specification
            subtitle={getSystemWeightText(displayWeights, "lb")}
            title={getSystemWeightText(displayWeights, "kg")}
        >
            {title}
        </Specification>
    );
}

function getSystemWeightText(weights: number[], metric: "kg" | "lb"): string {
    const text = weights
        .map((x: number) => (metric === "kg" ? x : Math.floor(x * POUNDS_IN_KILOGRAMS)) ?? "-")
        .join(" + ");
    return `${text} ${metric}`;
}
