// Package dependencies.
import { Box, SxProps } from "@mui/material";
import React, { ReactNode, useMemo } from "react";
import { useLocation } from "react-router";

// Local dependencies.
import { PageLocation, useOnPageLoadEffect } from "../../common/page";
import Footer from "../Footer/Footer";
import HeaderMenu from "../Header/HeaderMenu";
import ModalPopUp from "../Modal/ModalPopUp";

const sxContainer: SxProps = {
    display: "flex",
    flexDirection: "column",
    height: "100vh",
};
const sxContentDefaults: SxProps = {
    display: {
        xs: "unset",
        md: "flex",
    },
    flexDirection: "column",
    flexGrow: 1,
    // Add padding bottom to compensate for the footer.
    paddingBottom: "140px",
    // Add padding top to compensate for the header.
    paddingTop: {
        xs: "65px",
        md: "81px",
    },
};
const sxContentContainer: SxProps = {
    display: "flex",
    flexGrow: 1,
};
const sxFooter: SxProps = {
    bottom: "0",
    position: "fixed",
    width: "100%",
    zIndex: "10",
};

// Export Layout as default component.
export default Layout;

/**
 * Renders layout with header menu, footer, and children components.
 *
 * @param props The related component props.
 */
function Layout({ children }: { children?: ReactNode }): JSX.Element {
    const { pathname } = useLocation();
    useOnPageLoadEffect();

    const shouldDisplayFooter = pathname !== PageLocation.Information && pathname !== PageLocation.Confirmation;
    const sxContent: SxProps = useMemo(
        () => ({
            ...sxContentDefaults,
            paddingBottom: shouldDisplayFooter ? "140px" : "0px",
        }),
        [shouldDisplayFooter]
    );

    return (
        <Box sx={sxContainer}>
            <Box>
                <HeaderMenu />
            </Box>
            <Box sx={sxContent}>
                <Box id="content-container" sx={sxContentContainer}>
                    {children}
                </Box>
            </Box>
            {shouldDisplayFooter && (
                <Box sx={sxFooter}>
                    <Footer />
                </Box>
            )}
            <ModalPopUp />
        </Box>
    );
}
