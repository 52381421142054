// Package dependencies.
import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import ReactMarkdown from "react-markdown";

interface DisclaimerProps {
    markdown: string;
}

const sx: SxProps = {
    "& p": {
        marginTop: "23px",
        textAlign: "center",
        color: "#959090",
        fontSize: { xs: "12px", md: "14px" },
    },
};

export default Disclaimer;

function Disclaimer({ markdown }: DisclaimerProps) {
    return (
        <Box sx={sx}>
            <ReactMarkdown children={markdown} />
        </Box>
    );
}
