// Package dependencies.
import { Theme, Typography, useMediaQuery } from "@mui/material";
import React from "react";

// Local dependencies.
import { useBuyer, useConfiguration } from "../../common/configuration";
import { withDirectAccessGuard } from "../../common/directAccessGuard";
import { findOrderType, calculatePrice, getCurrentProductSelections } from "../../common/utils";
import { OrderType } from "../../store/engines";
import LayoutProducts from "../Layout/LayoutProduct";
import SelectedSystem from "./SelectedSystem";
import "./summaryListItem.scss";
import { useLocalization } from "../Localization/LocalizationProvider";
import { useProductSelections } from "../Providers/ProductSelectionsProvider";
import OrderLead from "./OrderLead";

function SummaryComponent(): JSX.Element {
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
    const { configuration } = useConfiguration();
    const buyer = useBuyer();
    const {
        products,
        products: { motors },
    } = useLocalization();
    const selectedProductCodes = useProductSelections();
    const { interestedInLandBasedCharger, interestedInRangeExtender } = selectedProductCodes;
    const productSelections = getCurrentProductSelections(selectedProductCodes, products);
    const totalPrice = calculatePrice(productSelections);
    const orderType = motors.length ? findOrderType(productSelections) : OrderType.Lead;

    const Information = (
        <>
            <Typography component="h1" variant="h4">
                <b>SUMMARY</b>
            </Typography>
            <SelectedSystem
                configuration={configuration}
                buyer={buyer}
                interestedInLandBasedCharger={interestedInLandBasedCharger}
                interestedInRangeExtender={interestedInRangeExtender}
                isMobile={isMobile}
                orderType={orderType}
                productSelections={productSelections}
                totalPrice={totalPrice}
            />
        </>
    );

    return (
        <LayoutProducts
            columnInformation={!isMobile && Information}
            columnSelections={
                <OrderLead
                    buyer={buyer}
                    Information={isMobile && Information}
                    interestedInLandBasedCharger={interestedInLandBasedCharger}
                    interestedInRangeExtender={interestedInRangeExtender}
                    isMobile={isMobile}
                    orderType={orderType}
                    productSelections={productSelections}
                />
            }
            xsReverseDirection
        />
    );
}

export default withDirectAccessGuard(SummaryComponent);
