// Package dependencies.
import { Typography } from "@mui/material";
import React from "react";

// Local dependencies.
import { useShouldApplyVat } from "../../common/configuration";
import { withDirectAccessGuard } from "../../common/directAccessGuard";
import { Availability, ChargerProduct } from "../../store/engines";
import { ChargerTypeCode } from "../../store/engines/charger";
import { motorTypes } from "../../store/engines/motor";
import ProductChoiceButtonLists, { ProductList } from "../ChoiceButton/ProductChoiceButtonLists";
import LayoutProducts from "../Layout/LayoutProduct";
import { useLocalization } from "../Localization/LocalizationProvider";
import { useProductSelections } from "../Providers/ProductSelectionsProvider";
import ChargerInformation from "./ChargerInformation";
import ChargerRequestOptions from "./ChargerRequestOptions";

export default withDirectAccessGuard(ChargerComponent);

function ChargerComponent(): JSX.Element {
    const {
        currency,
        products: { chargers },
    } = useLocalization();
    const shouldApplyVat = useShouldApplyVat();
    const {
        charger: selectedChargerCode,
        interestedInLandBasedCharger,
        motor,
        selectChargerType,
        setInterestedInLandBasedCharger,
    } = useProductSelections();
    const selectedMotorChargers = motorTypes[motor].chargers;
    const lists = getChargerProductLists(chargers, selectedMotorChargers);

    return (
        <LayoutProducts
            columnInformation={<ChargerInformation />}
            columnSelections={
                <>
                    <Typography component="h1" variant="h4">
                        SELECT YOUR <b>CHARGER</b>
                    </Typography>
                    <ProductChoiceButtonLists
                        currencySymbol={currency.symbol}
                        getDescription={getChargerDescription}
                        getName={getChargerName}
                        onClick={selectChargerType}
                        lists={lists}
                        selectedProductCode={selectedChargerCode}
                        shouldApplyVat={shouldApplyVat}
                    />
                    <ChargerRequestOptions
                        interestedInLandBasedCharger={interestedInLandBasedCharger}
                        onClick={() => setInterestedInLandBasedCharger(!interestedInLandBasedCharger)}
                    />
                </>
            }
            xsReverseDirection
        />
    );
}

function getChargerDescription({ description }: ChargerProduct): string {
    return description;
}

function getChargerName({ name }: ChargerProduct): string {
    return `${name} Charger`;
}

function getChargerProductLists(
    chargers: ChargerProduct[],
    selectedMotorChargers: ChargerTypeCode[]
): ProductList<ChargerProduct>[] {
    return [
        [
            `charger-choice-button-list-available`,
            getChargersByAvailabilityAndProductCode(chargers, selectedMotorChargers, [
                Availability.Available,
                Availability.PreOrder,
            ]),
            undefined,
        ],
        [
            `charger-choice-button-list-none`,
            getChargersByAvailabilityAndProductCode(chargers, selectedMotorChargers, [Availability.None]),
            <>
                COMING <b>LATER</b>
            </>,
        ],
    ];
}

/**
 * Filters a list of chargers on availability and product codes. Returns the
 * resulting filtered list.
 *
 * @param chargers The complete list of chargers.
 * @param chargersCompatibleWithMotor List of valid product codes.
 * @param availabilities List of valid availability values.
 */
function getChargersByAvailabilityAndProductCode(
    chargers: ChargerProduct[],
    productCodes: ChargerProduct["code"][],
    availabilities: Availability[]
) {
    return chargers.filter(
        ({ availability, code }) => productCodes.includes(code) && availabilities.includes(availability)
    );
}
