// Package dependencies.
import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

// Local dependencies.
import { Availability, BatteryProduct, MotorProduct } from "../../store/engines";
import { texts } from "../../store/engines/texts";
import KeepUpdatedSection from "../KeepUpdated/KeepUpdatedSection";
import MotorImage from "../MotorImage/MotorImage";
import MotorWithBatteries from "../MotorWithBatteries/MotorWithBatteries";
import { Disclaimer } from "../SystemSpec";
import SystemSpec from "../SystemSpec/SystemSpec";

interface ProductInformationProps {
    selectedBattery: BatteryProduct;
    selectedMotor: MotorProduct;
    type: "battery" | "motor";
}

const disclaimers = texts.disclaimer;
const sxMotorImageContainer: SxProps = {
    display: "flex",
    width: "100%",
    height: { xs: "25vh", md: "40vh" },
    overflow: { xs: "hidden", md: "visible" },
};

// Export ProductInformation as default component.
export default ProductInformation;

/**
 * Renders information for a given battery system.
 *
 * @param props The related component props.
 */
function ProductInformation({ selectedBattery, selectedMotor, type }: ProductInformationProps): JSX.Element {
    const { placement, series } = selectedMotor;
    const { availability, releaseYear } = type === "motor" ? selectedMotor : selectedBattery;
    return (
        <>
            <Box sx={sxMotorImageContainer}>
                {type === "motor" ? (
                    <MotorImage placement={placement} series={series} />
                ) : (
                    <MotorWithBatteries battery={selectedBattery} motor={selectedMotor} />
                )}
            </Box>

            {availability === Availability.None ? (
                <KeepUpdatedSection releaseYear={releaseYear} />
            ) : (
                <>
                    <SystemSpec type={type} />
                    <Disclaimer markdown={disclaimers[type][placement]} />
                </>
            )}
        </>
    );
}
