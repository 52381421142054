import { BatteryPack } from "./battery";
import { ChargerType } from "./charger";
import { MotorType } from "./motor";
import { ScreenType } from "./screen";

export interface Products {
    batteries: BatteryProduct[];
    chargers: ChargerProduct[];
    motors: MotorProduct[];
    screens: ScreenProduct[];
    orderFee: number;
}

export type ProductDetails<ProductCode extends string = string> = {
    availability: Availability;
    code: ProductCode;
    name: string;
    price: number;
    releaseYear: number;
};

export type BatteryProduct = ProductDetails & BatteryPack;
export type ChargerProduct = ProductDetails & ChargerType;
export type MotorProduct = ProductDetails & MotorType;
export type ScreenProduct = ProductDetails & ScreenType;

export interface ConfigurationPopupState {
    isOpen: boolean;
    page: number;
}

export enum EnginesActionTypes {
    SET_CONFIG_POPUP_STATE = "@@CONFIG/SET_CONFIG_POPUP_STATE",
    SET_DISCHARGE_TYPE = "@@CONFIG/SET_DISCHARGE_TYPE",
    CHANGE_CONTACT_INFO = "@@CHANGE_CONTACT_INFO",
}

export interface EvoyState {
    readonly dischargeType: DischargeType;
    readonly configurationPopupState: ConfigurationPopupState;
    readonly contactInformation: ContactInformation;
}
export interface ProductSelections {
    battery: BatteryProduct;
    charger: ChargerProduct;
    motor: MotorProduct;
    screen: ScreenProduct;
}

export interface ContactInformation {
    firstname: string | null;
    lastname: string | null;
    businessname: string | null;
    email: string | null;
    phone: string | null;
    countryCode: string;
    country: string | null;
    leadSource: string | null;
    leadSourceOther: string | null;
    privacyPolicy: boolean;
    subscribeToNewsletter: boolean;
    boatDescription: string | null;
}

export enum OrderType {
    Order = "Order",
    PreOrder = "PreOrder",
    Lead = "Lead",
}

export enum Availability {
    Available = "Available",
    PreOrder = "PreOrder",
    None = "None",
}

export enum MotorPlacement {
    Inboard = "Inboard",
    Outboard = "Outboard",
}

export enum BatterySizeCategory {
    Medium = "M",
    Large = "L",
    XLarge = "XL",
}

export enum DischargeType {
    Peak = "Peak",
    Continuous = "Continuous",
}
