// Package depedencies.
import { MuiTelInput } from "mui-tel-input";
import { memo } from "react";

// Local depedencies.
import { defaultTextFieldProps } from "./helper";
import { PhoneFieldProps } from "./types";

export default memo(PhoneField);

function PhoneField({ defaultCountry, error, label, name, onChange, required, value }: PhoneFieldProps) {
    const props = {
        ...defaultTextFieldProps,
        defaultCountry,
        error: !!error,
        helperText: error,
        label,
        name,
        onChange: (value: string) => onChange && onChange(name, value),
        required,
        value,
    };
    return <MuiTelInput {...props} />;
}
