// Package dependencies.
import { MenuItem, TextField } from "@mui/material";
import React from "react";

// Local dependencies.
import backIcon from "../../assets/back-icon.svg";
import { useConfiguration } from "../../common/configuration";
import { useConfigurationPopup } from "../../common/useConfigurationPopup";
import { Fuel } from "../../types/UsageInformation";
import InputSlider from "./InputSlider";

interface ModalCostPageProps {
    fuelConsumption: number;
    fuels: Fuel[];
    selectedFuel: Fuel;
    speed: number;
}

const backPage = {
    name: "Type of use",
    page: 3,
};

// Export ModalCostPage as default component.
export default ModalCostPage;

/**
 * Renders a cost page for the modal component.
 *
 * @param props The related component props.
 */
function ModalCostPage({ fuelConsumption, fuels, selectedFuel, speed }: ModalCostPageProps): JSX.Element {
    const { navigate } = useConfigurationPopup();
    const { setConsumption, setFuelCode, setSpeed } = useConfiguration();

    return (
        <>
            <button className="nxt-btn btn-back" onClick={() => navigate(backPage.page)}>
                <img className="back-icon" src={backIcon} alt="back" /> back
            </button>
            <h1>4. Costs</h1>
            <p className="cost-space">What type fuel are you using today?</p>
            <div className="row">
                <TextField
                    label="Fuel type"
                    onChange={({ target: { value } }) => setFuelCode(value)}
                    select
                    value={selectedFuel.fuelCode}
                    variant="filled"
                    sx={{ minWidth: "50%" }}
                >
                    {fuels.map(({ fuelCode, text }) => (
                        <MenuItem key={fuelCode} value={fuelCode}>
                            {text}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <h3>Typical speed?</h3>
            <InputSlider max={75} min={0} onChange={setSpeed} step={5} suffix="Knot" value={speed} />
            <h3>
                Fuel usage for <b>{speed} knot?</b>
            </h3>
            <InputSlider
                max={10}
                min={0}
                onChange={setConsumption}
                step={0.1}
                suffix="Litre pr. nm"
                value={fuelConsumption}
            />
            <div className="savings-block">
                <p>You will be able to see your savings after choosing motor and battery.</p>
            </div>
            <div className="row box">
                <span className="box--text"> Estimated lifetime savings EUR 300.000 and 100t CO2 </span>
            </div>

            <div className="row navigation self-align">
                <button className="nxt-btn" onClick={() => navigate()}>
                    Choose motor and battery
                </button>
            </div>
        </>
    );
}
