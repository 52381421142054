// Package depedendencies.
import React from "react";

// Local dependencies.
import backIcon from "../../assets/back-icon.svg";
import { useConfiguration } from "../../common/configuration";
import { useConfigurationPopup } from "../../common/useConfigurationPopup";
import InputSlider from "./InputSlider";

interface ModalUsagePageProps {
    hoursUsage: number;
}

const backPage = {
    name: "Type of boat",
    page: 2,
};
const nextPage = {
    name: "Cost",
    page: 4,
};

// Export ModalUsagePage as default component.
export default ModalUsagePage;

/**
 * Renders a usage page for the modal component.
 *
 * @param props The related component props.
 */
function ModalUsagePage({ hoursUsage }: ModalUsagePageProps): JSX.Element {
    const { setHours } = useConfiguration();
    const { navigate } = useConfigurationPopup();

    return (
        <>
            <button className="nxt-btn btn-back" onClick={() => navigate(backPage.page)}>
                <img className="back-icon" src={backIcon} alt="back" /> back
            </button>
            <h1>3. Type of use</h1>
            <p className="hide-for-mobile">
                In order to give you recommendations and estimate how much you will save, we need to know more about how
                to use the boat today.
            </p>
            <p className="slider-space">Hour of usage pr year?</p>
            <InputSlider
                max={1000} // NOTE: would make sense to have 8760 (24h * 365d).
                min={0}
                onChange={setHours}
                step={10}
                suffix="hours"
                value={hoursUsage}
            />
            <div className="row navigation">
                <button className="nxt-btn" onClick={() => navigate(nextPage.page)}>
                    Next - {nextPage.name}
                </button>
            </div>
        </>
    );
}
