import { createContext, Dispatch, ReactNode, useContext, useMemo, useReducer } from "react";
import { Products } from "../../localization/helper";
import { BatteryPackCode } from "../../store/engines/battery";
import { ChargerTypeCode } from "../../store/engines/charger";
import { MotorPlacementCode, MotorTypeCode } from "../../store/engines/motor";
import { ScreenTypeCode } from "../../store/engines/screen";
import { useLocalization } from "../Localization/LocalizationProvider";

interface ProductSelectionProviderProps {
    children?: ReactNode;
}

type ProductSelectionsAction =
    | { type: "setBatteryPack"; code: BatteryPackCode }
    | { type: "setChargerType"; code: ChargerTypeCode }
    | { type: "setMotorPlacement"; code: MotorPlacementCode }
    | { type: "setMotorType"; code: MotorTypeCode }
    | { type: "setScreenType"; code: ScreenTypeCode }
    | { type: "setInterestedInLandBasedCharger"; value: boolean }
    | { type: "setInterestedInRangeExtender"; value: boolean };

interface ProductSelectionCodes {
    battery: BatteryPackCode;
    charger: ChargerTypeCode;
    motor: MotorTypeCode;
    screen: ScreenTypeCode;
}

interface ProductSelectionsState {
    hasPreconfiguredDesign: boolean;
    interestedInLandBasedCharger: boolean;
    interestedInRangeExtender: boolean;
    motorPlacement: MotorPlacementCode;
    motorPlacementSelections: Record<MotorPlacementCode, ProductSelectionCodes>;
}

interface ProductSelectionContext
    extends ProductSelectionCodes,
        Omit<ProductSelectionsState, "motorPlacementSelections"> {
    motorPlacementSelections: Record<MotorPlacementCode, ProductSelectionCodes>;
    selectBatteryPack: (code: BatteryPackCode) => void;
    selectChargerType: (code: ChargerTypeCode) => void;
    selectMotorPlacement: (code: MotorPlacementCode) => void;
    selectMotorType: (code: MotorTypeCode) => void;
    selectScreenType: (code: ScreenTypeCode) => void;
    setInterestedInLandBasedCharger: (value: boolean) => void;
    setInterestedInRangeExtender: (value: boolean) => void;
}

const defaultInboardSelections: ProductSelectionCodes = {
    battery: BatteryPackCode.V800EnergyMedium,
    charger: ChargerTypeCode.AC4,
    motor: MotorTypeCode.InboardHurricaneSeries,
    screen: ScreenTypeCode.Single10Inch,
};
const defaultOutboardSelections: ProductSelectionCodes = {
    battery: BatteryPackCode.V400EnergyMedium,
    charger: ChargerTypeCode.AC1,
    motor: MotorTypeCode.OutboardBreezeSeries,
    screen: ScreenTypeCode.Single10Inch,
};

const noop = () => {};
const initialState: ProductSelectionsState = {
    hasPreconfiguredDesign: false,
    interestedInLandBasedCharger: false,
    interestedInRangeExtender: false,
    motorPlacement: MotorPlacementCode.Outboard,
    motorPlacementSelections: {
        I: defaultInboardSelections,
        O: defaultOutboardSelections,
    },
};
const ProductSelectionContext = createContext<ProductSelectionContext>({
    hasPreconfiguredDesign: initialState.hasPreconfiguredDesign,
    interestedInLandBasedCharger: initialState.interestedInLandBasedCharger,
    interestedInRangeExtender: initialState.interestedInRangeExtender,
    motorPlacement: initialState.motorPlacement,
    motorPlacementSelections: initialState.motorPlacementSelections,
    ...initialState.motorPlacementSelections.O,
    selectBatteryPack: noop,
    selectChargerType: noop,
    selectMotorPlacement: noop,
    selectMotorType: noop,
    selectScreenType: noop,
    setInterestedInLandBasedCharger: noop,
    setInterestedInRangeExtender: noop,
});

// Export ProductSelectionsProvider as default component.
export default ProductSelectionProvider;

function ProductSelectionProvider({ children }: ProductSelectionProviderProps) {
    const { products } = useLocalization();
    const [state, dispatch] = useReducer(reducerProductSelections, getInitialState(products));

    // TODO: validate if selected product code exists in products before update.
    const handlers = useMemo(
        () => ({
            selectBatteryPack: (code: BatteryPackCode) => dispatch({ type: "setBatteryPack", code }),
            selectChargerType: (code: ChargerTypeCode) => dispatch({ type: "setChargerType", code }),
            selectMotorPlacement: (code: MotorPlacementCode) => dispatch({ type: "setMotorPlacement", code }),
            selectMotorType: getSetMotorType(dispatch, products),
            selectScreenType: (code: ScreenTypeCode) => dispatch({ type: "setScreenType", code }),
            setInterestedInLandBasedCharger: (value: boolean) =>
                dispatch({ type: "setInterestedInLandBasedCharger", value }),
            setInterestedInRangeExtender: (value: boolean) => dispatch({ type: "setInterestedInRangeExtender", value }),
        }),
        [dispatch, products]
    );

    // TODO: return products instead of just product codes.
    const value: ProductSelectionContext = useMemo(
        () => ({
            hasPreconfiguredDesign: state.hasPreconfiguredDesign,
            interestedInLandBasedCharger: state.interestedInLandBasedCharger,
            interestedInRangeExtender: state.interestedInRangeExtender,
            motorPlacement: state.motorPlacement,
            motorPlacementSelections: state.motorPlacementSelections,
            ...state.motorPlacementSelections[state.motorPlacement],
            ...handlers,
        }),
        [handlers, state]
    );

    return <ProductSelectionContext.Provider value={value}>{children}</ProductSelectionContext.Provider>;
}

/**
 * Converts a product description string into product selections. Returns the
 * motor placement code and product selections if the query string is valid.
 * Returns null if the query string is invalid.
 *
 * @param products Available battery, charger, motor and screen products.
 * @param query The provided products description string.
 */
function convertQueryToProductSelections(
    { batteries, chargers, motors, screens }: Products,
    query?: string
): { motorPlacement: MotorPlacementCode; products: ProductSelectionCodes } | null {
    if (query) {
        const [
            motorPlacementCode,
            motorSeriesCode,
            batteryTypeCode,
            batteryCategoryCode,
            screenSetupCode,
            screenSizeCode,
            chargerTypeCode,
        ] = query.split("_");

        // Find motor.
        const motorTypeCode = `${motorPlacementCode}_${motorSeriesCode}`;
        const motorType = motors.find(({ code }) => code === motorTypeCode);

        if (motorType) {
            // Find battery pack compatible with selected motor type.
            const batteryPackCode = `${motorSeriesCode}_${batteryTypeCode}_${batteryCategoryCode}`;
            const batteryPack = batteries
                .filter(({ code }) => motorType.batteries.includes(code))
                .find(({ code }) => code === batteryPackCode);

            // Find charger compatible with selected motor type.
            const chargerType = chargers
                .filter(({ code }) => motorType.chargers.includes(code))
                .find(({ code }) => code === chargerTypeCode);

            // Find screen.
            const screenTypeCode = `${screenSetupCode}_${screenSizeCode}`;
            const screenType = screens.find(({ code }) => code === screenTypeCode);

            // Check if a valid product has been found product types.
            if (batteryPack && chargerType && motorType && screenType) {
                return {
                    motorPlacement: motorType.placement,
                    products: {
                        battery: batteryPack.code,
                        charger: chargerType.code,
                        motor: motorType.code,
                        screen: screenType.code,
                    },
                };
            }
        }
    }

    // Return null if product query was invalid.
    return null;
}

/**
 * Gets the intial state of product selections. Checks if the application url
 * includes a preconfigured design.
 *
 * @param products The available battery, charger, motor and screen products.
 */
function getInitialState(products: Products): ProductSelectionsState {
    const url = new URL(window.location.href);
    const ilbc = url.searchParams.get("ilbc");
    const ire = url.searchParams.get("ire");

    const selections = convertQueryToProductSelections(products, url.searchParams.get("products") || undefined);
    const motorPlacementSelections = selections
        ? { ...initialState.motorPlacementSelections, [selections.motorPlacement]: selections.products }
        : initialState.motorPlacementSelections;

    return {
        hasPreconfiguredDesign: !!selections,
        motorPlacement: selections?.motorPlacement || initialState.motorPlacement,
        motorPlacementSelections,
        interestedInLandBasedCharger: ilbc !== null ? Boolean(ilbc) : initialState.interestedInLandBasedCharger,
        interestedInRangeExtender: ire !== null ? Boolean(ire) : initialState.interestedInRangeExtender,
    };
}

/**
 * Returns the setMotorType functionality for the given dispatcher and products.
 *
 * @param dispatch The dispatcher to update product selections state.
 * @param products The available products.
 */
function getSetMotorType(dispatch: Dispatch<ProductSelectionsAction>, products: Products) {
    /**
     * Updates the selected motor type. Also updates the selected battery pack,
     * and charger, with products that are compatible with the selected motor.
     */
    return function setMotorType(code: MotorTypeCode) {
        const motor = products.motors.find((motor) => code === motor.code);

        if (motor) {
            const batteries = products.batteries.filter(({ code }) => motor.batteries.includes(code));
            const battery = batteries[0];

            const chargers = products.chargers.filter(({ code }) => motor.chargers.includes(code));
            const charger = chargers[0];

            dispatch({ type: "setMotorType", code: motor.code });
            if (battery) {
                dispatch({ type: "setBatteryPack", code: battery.code });
            }
            if (charger) {
                dispatch({ type: "setChargerType", code: charger.code });
            }
        }
    };
}

function reducerProductSelections(
    state: ProductSelectionsState,
    action: ProductSelectionsAction
): ProductSelectionsState {
    if (action.type === "setBatteryPack") {
        return updateStateMotorPlacementSelections(state, "battery", action.code);
    } else if (action.type === "setChargerType") {
        return updateStateMotorPlacementSelections(state, "charger", action.code);
    } else if (action.type === "setMotorPlacement") {
        return { ...state, motorPlacement: action.code };
    } else if (action.type === "setMotorType") {
        return updateStateMotorPlacementSelections(state, "motor", action.code);
    } else if (action.type === "setScreenType") {
        return updateStateMotorPlacementSelections(state, "screen", action.code);
    } else if (action.type === "setInterestedInLandBasedCharger") {
        return { ...state, interestedInLandBasedCharger: action.value };
    } else if (action.type === "setInterestedInRangeExtender") {
        return { ...state, interestedInRangeExtender: action.value };
    }
    return state;
}

export function useProductSelections(): ProductSelectionContext {
    return useContext(ProductSelectionContext);
}

export function useSelectedBatteryPackCode(): BatteryPackCode {
    const { battery } = useProductSelections();
    return battery;
}

export function useSelectedChargerTypeCode(): ChargerTypeCode {
    const { charger } = useProductSelections();
    return charger;
}

export function useSelectedMotorTypeCode(placement?: MotorPlacementCode): MotorTypeCode {
    const { motor, motorPlacementSelections } = useProductSelections();
    return placement ? motorPlacementSelections[placement].motor : motor;
}

export function useSelectedScreenTypeCode(): ScreenTypeCode {
    const { screen } = useProductSelections();
    return screen;
}

function updateMotorPlacementSelections<Field extends keyof ProductSelectionCodes>(
    motorPlacement: MotorPlacementCode,
    motorPlacementSelections: Record<MotorPlacementCode, ProductSelectionCodes>,
    field: Field,
    code: ProductSelectionCodes[Field]
) {
    motorPlacementSelections[motorPlacement] = updateProductSelectionCode(
        motorPlacementSelections[motorPlacement],
        field,
        code
    );
    return { ...motorPlacementSelections };
}

function updateProductSelectionCode<Field extends keyof ProductSelectionCodes>(
    selections: ProductSelectionCodes,
    field: Field,
    code: ProductSelectionCodes[Field]
) {
    return {
        ...selections,
        [field]: code,
    };
}

function updateStateMotorPlacementSelections<Field extends keyof ProductSelectionCodes>(
    state: ProductSelectionsState,
    field: Field,
    code: ProductSelectionCodes[Field]
) {
    const motorPlacementSelections = updateMotorPlacementSelections(
        state.motorPlacement,
        state.motorPlacementSelections,
        field,
        code
    );
    return { ...state, motorPlacementSelections };
}
