import { combineReducers } from "redux";

import { EvoyState, engineReducer } from "./engines";
import { NavigationState, reducerNavigationState } from "../common/page";

// The top-level state object
export interface ApplicationState {
    navigationState: NavigationState;
    evoyState: EvoyState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property
// using the reducer with the matching name. It's important that the names match exactly, and that
// the reducer acts on the corresponding ApplicationState property type.
export const createRootReducer = () =>
    combineReducers({
        navigationState: reducerNavigationState,
        evoyState: engineReducer,
    });
