// Package dependencies.
import { Close, Language } from "@mui/icons-material";
import {
    Box,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    Typography,
} from "@mui/material";
import React, { memo, useState } from "react";
import { getLocalizedUrl, useLocalization } from "../Localization/LocalizationProvider";

// Local dependencies.
import { CountryCode, Region, regions } from "../Localization/regions";

interface CountryListItemProps {
    name: string;
    href: string;
    selected?: boolean;
}

interface DrawerLocalizationPickerProps {
    open: boolean;
    onClose?: () => void;
    onClickCountry?: (code: CountryCode) => void;
    regions: Region[];
    selectedCountry?: CountryCode;
}

interface RegionListProps {
    region: Region;
    selectedCountry?: CountryCode;
}

// Export HeaderMenuLocale with memoization as default component.
export default memo(HeaderMenuLocale);

/**
 * Renders a locale selector for the header menu.
 */
function HeaderMenuLocale(): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);
    const handleClosePopup = () => setIsOpen(false);
    const handleOpenPopup = () => setIsOpen(true);
    const { country } = useLocalization();
    return (
        <Box>
            <IconButton color="inherit" onClick={handleOpenPopup}>
                <Language />
                &nbsp;
                <Typography variant="h6">{country}</Typography>
            </IconButton>
            <DrawerLocalizationPicker
                onClose={handleClosePopup}
                open={isOpen}
                regions={regions}
                selectedCountry={country}
            />
        </Box>
    );
}

function DrawerLocalizationPicker({ onClose, open, regions, selectedCountry }: DrawerLocalizationPickerProps) {
    return (
        <Drawer anchor="right" onClose={onClose} open={open}>
            <Box sx={{ padding: 4 }}>
                <Box display="flex" justifyContent="flex-end">
                    <IconButton color="inherit" onClick={onClose}>
                        <Close />
                    </IconButton>
                </Box>
                {regions.map((region) => (
                    <RegionList key={`list-region-${region.name}`} region={region} selectedCountry={selectedCountry} />
                ))}
            </Box>
        </Drawer>
    );
}

function RegionList({ region, selectedCountry }: RegionListProps) {
    return (
        <>
            <Typography component="h4" variant="h5">
                {region.name}
            </Typography>
            <List sx={{ width: "100%", minWidth: 200 }}>
                {region.countries.length ? (
                    region.countries.map((country) => (
                        <CountryListItem
                            key={`country-list-item-${country.code}`}
                            href={getLocalizedUrl(country.code)}
                            name={country.name}
                            selected={selectedCountry === country.code}
                        />
                    ))
                ) : (
                    <ComingSoon />
                )}
            </List>
            <Divider sx={{ my: 2 }} />
        </>
    );
}

function ComingSoon() {
    return <Typography>Coming Soon...</Typography>;
}

function CountryListItem({ href, name, selected }: CountryListItemProps) {
    return (
        <ListItem disablePadding>
            <ListItemButton href={href} selected={selected}>
                <ListItemText primary={<Typography fontWeight={selected ? "bolder" : undefined}>{name}</Typography>} />
            </ListItemButton>
        </ListItem>
    );
}
