// Package dependencies.
import { Box, Grid, SxProps } from "@mui/material";
import React, { ReactNode, useMemo } from "react";

// Local dependencies.
import { colors } from "../Providers/ThemeProvider";

interface LayoutProductsProps {
    columnInformation?: ReactNode;
    columnSelections?: ReactNode;
    xsReverseDirection?: boolean;
    sxBoxColumnInformation?: SxProps;
}

const sxContainerDefaults: SxProps = {
    height: "100%",
};
const sxBoxColumn: SxProps = {
    height: "100%",
    px: {
        xs: 1,
        md: 4,
    },
    py: {
        xs: 1,
        md: 5,
    },
    overflowX: "hidden",
};
const sxColumnInformation: SxProps = {
    backgroundColor: colors.white,
};
const sxColumnSelections: SxProps = {};

// Export LayoutProducts as default component.
export default LayoutProducts;

function LayoutProducts({
    columnInformation,
    columnSelections,
    sxBoxColumnInformation: sxBoxColumnInformationFromProps,
    xsReverseDirection,
}: LayoutProductsProps): JSX.Element {
    const sxContainer: SxProps = useMemo(() => {
        const sx: SxProps = {
            ...sxContainerDefaults,
            flexDirection: {
                xs: xsReverseDirection ? "column-reverse" : "row",
                md: "row",
            },
        };
        return sx;
    }, [xsReverseDirection]);
    const sxBoxColumnInformation: SxProps = useMemo(
        () => ({
            ...sxBoxColumn,
            ...sxBoxColumnInformationFromProps,
        }),
        [sxBoxColumnInformationFromProps]
    );

    return (
        <Grid container spacing={0} sx={sxContainer}>
            {columnInformation && (
                <Grid item md={6} xs={12} sx={sxColumnInformation}>
                    <Box sx={sxBoxColumnInformation}>{columnInformation}</Box>
                </Grid>
            )}
            <Grid item md={6} xs={12} sx={sxColumnSelections}>
                <Box sx={sxBoxColumn}>{columnSelections}</Box>
            </Grid>
        </Grid>
    );
}
