import { Country, CountryCode, ProductsConfigCode, Region, RegionCode } from "./types";

const language = "English";

export type { Country, Region, RegionCode };
export { CountryCode };

const availableCountries: Country[] = [
    {
        code: CountryCode.Austria,
        name: "Austria",
        language,
        region: RegionCode.Europe,
        products: ProductsConfigCode.Europe,
    },
    {
        code: CountryCode.Belgium,
        name: "Belgium",
        language,
        region: RegionCode.Europe,
        products: ProductsConfigCode.Europe,
    },
    // {
    //     code: CountryCode.Croatia,
    //     name: "Croatia",
    //     language,
    //     region: RegionCode.Europe,
    //     products: ProductsConfigCode.Europe,
    // },
    // {
    //     code: CountryCode.Czechia,
    //     name: "Czechia",
    //     language,
    //     region: RegionCode.Europe,
    //     products: ProductsConfigCode.Europe,
    // },
    // {
    //     code: CountryCode.Denmark,
    //     name: "Denmark",
    //     language,
    //     region: RegionCode.Europe,
    //     products: ProductsConfigCode.Europe,
    // },
    {
        code: CountryCode.Estonia,
        name: "Estonia",
        language,
        region: RegionCode.Europe,
        products: ProductsConfigCode.Europe,
    },
    {
        code: CountryCode.France,
        name: "France",
        language,
        region: RegionCode.Europe,
        products: ProductsConfigCode.Europe,
    },
    {
        code: CountryCode.Germany,
        name: "Germany",
        language,
        region: RegionCode.Europe,
        products: ProductsConfigCode.Europe,
    },
    {
        code: CountryCode.Greece,
        name: "Greece",
        language,
        region: RegionCode.Europe,
        products: ProductsConfigCode.Europe,
    },
    // {
    //     code: CountryCode.Iceland,
    //     name: "Iceland",
    //     language,
    //     region: RegionCode.Europe,
    //     products: ProductsConfigCode.Europe,
    // },
    {
        code: CountryCode.Ireland,
        name: "Ireland",
        language,
        region: RegionCode.Europe,
        products: ProductsConfigCode.Europe,
    },
    {
        code: CountryCode.Italy,
        name: "Italy",
        language,
        region: RegionCode.Europe,
        products: ProductsConfigCode.Europe,
    },
    {
        code: CountryCode.Netherlands,
        name: "Netherlands",
        language,
        region: RegionCode.Europe,
        products: ProductsConfigCode.Europe,
    },
    {
        code: CountryCode.Norway,
        name: "Norway",
        language,
        region: RegionCode.Europe,
        products: ProductsConfigCode.Norway,
    },
    {
        code: CountryCode.Poland,
        name: "Poland",
        language,
        region: RegionCode.Europe,
        products: ProductsConfigCode.Europe,
    },
    {
        code: CountryCode.Portugal,
        name: "Portugal",
        language,
        region: RegionCode.Europe,
        products: ProductsConfigCode.Europe,
    },
    {
        code: CountryCode.Spain,
        name: "Spain",
        language,
        region: RegionCode.Europe,
        products: ProductsConfigCode.Europe,
    },
    // {
    //     code: CountryCode.Sweden,
    //     name: "Sweden",
    //     language,
    //     region: RegionCode.Europe,
    //     products: ProductsConfigCode.Europe,
    // },
    // {
    //     code: CountryCode.UnitedKingdom,
    //     name: "United Kingdom",
    //     language,
    //     region: RegionCode.Europe,
    //     products: ProductsConfigCode.Europe,
    // },
];

export const countries: Record<CountryCode, Country> = availableCountries.reduce((record, country) => {
    record[country.code] = country;
    return record;
}, {} as Record<CountryCode, Country>);

const availableRegions: [RegionCode, string][] = [
    [RegionCode.Europe, "Europe"],
    [RegionCode.NorthAmerica, "North America"],
    [RegionCode.Pacific, "Pacific"],
];

export const regions: Region[] = availableRegions.map(
    ([code, name]): Region => ({
        code,
        name,
        countries: Object.values(countries).filter((country: Country) => country.region === code),
    })
);
