// Package dependencies.
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControl, { FormControlProps } from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { memo } from "react";

// Local dependencies.
import { CheckboxFieldProps } from "./types";

export default memo(CheckboxField);

function CheckboxField({ error, name, label, onChange, required, value }: CheckboxFieldProps) {
    const formControlProps: FormControlProps = {
        error: !!error,
        required,
    };
    const checkboxProps: CheckboxProps = {
        name,
        onChange: onChange && ((event: unknown, checked: boolean) => onChange(name, checked)),
        value,
        defaultChecked: value,
    };
    return (
        <FormControl {...formControlProps}>
            <FormControlLabel control={<Checkbox {...checkboxProps} color="default" />} label={label} />
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
}
