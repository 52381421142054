import planninghullImage from "../../assets/planninghull.png";
import semidisplacementhullImage from "../../assets/semidisplacementhull.png";
import displacementhullImage from "../../assets/displacementhull.png";
import { Boat, BoatCode, Buyer, BuyerCode, Fuel, FuelCode, FuelType, HullCode } from "../../types/UsageInformation";

export const boats: Boat[] = [
    {
        boatCode: BoatCode.BB,
        text: "Bay boats/ Bowrider boats",
        hull: [
            {
                hullCode: HullCode.PH,
                available: true,
                img: planninghullImage,
                text: "Planing hull",
            },
        ],
    },
    {
        boatCode: BoatCode.BR,
        text: "Bowrider",
        hull: [
            {
                hullCode: HullCode.PH,
                available: true,
                img: planninghullImage,
                text: "Planing hull",
            },
        ],
    },

    {
        boatCode: BoatCode.CC,
        text: "Cabincruiser",
        hull: [
            {
                hullCode: HullCode.PH,
                available: true,
                img: planninghullImage,
                text: "Planing hull",
            },
            {
                hullCode: HullCode.SDH,
                available: true,
                img: semidisplacementhullImage,
                text: "Semi-displacement hull",
            },
            {
                hullCode: HullCode.DH,
                available: false,
                img: displacementhullImage,
                text: "Displacement hull",
            },
        ],
    },
    {
        boatCode: BoatCode.DC,
        text: "Daycruiser",
        hull: [
            {
                hullCode: HullCode.PH,
                available: true,
                img: planninghullImage,
                text: "Planing hull",
            },
            {
                hullCode: HullCode.SDH,
                available: true,
                img: semidisplacementhullImage,
                text: "Semi-displacement hull",
            },
        ],
    },
    {
        boatCode: BoatCode.FB,
        text: "Fishing boat / small smack",
        hull: [
            {
                hullCode: HullCode.SDH,
                available: true,
                img: semidisplacementhullImage,
                text: "Semi-displacement hull",
            },
            {
                hullCode: HullCode.DH,
                available: false,
                img: displacementhullImage,
                text: "Displacement hull",
            },
        ],
    },
    {
        boatCode: BoatCode.JS,
        text: "Jet ski",
        hull: [
            {
                hullCode: HullCode.PH,
                available: false,
                img: planninghullImage,
                text: "Planing hull",
            },
        ],
    },
    {
        boatCode: BoatCode.RIB,
        text: "RIB",
        hull: [
            {
                hullCode: HullCode.PH,
                available: true,
                img: planninghullImage,
                text: "Planing hull",
            },
        ],
    },
    {
        boatCode: BoatCode.SB,
        text: "Sailboat",
        hull: [
            {
                hullCode: HullCode.DH,
                available: false,
                img: displacementhullImage,
                text: "Displacement hull",
            },
        ],
    },
    {
        boatCode: BoatCode.SD,
        text: "Skiff / Dinghy",
        hull: [
            {
                hullCode: HullCode.PH,
                available: true,
                img: planninghullImage,
                text: "Planing hull",
            },
        ],
    },
    {
        boatCode: BoatCode.SWS,
        text: "Sloop without sail",
        hull: [
            {
                hullCode: HullCode.SDH,
                available: true,
                img: semidisplacementhullImage,
                text: "Semi-displacement hull",
            },
            {
                hullCode: HullCode.DH,
                available: false,
                img: displacementhullImage,
                text: "Displacement hull",
            },
        ],
    },
    {
        boatCode: BoatCode.SPB,
        text: "Speedboat",
        hull: [
            {
                hullCode: HullCode.PH,
                available: true,
                img: planninghullImage,
                text: "Planing hull",
            },
        ],
    },
    {
        boatCode: BoatCode.YACHT,
        text: "Yacht",
        hull: [
            {
                hullCode: HullCode.DH,
                available: false,
                img: displacementhullImage,
                text: "Displacement hull",
            },
        ],
    },
    {
        boatCode: BoatCode.OTHERS,
        text: "Others",
        hull: [
            {
                hullCode: HullCode.PH,
                available: true,
                img: planninghullImage,
                text: "Planing hull",
            },
            {
                hullCode: HullCode.DH,
                available: false,
                img: displacementhullImage,
                text: "Displacement hull",
            },
        ],
    },
];

export const buyers: Buyer[] = [
    {
        buyerCode: BuyerCode.PNVAT,
        text: "Private in Norway incl. VAT",
        valueUnit: "Incl. VAT",
        value: "Private",
        invoice: true,
        enova: false,
        vat: true,
        private: true,
    },
    {
        buyerCode: BuyerCode.PONVAT,
        text: "Private outside of Norway excl. VAT",
        valueUnit: "Excl. VAT",
        value: "ForeignPrivate",
        invoice: true,
        enova: false,
        vat: false,
        private: true,
    },
    {
        buyerCode: BuyerCode.PRON,
        text: "Professional in Norway incl. Enova",
        valueUnit: "Incl. Enova",
        value: "Company",
        enova: true,
        invoice: false,
        vat: false,
        private: false,
    },
    {
        buyerCode: BuyerCode.PROON,
        text: "Professional outside of Norway excl. VAT",
        valueUnit: "Excl. VAT",
        value: "ForeignCompany",
        invoice: false,
        enova: false,
        vat: false,
        private: false,
    },
];

export interface Configuration {
    boat: Boat[];
    fuel: Fuel[];
    buyer: Buyer[];
    hours: number;
    speed: number;
    consumption: number;
}

export const fuels: Fuel[] = [
    {
        fuelCode: FuelCode.PETROL,
        text: "Petrol",
        type: FuelType.Petrol,
    },
    {
        fuelCode: FuelCode.DIESEL,
        text: "Diesel",
        type: FuelType.Diesel,
    },
];

export const configuration: Configuration = {
    hours: 50,
    speed: 25,
    consumption: 1.2,
    fuel: fuels,
    boat: boats,
    buyer: buyers,
};
