// Package dependencies.
import { Box, SxProps, Typography } from "@mui/material";
import React from "react";
import ReactMarkdown from "react-markdown";

// Local dependencies.
import { ScreenType } from "../../store/engines/screen";
import ScreenImages from "./ScreenImages";

interface ScreenInformationProps {
    screenType: ScreenType;
    text: string;
}

// Export ScreenInformation as default component.
export default ScreenInformation;

/**
 * Renders an information section displaying related information about the given
 * screen system.
 *
 * @param props The related component props.
 */
function ScreenInformation({ screenType, text }: ScreenInformationProps) {
    const sxBox: SxProps = {
        "& ul": {
            paddingInlineStart: "20px",
            "& li": {
                lineHeight: "23px",
            },
        },
    };

    return (
        <>
            <ScreenImages screen={screenType} />
            <Box sx={sxBox}>
                <Typography fontSize="2em" fontWeight="bold" textTransform="uppercase">
                    {screenType.name + " "}
                    <Typography fontSize="inherit" component="span" fontWeight="normal">
                        High Contrast TFT Touchscreen
                    </Typography>
                </Typography>
                <ReactMarkdown children={text} />
            </Box>
        </>
    );
}
