// Package dependencies.
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import React, { FunctionComponent } from "react";

// Local dependencies.
import SystemSpecPower from "./SystemSpecPower";
import SystemSpecWeight from "./SystemSpecWeight";
import SystemSpecRange from "./SystemSpecRange";

interface SystemSpecProps {
    type?: "battery" | "motor" | "system";
}

const CustomDivider = () => <Divider flexItem orientation="vertical" sx={{ borderColor: "primary.main" }} />;

const SystemSpec: FunctionComponent<SystemSpecProps> = ({ type }: SystemSpecProps) => {
    return (
        <Box
            sx={{
                alignItems: "center",
                display: "flex",
                textAlign: "center",
                mt: "14px",
                justifyContent: "space-evenly",
            }}
        >
            <SystemSpecPower />
            <CustomDivider />
            <SystemSpecWeight type={type} />
            <CustomDivider />
            <SystemSpecRange />
        </Box>
    );
};

export default SystemSpec;
