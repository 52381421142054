// Package dependencies.
import { Button } from "@mui/material";
import React, { memo } from "react";
import { NavLink } from "react-router-dom";

// Local dependencies.
import { PageLocation } from "../../common/page";
import { getLocalizedUrl, useLocalization } from "../Localization/LocalizationProvider";

interface HeaderMenuNavigationProps {
    isBatteryAvailable: boolean;
    isMotorAvailable: boolean;
}

// Export HeaderMenuNavigation with memoization as default component.
export default memo(HeaderMenuNavigation);

/**
 * Renders the header menu navigation. Displays a list of navigation items
 * depending on the battery and motor availability.
 *
 * @param props The related component props.
 */
function HeaderMenuNavigation({ isBatteryAvailable, isMotorAvailable }: HeaderMenuNavigationProps): JSX.Element {
    const items: [PageLocation, string][] = [[PageLocation.Engine, "MOTOR"]];
    const { country } = useLocalization();

    // Add battery page if motor is available.
    if (isMotorAvailable) {
        items.push([PageLocation.Battery, "BATTERY"]);
    }

    // Add remaining product pages if both battery and motor is available,
    // otherwise add the information page.
    if (isMotorAvailable && isBatteryAvailable) {
        items.push([PageLocation.Screen, "OPTIONS"]);
        items.push([PageLocation.Charger, "CHARGER"]);
        items.push([PageLocation.Summary, "SUMMARY"]);
    } else {
        items.push([PageLocation.Information, "KEEP ME UPDATED"]);
    }

    // Render the navigation list.
    return (
        <nav>
            {items.map(([to, name], index) => (
                <Button
                    color="inherit"
                    component={NavLink}
                    key={`button-${index}`}
                    size="large"
                    to={getLocalizedUrl(country, to)}
                >
                    {`${index + 1}. ${name}`}
                </Button>
            ))}
        </nav>
    );
}
