// Package dependencies.
import { Theme, Typography, useMediaQuery } from "@mui/material";
import React from "react";

// Local dependencies.
import { useBuyer } from "../../common/configuration";
import { withDirectAccessGuard } from "../../common/directAccessGuard";
import { getMotorImageURL } from "../../common/motorImages";
import { findOrderType, getCurrentProductSelections } from "../../common/utils";
import { motorPlacements } from "../../store/engines/motor";
import InformationImage, { getParentContainerSx } from "../InformationImage/InformationImage";
import LayoutProducts from "../Layout/LayoutProduct";
import { useLocalization } from "../Localization/LocalizationProvider";
import { useProductSelections } from "../Providers/ProductSelectionsProvider";
import OrderLeadForm from "../Summary/OrderLeadForm";

export default withDirectAccessGuard(Information);

/**
 * Renders an information page where the user can enter their email and
 * subscribe to newsletter.
 *
 * @param props The related component props.
 */
function Information(): JSX.Element {
    const isMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
    const { products } = useLocalization();
    const selections = useProductSelections();
    const productSelections = getCurrentProductSelections(selections, products);
    const orderType = findOrderType(productSelections);
    const motor = productSelections.motor;
    const motorPlacement = motorPlacements[motor.placement];
    const buyer = useBuyer();

    return (
        <LayoutProducts
            columnInformation={
                <InformationImage
                    alt={`${motorPlacement.name} motor`}
                    src={getMotorImageURL(motor.placement, motor.series, "main")}
                    useGradient={!isMdDown}
                />
            }
            columnSelections={
                <>
                    <Typography component="h1" variant="h4">
                        WANT MORE <b>PRODUCT INFORMATION?</b>
                    </Typography>
                    <Typography sx={{ marginTop: 4 }}>
                        We will keep you updated with all the latest news about our electric propulsion systems. You
                        will be the first to know product news and be invited to pre-orders before everyone else.
                    </Typography>
                    <Typography sx={{ marginTop: 4, marginBottom: 2 }}>Sign up to be updated!</Typography>
                    <OrderLeadForm
                        buyer={buyer}
                        interestedInLandBasedCharger={selections.interestedInLandBasedCharger}
                        interestedInRangeExtender={selections.interestedInRangeExtender}
                        orderType={orderType}
                        productSelections={productSelections}
                    />
                </>
            }
            sxBoxColumnInformation={getParentContainerSx(!isMdDown)}
            xsReverseDirection
        />
    );
}
