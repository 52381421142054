import { BatterySizeCategory } from "./types";

export interface BatteryPack {
    category: BatterySizeCategory;
    code: BatteryPackCode;
    kwh: number;
    name: string;
    type: BatteryTypeCode;
    units: number;
}

export enum BatteryPackCode {
    V400EnergyMedium = "400V_E_M",
    V400EnergyLarge = "400V_E_L",
    V400EnergyXLarge = "400V_E_XL",
    V800EnergyMedium = "800V_E_M",
    V800EnergyLarge = "800V_E_L",
    V800EnergyXLarge = "800V_E_XL",
}

export interface BatteryType {
    certification: string;
    code: BatteryTypeCode;
    continuousCharge: number;
    continuousDischarge: number;
    depthOfDischarge: number;
    description: string;
    /**
     * Dimensions in millimeters formatted as L x W x H.
     */
    dimensions: string;
    lifetimeCycles: number;
    name: string;
    peakDischarge: number;
    /**
     * The weight of a single battery unit in kilograms.
     */
    weight: number;
    /**
     * The weight of additional equipment, like cables and cooling liquid.
     */
    weightAdditional: number;
}

export enum BatteryTypeCode {
    Energy = "E",
}

export const batteryTypes: Record<BatteryTypeCode, BatteryType> = {
    [BatteryTypeCode.Energy]: {
        certification: "CE",
        code: BatteryTypeCode.Energy,
        continuousCharge: 1,
        continuousDischarge: 2,
        depthOfDischarge: 70,
        description: "Lifecycle 3.000 cycles - 70% DOD",
        dimensions: "1111 x 795 x 351",
        lifetimeCycles: 3000,
        name: "Energy",
        peakDischarge: 4,
        weight: 380,
        weightAdditional: 20,
    },
};

export const batteryPacks: Record<BatteryPackCode, BatteryPack> = {
    [BatteryPackCode.V400EnergyMedium]: {
        category: BatterySizeCategory.Medium,
        code: BatteryPackCode.V400EnergyMedium,
        kwh: 63,
        name: "Normal Range",
        type: BatteryTypeCode.Energy,
        units: 1,
    },
    [BatteryPackCode.V400EnergyLarge]: {
        category: BatterySizeCategory.Large,
        code: BatteryPackCode.V400EnergyLarge,
        kwh: 126,
        name: "Long Range",
        type: BatteryTypeCode.Energy,
        units: 2,
    },
    [BatteryPackCode.V400EnergyXLarge]: {
        category: BatterySizeCategory.XLarge,
        code: BatteryPackCode.V400EnergyXLarge,
        kwh: 189,
        name: "X-Long Range",
        type: BatteryTypeCode.Energy,
        units: 3,
    },
    [BatteryPackCode.V800EnergyMedium]: {
        category: BatterySizeCategory.Medium,
        code: BatteryPackCode.V800EnergyMedium,
        kwh: 126,
        name: "Normal Range",
        type: BatteryTypeCode.Energy,
        units: 2,
    },
    [BatteryPackCode.V800EnergyLarge]: {
        category: BatterySizeCategory.Large,
        code: BatteryPackCode.V800EnergyLarge,
        kwh: 252,
        name: "Long Range",
        type: BatteryTypeCode.Energy,
        units: 4,
    },
    [BatteryPackCode.V800EnergyXLarge]: {
        category: BatterySizeCategory.XLarge,
        code: BatteryPackCode.V800EnergyXLarge,
        kwh: 378,
        name: "X-Long Range",
        type: BatteryTypeCode.Energy,
        units: 6,
    },
};
