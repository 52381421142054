import { MotorPlacementCode, MotorSeriesCode } from "../store/engines/motor";
import { BoatCode, BuyerCode, FuelCode, HullCode, UsageInformation } from "../types/UsageInformation";

const DEFAULT_CONFIGS: Record<"I" | "I_BS" | "O", UsageInformation> = {
    I: {
        boatCode: BoatCode.BB,
        buyerCode: BuyerCode.PONVAT,
        hullCode: HullCode.PH,
        fuelCode: FuelCode.DIESEL,
        hours: 50,
        speed: 25,
        consumption: 1.5,
    },
    I_BS: {
        boatCode: BoatCode.BB,
        buyerCode: BuyerCode.PONVAT,
        hullCode: HullCode.PH,
        fuelCode: FuelCode.DIESEL,
        hours: 50,
        speed: 20,
        consumption: 0.8,
    },
    O: {
        boatCode: BoatCode.BB,
        buyerCode: BuyerCode.PONVAT,
        hullCode: HullCode.PH,
        fuelCode: FuelCode.PETROL,
        hours: 50,
        speed: 25,
        consumption: 1.2,
    },
};

export function getDefaultConfig(placement: MotorPlacementCode, series: MotorSeriesCode | undefined): UsageInformation {
    const key = `${placement}_${series}`;
    const config =
        key in DEFAULT_CONFIGS ? DEFAULT_CONFIGS[key as keyof typeof DEFAULT_CONFIGS] : DEFAULT_CONFIGS[placement];

    // Create a copy of the default config object.
    return { ...config };
}

export function convertQueryToConfig(query?: string): UsageInformation | null {
    if (query) {
        // Parse query string into values.
        const [a, b, c, d, e, f, g] = query.split("_");
        const boatCode: BoatCode | null = a in BoatCode ? (a as BoatCode) : null;
        const hullCode: HullCode | null = b in HullCode ? (b as HullCode) : null;
        const fuelCode: FuelCode | null = c in FuelCode ? (c as FuelCode) : null;
        const hours = parseFloat(d);
        const speed = parseFloat(e);
        const consumption = parseFloat(f);
        const buyerCode: BuyerCode | null = g in BuyerCode ? (g as BuyerCode) : null;

        // Validate values before returning the resulting config object.
        if (boatCode && hullCode && fuelCode && !isNaN(hours) && !isNaN(speed) && !isNaN(consumption) && buyerCode) {
            return {
                boatCode,
                consumption,
                fuelCode,
                hours,
                hullCode,
                speed,
                buyerCode,
            };
        }
    }
    return null;
}
