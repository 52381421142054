import * as React from "react";
import NumberFormat from "react-number-format";
import { applyVatIfNeeded, getCurrentProductSelections } from "../../common/utils";
import "./summaryListItem.scss";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import charger from "../../assets/charger_small.png";
import screen from "../../assets/screen_small.png";
import battery from "../../assets/white_battery_small.png";
import { NavLink } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { getMotorImageURL } from "../../common/motorImages";
import { Buyer } from "../../types/UsageInformation";
import { useSelectedMotorTypeCode } from "../Providers/ProductSelectionsProvider";
import { motorTypes } from "../../store/engines/motor";

type Props = {
    currencySymbol: string;
    id?: string;
    nav: string;
    type: string;
    primaryTextTopBold?: string;
    primaryTextBottom?: string;
    price: number;
    included: boolean;
    selectedBuyer: Buyer;
};
const SummaryListItem = (props: Props) => {
    const motorTypeCode = useSelectedMotorTypeCode();
    const {
        currencySymbol,
        selectedBuyer: { vat: applyVat },
    } = props;

    const findImage = (type: string) => {
        switch (type) {
            case "motor":
                const { placement, series } = motorTypes[motorTypeCode];
                return getMotorImageURL(placement, series, "thumbnail");
            case "battery":
                return battery;
            case "charger":
                return charger;
            case "screen":
                return screen;
        }
    };

    const findYellowText = (type: string) => {
        switch (type) {
            case "motor":
                return "Change motor";
            case "battery":
                return "Change battery";
            case "charger":
                return "Change charger";
            case "screen":
                return "Change touchscreen";
        }
    };

    return (
        <>
            {props.type !== "totalprice" ? (
                <NavLink className="nav-link" to={props.nav}>
                    <div className="image-box">
                        <img src={findImage(props.type)} alt={props.type}></img>
                    </div>
                    <div className="info-box">
                        <div className="yellow-text-row">
                            <span>
                                <div className="change-selections-text">
                                    {findYellowText(props.type)}
                                    <EditIcon />
                                </div>
                            </span>
                        </div>
                        <div className="name-row">
                            {props.type === "motor" ? (
                                <span>
                                    {props.primaryTextTopBold} <span style={{ fontWeight: "normal" }}>SERIES</span>
                                </span>
                            ) : (
                                <span>{props.primaryTextTopBold}</span>
                            )}
                        </div>
                        <div className="row">
                            <span id="left">{props.primaryTextBottom}</span>

                            {props.included ? (
                                <span id="included">INCLUDED</span>
                            ) : (
                                props.price > -1 && (
                                    <span>
                                        <span id="currency">{currencySymbol}&nbsp;</span>
                                        <NumberFormat
                                            id={"price"}
                                            value={applyVatIfNeeded(props.price, applyVat)}
                                            displayType={"text"}
                                            defaultValue="-"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                        />
                                    </span>
                                )
                            )}
                        </div>
                    </div>
                </NavLink>
            ) : (
                <div className="sum boxtext">
                    <span className="total-currency">
                        {" "}
                        {`TOTAL ${applyVat ? "incl." : "excl."} VAT ${currencySymbol} `}
                        <NumberFormat
                            id={"price"}
                            value={applyVatIfNeeded(props.price, applyVat)}
                            displayType={"text"}
                            defaultValue="-"
                            thousandSeparator={"."}
                            decimalSeparator={","}
                        />
                    </span>
                    <br />
                    <span>Incoterms Ex Works (EXW) Florø</span>
                </div>
            )}
        </>
    );
};

export default SummaryListItem;
