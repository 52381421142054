// Package dependencies.
import * as React from "react";

// Local dependencies.
import checkmark from "../../assets/checkmark.svg";
import "./CheckboxButton.scss";

interface CheckboxButtonProps {
    active?: boolean;
    label?: string;
    onClick?: () => void;
}

// Export the default component.
export default CheckboxButton;

function CheckboxButton({ active, label, onClick }: CheckboxButtonProps): JSX.Element {
    return (
        <button className={`${active ? "active " : ""}checkbox-button`} onClick={onClick}>
            <div className="checkmark-wrapper checkmark-wrapper-margin">
                <div className="checkmark-square">
                    {active && <img className="checkmark-image-small" src={checkmark} alt="checkmark" />}
                </div>
                <span className="checkbox-button-label">{label}</span>
            </div>
        </button>
    );
}
