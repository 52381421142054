// Package dependencies.
import { MenuItem, TextField } from "@mui/material";
import React from "react";

// Local dependencies.
import enova from "../../assets/enova.png";
import { useConfiguration } from "../../common/configuration";
import { useConfigurationPopup } from "../../common/useConfigurationPopup";
import { Buyer } from "../../types/UsageInformation";

interface ModalBuyerPageProps {
    buyers: Buyer[];
    selectedBuyer: Buyer;
}

const nextPage = {
    name: "Type of boat",
    page: 2,
};

// Export ModalBuyerPage as the default component.
export default ModalBuyerPage;

/**
 * Renders a buyer page for the modal component.
 *
 * @param props The related component props.
 */
function ModalBuyerPage({ buyers, selectedBuyer }: ModalBuyerPageProps): JSX.Element {
    const { setBuyerCode } = useConfiguration();
    const { navigate } = useConfigurationPopup();

    return (
        <>
            <div className="info">
                <h1>Cost saving calculator</h1>
                <p>
                    In order to estimate your potential savings we need some information from you. Follow the next 4
                    steps before finding your E-system.
                </p>
            </div>

            <h1>1. Type of buyer</h1>
            <p>Are you thinking of purchasing one of our systems as a private buyer or for a commercial activity?</p>
            <div className="row">
                <TextField
                    label="Buyer"
                    onChange={({ target: { value } }) => setBuyerCode(value)}
                    select
                    value={selectedBuyer.buyerCode}
                    variant="filled"
                    sx={{ minWidth: "50%" }}
                >
                    {buyers.map(({ buyerCode, text }) => (
                        <MenuItem key={buyerCode} value={buyerCode}>
                            {text}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            {selectedBuyer.enova && (
                <div>
                    <div className="row">
                        <img src={enova} alt="Enova icon" className="enova-icon" />
                    </div>
                    <div className="row">
                        <p>
                            Battery installation is an important step for shipping to the low-emission community. Enova
                            can provide financial support for investments in battery projects in your vessels, both new
                            builds and retrofit.
                        </p>
                        <p>
                            The amount of support is assessed individually for each project. For large companies,
                            support for the procurement and conversion of ships is limited up to 40 percent of the
                            additional cost, for small and medium-sized enterprises the corresponding restriction is 50
                            per cent.
                        </p>
                        <p>Evoy will help you apply.</p>
                    </div>
                    <div className="row">
                        <a
                            className="enova-link"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://www.enova.no/bedrift/maritim-transport/elektrifisering-av-sjotransport/"
                        >
                            Read more at Enova.no (Norwegian)
                        </a>
                    </div>
                </div>
            )}
            <div className="row navigation">
                <button className="nxt-btn" onClick={() => navigate(nextPage.page)}>
                    Next - {nextPage.name}
                </button>
            </div>
        </>
    );
}
