// Package dependencies.
import { Typography } from "@mui/material";
import React from "react";
import { useShouldApplyVat } from "../../common/configuration";
import { withDirectAccessGuard } from "../../common/directAccessGuard";
import { Availability, ProductDetails, ScreenProduct } from "../../store/engines";

// Local dependencies.
import { texts } from "../../store/engines/texts";
import { screenTypes } from "../../store/engines/screen";
import CheckboxButton from "../CheckboxButton/CheckboxButton";
import ProductChoiceButtonLists, { ProductList } from "../ChoiceButton/ProductChoiceButtonLists";
import LayoutProducts from "../Layout/LayoutProduct";
import { useLocalization } from "../Localization/LocalizationProvider";
import { useProductSelections } from "../Providers/ProductSelectionsProvider";
import ScreenInformation from "./ScreenInformation";

export default withDirectAccessGuard(TouchScreenComponent);

function TouchScreenComponent(): JSX.Element {
    const {
        currency,
        products: { screens },
    } = useLocalization();
    const {
        interestedInRangeExtender,
        screen: selectedScreen,
        selectScreenType,
        setInterestedInRangeExtender,
    } = useProductSelections();
    const screenType = screenTypes[selectedScreen];
    const shouldApplyVat = useShouldApplyVat();
    const lists = getScreenProductLists(screens);

    return (
        <LayoutProducts
            columnInformation={<ScreenInformation screenType={screenType} text={texts.screenText} />}
            columnSelections={
                <>
                    <Typography component="h1" variant="h4">
                        SELECT <b>TOUCHSCREEN</b>
                    </Typography>
                    <ProductChoiceButtonLists
                        currencySymbol={currency.symbol}
                        getDescription={getScreenDescription}
                        getName={getScreenName}
                        onClick={selectScreenType}
                        lists={lists}
                        selectedProductCode={selectedScreen}
                        shouldApplyVat={shouldApplyVat}
                    />
                    <Typography component="h2" variant="h6">
                        UPON REQUEST
                    </Typography>
                    <Typography component="h3" variant="h6">
                        <b>RANGE EXTENDER</b>{" "}
                        <span style={{ fontWeight: "normal" }}>WITH DIESEL, GAS, H2, OR ALCHOHOL</span>
                    </Typography>
                    <CheckboxButton
                        active={interestedInRangeExtender}
                        label="I'm interested"
                        onClick={() => setInterestedInRangeExtender(!interestedInRangeExtender)}
                    />
                </>
            }
            xsReverseDirection
        />
    );
}

function getScreenDescription({}: ScreenProduct): string {
    return "";
}
function getScreenName({ name }: ScreenProduct): string {
    return name;
}

function getScreenProductLists(screens: ScreenProduct[]): ProductList<ScreenProduct>[] {
    const sorting = undefined;
    return [
        [
            `product-choice-button-list-available`,
            getProductsByCodesAndAvailability(screens, null, [Availability.Available, Availability.PreOrder], sorting),
            undefined,
        ],
        [
            `product-choice-button-list-none`,
            getProductsByCodesAndAvailability(screens, null, [Availability.None], sorting),
            <>
                <span style={{ fontWeight: "normal" }}>COMING</span> LATER
            </>,
        ],
    ];
}

/**
 * Filters a list of batteries on availability and product codes. Returns the
 * resulting filtered list.
 *
 * @param batteries The complete list of batteries.
 * @param productCodes List of valid product codes.
 * @param availabilities List of valid availability values.
 * @param sorting Add custom sorting of the products.
 */
function getProductsByCodesAndAvailability<Product extends ProductDetails>(
    products: Product[],
    codes: Product["code"][] | null,
    availabilities: Availability[],
    sorting?: (a: Product, b: Product) => number
) {
    const result = products.filter(
        ({ availability, code }) => (!codes || codes.includes(code)) && availabilities.includes(availability)
    );

    return sorting ? result.sort(sorting) : result;
}
