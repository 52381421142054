import { CSSObject } from "@mui/material";

function getFontFace(filename: string, fontWeight: number, fontStyle: "normal" | "italic" = "normal"): CSSObject {
    return {
        fontDisplay: "auto",
        fontFamily: "Gotham",
        fontStyle,
        fontWeight,
        src: `url("/fonts/${filename}.woff2") format("woff2"), url("fonts/${filename}.woff") format("woff")`,
    };
}

function fontFaceToString({ fontFamily, fontDisplay, fontStyle, fontWeight, src }: CSSObject): string {
    return `
    @font-face {
      font-family: "${fontFamily}";
      font-style: ${fontStyle};
      font-display: ${fontDisplay};
      font-weight: ${fontWeight};
      src: ${src};
    }
  `;
}

export const gothamLight = getFontFace("Gotham-Light", 300);

export const gothamLightItalic = getFontFace("Gotham-LightItalic", 300, "italic");

export const gothamBook = getFontFace("Gotham-Book", 400);

export const gothamBookItalic = getFontFace("Gotham-BookItalic", 400, "italic");

export const gothamMedium = getFontFace("Gotham-Medium", 500);

export const gothamMediumItalic = getFontFace("Gotham-MediumItalic", 500, "italic");

export const gothamBold = getFontFace("Gotham-Bold", 700);

export const gothamBoldItalic = getFontFace("Gotham-BoldItalic", 700, "italic");

const gothamFonts: CSSObject[] = [
    gothamLight,
    gothamLightItalic,
    gothamBook,
    gothamBookItalic,
    gothamMedium,
    gothamMediumItalic,
    gothamBold,
    gothamBoldItalic,
];

const gothamFontFaces: string = gothamFonts.map(fontFaceToString).join("\n");

export default gothamFontFaces;
