import React, { FunctionComponent } from "react";
import { useDispatch } from "react-redux";
import { DischargeType, setDischargeType } from "../../store/engines";
import "./SelectDischargeType.scss";

type SelectDischargeTypeProps = {
    onSelected: (dischargeType: DischargeType) => void;
};

const SelectDischargeType: FunctionComponent<SelectDischargeTypeProps> = ({ onSelected }) => {
    const dispatch = useDispatch();

    const dischargeTypeClicked = (dischargeType: DischargeType) => {
        dispatch(setDischargeType(dischargeType));
        onSelected(dischargeType);
    };

    return (
        <div className="select-discharge-type-container">
            <h1>Select discharge measure</h1>
            <button onClick={() => dischargeTypeClicked(DischargeType.Continuous)}>Continuous Power</button>
            <button onClick={() => dischargeTypeClicked(DischargeType.Peak)}>Peak Power</button>
        </div>
    );
};

export default SelectDischargeType;
