// Package dependencies.
import { ArrowBack } from "@mui/icons-material";
import { AppBar, Box, IconButton, SxProps, Theme, Toolbar, useMediaQuery } from "@mui/material";
import ButtonBase from "@mui/material/ButtonBase";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

// Local dependencies.
import { getPreviousPageFromPathname, PageLocation } from "../../common/page";
import { getCurrentProductSelections } from "../../common/utils";
import { Availability } from "../../store/engines";
import { getLocalizedUrl, useLocalization } from "../Localization/LocalizationProvider";
import { useProductSelections } from "../Providers/ProductSelectionsProvider";
import EvoySpeed, { aspectRatio } from "./EvoySpeed";
import HeaderMenuLocale from "./HeaderMenuLocale";
import HeaderMenuNavigation from "./HeaderMenuNavigation";

const enableLocalization = process.env.REACT_APP_ENABLE_LOCALIZATION === "true";

// Logo image options.
const MAX_HEIGHT_LOGO = { xs: 64, md: 80 };
const MAX_WIDTH_LOGO = { xs: MAX_HEIGHT_LOGO.xs * aspectRatio, md: MAX_HEIGHT_LOGO.md * aspectRatio };

const sxImageBox: SxProps = {
    display: "flex",
    maxHeight: MAX_HEIGHT_LOGO,
    maxWidth: MAX_WIDTH_LOGO,
    position: "relative",
    "& svg": {
        pointerEvents: "none",
    },
};

// Export HeaderMenu with redux store and memoization as default component.
export default HeaderMenu;

/**
 * Renders a header menu with logo, navigation, and locale selector.
 *
 * @param props The related component props.
 */
function HeaderMenu(): JSX.Element {
    const { country, products } = useLocalization();
    const { pathname } = useLocation();
    const isMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
    const previousPage = getPreviousPageFromPathname(country, pathname);
    const shouldDisplayNavigation = !isMdDown && pathname !== PageLocation.Confirmation;
    const selections = useProductSelections();
    const { battery, motor } = getCurrentProductSelections(selections, products);
    const isBatteryAvailable = battery.availability !== Availability.None;
    const isMotorAvailable = motor.availability !== Availability.None;
    return (
        <AppBar color="default">
            <Toolbar>
                {previousPage && isMdDown && (
                    <IconButton
                        color="inherit"
                        component={NavLink}
                        size="large"
                        to={getLocalizedUrl(country, previousPage.url)}
                    >
                        <ArrowBack />
                    </IconButton>
                )}
                <ButtonBase sx={sxImageBox} href="https://www.evoy.no/">
                    <EvoySpeed />
                </ButtonBase>
                {shouldDisplayNavigation && (
                    <HeaderMenuNavigation isBatteryAvailable={isBatteryAvailable} isMotorAvailable={isMotorAvailable} />
                )}
                <Box sx={{ flexGrow: 1 }} />
                {enableLocalization && <HeaderMenuLocale />}
            </Toolbar>
        </AppBar>
    );
}
