// Package dependencies.
import { SxProps } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { ReactNode } from "react";

interface SpecificationProps {
    children?: ReactNode;
    subtitle: string;
    title: string;
}

const sxContent: SxProps = {
    fontSize: { xs: "15px", lg: "22px" },
    color: "#666666",
    mt: "0.5em",
    mb: 0,
    "& button": {
        boxSizing: "border-box",
        display: "inline-block",
        fontSize: "inherit",
        color: "inherit",
        border: "none",
        backgroundColor: "transparent",
        borderBottom: "2px solid",
        borderColor: "primary.main",
        outline: "none",
        padding: 0,
        margin: 0,

        "&:hover, &:focus": {
            color: "primary.main",
        },
    },
};
const sxTitle: SxProps = {
    fontSize: { xs: "20px", lg: "32px" },
    fontWeight: "bold",
    margin: 0,
};
const sxSubtitle = {
    fontSize: { xs: "16px", lg: "18px" },
    fontWeight: "normal",
    margin: 0,
};

// Export Spec as default component.
export default Specification;

function Specification({ children, subtitle, title }: SpecificationProps) {
    return (
        <div>
            <Typography sx={sxTitle}>{title}</Typography>
            <Typography sx={sxSubtitle}>{subtitle}</Typography>
            <Typography sx={sxContent}>{children}</Typography>
        </div>
    );
}
