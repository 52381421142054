import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ChangeEvent } from "react";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import { MenuItem } from "@mui/material";
import Collapse from "@mui/material/Collapse";
import Grid from "@mui/material/Grid";

import { SelectFieldProps } from "./types";

function SelectField({ error, label, name, onChange, required, value, grid, other }: SelectFieldProps) {
    const gridProp = grid || { xs: 12 };
    const props = {
        error: !!error,
        label,
        name,
        required,
        onChange: onChange && ((event: SelectChangeEvent) => onChange(name, event.target.value)),
        value,
    };
    const otherProps = {
        error: !!other.error,
        label: other.label,
        name: other.name,
        required: other.required,
        onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
            onChange && onChange(other.name, event.target.value),
        value: other.value,
    };
    return (
        <>
            <Grid item {...grid}>
                <FormControl variant="filled" sx={{ minWidth: 320 }}>
                    <InputLabel id="demo-simple-select-label">{label}</InputLabel>
                    <Select {...props}>
                        <MenuItem value="General Web Search">General Web Search</MenuItem>
                        <MenuItem value="Google Ads">Google Ads</MenuItem>
                        <MenuItem value="Social Media">Social Media</MenuItem>
                        <MenuItem value="Magazines">Magazines</MenuItem>
                        <MenuItem value="Events">Events</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                    </Select>
                    {error && <FormHelperText>{error}</FormHelperText>}
                </FormControl>
            </Grid>
            <Grid item {...grid}>
                <Collapse in={value === "Other"} {...gridProp} sx={{ width: "100%" }}>
                    <FormControl fullWidth>
                        <TextField {...otherProps} variant="filled" />
                    </FormControl>
                </Collapse>
            </Grid>
        </>
    );
}

export default SelectField;
