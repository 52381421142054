// Package dependencies.
import { MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";

// Local dependencies.
import backIcon from "../../assets/back-icon.svg";
import spinner from "../../assets/spinner.svg";
import { getApiClient } from "../../common/apiHelper";
import { useConfiguration } from "../../common/configuration";
import { useConfigurationPopup } from "../../common/useConfigurationPopup";
import { Boat, Buyer, Hull } from "../../types/UsageInformation";

interface Lead {
    segment: string | null;
    boat: string | null;
    hull: string | null;
    email: string | null;
}

interface LeadErrors {
    Email?: string[];
}

interface ModalBoatPageProps {
    boats: Boat[];
    selectedBoat: Boat;
    selectedBuyer: Buyer;
    selectedHull: Hull;
}

const apiClient = getApiClient();
const backPage = {
    name: "Type of buyer",
    page: 1,
};
const nextPage = {
    name: "Type of use",
    page: 3,
};
const styleError = { color: "red" };

// Export ModalBoatPage as default component.
export default ModalBoatPage;

/**
 * Renders a boat page for the modal component.
 *
 * @param props The related component props.
 */
function ModalBoatPage({ boats, selectedBoat, selectedBuyer, selectedHull }: ModalBoatPageProps): JSX.Element {
    const { setBoatCode, setHullCode } = useConfiguration();
    const [email, setEmail] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(false);
    const [leadErrors, setLeadErrors] = useState<LeadErrors | null>(null);
    const { navigate } = useConfigurationPopup();
    return (
        <>
            <button className="nxt-btn btn-back" onClick={() => navigate(backPage.page)}>
                <img className="back-icon" src={backIcon} alt="back" /> back
            </button>
            <h1>2. Type of boat</h1>
            <p>What type of boat do you have today or are planning to use?</p>
            <div className="row boat-category">
                <TextField
                    label="Boat category"
                    onChange={({ target: { value } }) => setBoatCode(value)}
                    select
                    value={selectedBoat.boatCode}
                    variant="filled"
                    sx={{ minWidth: "50%" }}
                >
                    {boats.map(({ boatCode, text }) => (
                        <MenuItem key={boatCode} value={boatCode}>
                            {text}
                        </MenuItem>
                    ))}
                </TextField>
            </div>
            <div>
                {!selectedHull.available && (
                    <div>
                        <p>
                            Currently, we do not have a motor that support your type of boat. Since we are a start up
                            company we will listen if there is a lot of people wanting an electrical Evoy motor for this
                            kind of boat and hull. We would also notify you if we know somebody that might help you.
                        </p>
                        <p>If you want to be updated about electrical motors for your boat - let us know!</p>
                        <div className="row">
                            <div className="input-container">
                                <input
                                    onChange={({ target: { value } }) => setEmail(value)}
                                    className={leadErrors && leadErrors.Email ? "email invalid" : "email"}
                                    type="text"
                                    placeholder="Email Address"
                                />
                                <div>
                                    {leadErrors && leadErrors.Email && (
                                        <span style={styleError}>
                                            {leadErrors.Email.map((error: string, index: number) => (
                                                <span key={index}>
                                                    {error} <br />
                                                </span>
                                            ))}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <button
                                className="nxt-btn register-email-btn"
                                onClick={() =>
                                    sendLead(
                                        {
                                            boat: selectedBoat.text,
                                            email,
                                            hull: selectedHull.text,
                                            segment: selectedBuyer.value,
                                        },
                                        setError,
                                        setIsSubmitting,
                                        setLeadErrors,
                                        navigate
                                    )
                                }
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? <img src={spinner} alt="spinner" /> : "Register email"}
                            </button>
                            {error && !leadErrors && (
                                <p style={styleError}>Could not send lead. Please try again later</p>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className="type-boat">
                <>
                    <p>
                        What type of <strong>{selectedBoat.text}</strong> do you have?
                    </p>
                    <div className="row hulls">
                        {selectedBoat.hull.map((hull) => {
                            if (hull) {
                                return (
                                    <button
                                        key={`button-hull-${hull.hullCode}`}
                                        onClick={() => setHullCode(hull.hullCode)}
                                        className={hull.hullCode === selectedHull.hullCode ? "active" : ""}
                                    >
                                        <img src={hull.img} alt="The suggested hull" />
                                        <p>{hull.text}</p>
                                    </button>
                                );
                            }
                            return null;
                        })}
                    </div>
                </>
            </div>

            {selectedHull.available && (
                <div className="row navigation">
                    <button className="nxt-btn" onClick={() => navigate(nextPage.page)}>
                        Next - {nextPage.name}
                    </button>
                </div>
            )}
        </>
    );
}

async function sendLead(
    leadRequest: Lead,
    setError: (error: boolean) => void,
    setIsSubmitting: (isSubmitting: boolean) => void,
    setLeadErrors: (leadErrors: LeadErrors | null) => void,
    navigate: (page?: number) => void
) {
    try {
        //Reset any error
        setError(false);
        setIsSubmitting(true);
        await apiClient.post<Lead>("/leads", leadRequest);
        window.location.href = "https://www.evoy.no/thank-you-page-contact/";
        //Now close the modal
        navigate();
    } catch (error: any) {
        if (error.response) {
            setLeadErrors(error.response.data.errors);
        }
        setError(true);
        setIsSubmitting(false);
    }
}
