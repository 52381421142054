import { BatteryPack, batteryTypes } from "../store/engines/battery";
import { MotorType } from "../store/engines/motor";

interface SystemWeights {
    battery: number;
    batteryAdditional: number;
    motor: number;
    motorEquipment: number;
    systemTotal: number;
}

/**
 * Calculates the system weight in kilograms of either the battery, motor or the sum of the
 * total system.
 *
 * @param battery The battery type.
 * @param motor The motor type.
 */
export const getSystemWeightInKg = ({ type, units }: BatteryPack, motor: MotorType): SystemWeights => {
    const { weight, weightAdditional: batteryAdditional } = batteryTypes[type];
    const batteryWeight = units * weight;
    const { weightInKg, weightEquipmentInKg } = motor;
    const motorWeight = weightInKg ?? 0;
    const motorEquipment = weightEquipmentInKg ?? 0;
    const systemTotal: number = batteryWeight + batteryAdditional + motorWeight + motorEquipment;

    // Calculate the desired weight.
    return {
        battery: batteryWeight,
        batteryAdditional,
        motor: motorWeight,
        motorEquipment,
        systemTotal,
    };
};
