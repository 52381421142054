// Package dependencies.
import React from "react";
import { NavLink } from "react-router-dom";

// Local dependencies.
import "./KeepUpdatedSection.scss";

type KeepUpdatedSectionProps = {
    releaseYear: number;
};
export default KeepUpdatedSection;

function KeepUpdatedSection({ releaseYear }: KeepUpdatedSectionProps) {
    return (
        <div className="updated">
            <p>Hold tight! Available {releaseYear}</p>
            <div className="button-row">
                <div className="dashed-line"></div>
                <NavLink className="updated-button" to="/information" onClick={() => {}}>
                    <p>KEEP ME UPDATED</p>
                </NavLink>
                <div className="dashed-line"></div>
            </div>
        </div>
    );
}
