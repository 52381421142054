// Package dependencies.
import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";

// Local dependencies.
import { PageLocation } from "../../common/page";
import { getCurrentProductSelections } from "../../common/utils";
import { Availability, setConfigurationPopupState, ContactInformation } from "../../store/engines";
import { useLocalization } from "../Localization/LocalizationProvider";
import { useProductSelections } from "../Providers/ProductSelectionsProvider";
import Savings from "../Savings/Savings";
import "./Footer.scss";
import FooterButton from "./FooterButton";

// Separate state props + dispatch props to their own interfaces.
interface FooterComponentPropsFromState {}

interface FooterComponentPropsFromDispatch {
    setConfigurationPopupState: typeof setConfigurationPopupState;
}

type FooterComponentProps = FooterComponentPropsFromState & FooterComponentPropsFromDispatch;

const mapDispatchToProps: FooterComponentPropsFromDispatch = {
    setConfigurationPopupState,
};

// Export FooterComponent with redux store and router as default component.
export default connect(undefined, mapDispatchToProps)(FooterComponent);

/**
 * Renders a footer with a customer configuration popup.
 *
 * @param props The related props.
 */
function FooterComponent({ setConfigurationPopupState }: FooterComponentProps): JSX.Element {
    const { pathname } = useLocation();
    const selections = useProductSelections();
    const { products } = useLocalization();
    const productSelections = getCurrentProductSelections(selections, products);
    const { battery, motor } = productSelections;
    const systemUnavailable =
        motor.availability === Availability.None
            ? motor
            : battery.availability === Availability.None
            ? battery
            : undefined;
    const noFooterPages: string[] = [PageLocation.Information, PageLocation.Confirmation];
    const shouldDisplayFooter = !noFooterPages.includes(pathname);
    return (
        <>
            {shouldDisplayFooter && (
                <footer>
                    <section className="footer-container">
                        <div className="footer-top">
                            <div className="left-part">
                                {systemUnavailable ? (
                                    <div>
                                        <p className="small-msg font-light">Lifetime savings</p>
                                        <span className="large-msg">Stay tuned</span>
                                    </div>
                                ) : (
                                    <>
                                        <Savings productSelections={productSelections} />
                                        <div className="button-inputs-settings">
                                            <span
                                                onClick={() => setConfigurationPopupState({ isOpen: true, page: 1 })}
                                            ></span>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="footer-bottom">
                            <FooterButton isSystemAvailable={!systemUnavailable} onKeepUpdated={() => {}} />
                        </div>
                    </section>
                </footer>
            )}
        </>
    );
}
