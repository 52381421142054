import { Reducer } from "redux";
import { sanitizeEmail } from "../../common/utils";
import { EvoyState, EnginesActionTypes, DischargeType, ContactInformation } from "./types";

// Type-safe initialState!
function getInitialState(): EvoyState {
    return {
        dischargeType: DischargeType.Continuous,
        configurationPopupState: {
            isOpen: false,
            page: 1,
        },
        contactInformation: {
            firstname: null,
            lastname: null,
            businessname: null,
            email: null,
            phone: null,
            countryCode: "no",
            country: null,
            leadSource: null,
            leadSourceOther: null,
            privacyPolicy: false,
            subscribeToNewsletter: false,
            boatDescription: null,
        },
    };
}

const initialState: EvoyState = getInitialState();

const reducer: Reducer<EvoyState> = (state = initialState, action): EvoyState => {
    switch (action.type) {
        case EnginesActionTypes.SET_CONFIG_POPUP_STATE: {
            return { ...state, configurationPopupState: action.payload };
        }
        case EnginesActionTypes.SET_DISCHARGE_TYPE: {
            return { ...state, dischargeType: action.payload };
        }
        case EnginesActionTypes.CHANGE_CONTACT_INFO: {
            return { ...state, contactInformation: updateContactInfo(state, action.payload[0], action.payload[1]) };
        }

        default: {
            return state;
        }
    }
};

const updateContactInfo = (
    { contactInformation }: EvoyState,
    name: string,
    value: string | boolean
): ContactInformation => {
    if (
        typeof value === "string" &&
        [
            "firstname",
            "lastname",
            "businessname",
            "email",
            "phone",
            "countryCode",
            "country",
            "leadSource",
            "leadSourceOther",
            "boatDescription",
        ].includes(name)
    ) {
        return { ...contactInformation, [name]: name === "email" ? sanitizeEmail(value) : value };
    } else if (typeof value === "boolean" && name === "privacyPolicy") {
        return { ...contactInformation, [name]: value };
    } else if (typeof value === "boolean" && name === "subscribeToNewsletter") {
        return { ...contactInformation, [name]: value };
    } else {
        return contactInformation;
    }
};

export { reducer as engineReducer };
