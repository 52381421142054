// Package dependencies.
import { Box, SxProps } from "@mui/material";
import React, { ReactNode } from "react";
import NumberFormat from "react-number-format";

// Local dependencies.
import { applyVatIfNeeded } from "../../common/utils";
import "./ChoiceButton.scss";

const sxCurrencyBox: SxProps = {
    fontSize: { xs: "12px", md: "14px" },
};

interface ChoiceButtonProps {
    active: boolean;
    available: boolean;
    currencySymbol: string;
    expected: string | null;
    onClick: () => void;
    price: number;
    primaryTextBottom: string | ReactNode;
    primaryTextTop: string;
    shouldApplyVat: boolean;
}

// Export ChoiceButton as default component.
export default ChoiceButton;

function ChoiceButton({
    active,
    available,
    currencySymbol,
    expected,
    onClick,
    price,
    primaryTextBottom,
    primaryTextTop,
    shouldApplyVat,
}: ChoiceButtonProps): JSX.Element {
    const className = `choice-button ${active ? "active" : ""} ${!available ? "ribbon" : ""}`;
    const leftClassName = "left";

    return (
        <button onClick={onClick} className={className}>
            {!available && (
                <div className="ribbon-shape-wrapper">
                    <div className="ribbon-shape">HOLD TIGHT</div>
                </div>
            )}

            <div className="rows-container">
                <div className="top-row">
                    <div className={leftClassName}>
                        <span>{primaryTextTop}</span>
                    </div>

                    <div className="right">
                        {price === 0 ? (
                            <span>Included</span>
                        ) : (
                            price > -1 && (
                                <span>
                                    <Box component="span" sx={sxCurrencyBox}>
                                        {currencySymbol}{" "}
                                    </Box>
                                    <span className="price-big-text">
                                        <NumberFormat
                                            value={applyVatIfNeeded(price, shouldApplyVat)}
                                            displayType={"text"}
                                            defaultValue="-"
                                            thousandSeparator={"."}
                                            decimalSeparator={","}
                                        />
                                    </span>
                                </span>
                            )
                        )}
                    </div>
                </div>
                <div className="bottom-row">
                    <div className={leftClassName}>
                        <span>{primaryTextBottom}</span>
                    </div>
                    <div className="right">
                        <span className={active && available ? "boxtext-right-blue" : ""}>{expected}</span>
                    </div>
                </div>
            </div>
        </button>
    );
}
