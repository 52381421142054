// Package dependencies.
import { Button, SxProps, Theme, Typography, useMediaQuery } from "@mui/material";
import React from "react";

// Local dependencies.
import Checkmark from "../../assets/checkmark.png";
import { withDirectAccessGuard } from "../../common/directAccessGuard";
import { getCurrentProductSelections } from "../../common/utils";
import { Availability } from "../../store/engines";
import InformationImage, { getParentContainerSx } from "../InformationImage/InformationImage";
import LayoutProducts from "../Layout/LayoutProduct";
import { useLocalization } from "../Localization/LocalizationProvider";
import { useProductSelections } from "../Providers/ProductSelectionsProvider";
import "./Confirmation.scss";

const isNotAvailableMainText =
    "We will send you news letters and invite you to our Evoy events. You will also be the first to know when we are launching new products.";
const isAvailableMainText =
    "Your information is registered. You will be contacted by our sales team within 2 business days.";
const sxMainText: SxProps = { my: 2 };

// Export Confirmation with redux and access guard as default component.
export default withDirectAccessGuard(Confirmation);

function Confirmation() {
    const isMdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
    const { products } = useLocalization();
    const selections = useProductSelections();
    const { battery, motor } = getCurrentProductSelections(selections, products);
    const isAvailable = motor.availability !== Availability.None && battery.availability !== Availability.None;
    const confirmation = isAvailable ? "REGISTRATION" : "INTEREST";
    const mainText = isAvailable ? isAvailableMainText : isNotAvailableMainText;
    return (
        <LayoutProducts
            columnInformation={<InformationImage alt="Checkmark" src={Checkmark} useGradient={!isMdDown} />}
            columnSelections={
                <>
                    <Typography component="h1" variant="h4">
                        <b>THANK YOU</b>&nbsp;FOR YOUR {confirmation}
                    </Typography>
                    <Typography sx={sxMainText}>{mainText}</Typography>
                    <Button onClick={handleHomepageClick} variant="contained">
                        GO TO HOMEPAGE
                    </Button>
                </>
            }
            sxBoxColumnInformation={getParentContainerSx(!isMdDown)}
            xsReverseDirection
        />
    );
}

/**
 * Redirects the user to Evoy's homepage.
 */
function handleHomepageClick() {
    window.open("https://evoy.no", "_self");
}
