import { getAvailabilityDescription } from "../../common/utils";
import { Availability, ProductDetails } from "../../store/engines";
import ChoiceButton from "./ChoiceButton";

interface ProductChoiceButtonProps<ProductType extends ProductDetails> {
    currencySymbol: string;
    getDescription: (product: ProductType) => string;
    getName: (product: ProductType) => string;
    onClick: (code: ProductType["code"]) => void;
    product: ProductType;
    selectedProductCode: ProductType["code"] | undefined;
    shouldApplyVat: boolean;
}

export default ProductChoiceButton;

function ProductChoiceButton<ProductType extends ProductDetails>({
    currencySymbol,
    getDescription,
    getName,
    onClick,
    product,
    selectedProductCode,
    shouldApplyVat,
}: ProductChoiceButtonProps<ProductType>) {
    const { availability, code, price, releaseYear } = product;
    const description = getDescription(product);
    const name = getName(product);
    const availabilityDescription = getAvailabilityDescription(availability, releaseYear);
    return (
        <ChoiceButton
            active={code === selectedProductCode}
            currencySymbol={currencySymbol}
            price={price}
            expected={availabilityDescription}
            primaryTextBottom={description}
            primaryTextTop={name}
            shouldApplyVat={shouldApplyVat}
            available={availability !== Availability.None}
            onClick={() => onClick(code)}
        ></ChoiceButton>
    );
}
