// Package dependencies.
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

// Local dependencies.
import { getNextPageFromPathname } from "../../common/page";
import { getLocalizedUrl, useLocalization } from "../Localization/LocalizationProvider";

interface FooterButtonProps {
    isSystemAvailable: boolean;
    onKeepUpdated: () => void;
}

// Export FooterButton as the default component.
export default FooterButton;

/**
 * Renders the footer button. Renders a submit button on the summary page, or
 * renders a keep updated button if the system is unavailable, or renders a next
 * button on a product page, or renders an empty button container on any other
 * page.
 *
 * @param props The related component props.
 */
function FooterButton({ isSystemAvailable, onKeepUpdated }: FooterButtonProps) {
    const { pathname } = useLocation();
    const { country } = useLocalization();
    const nextPage = getNextPageFromPathname(country, pathname);

    // Render next button, keep updated button, submit button, or empty fragment
    return (
        <div className="footer-button-container">
            {!isSystemAvailable ? (
                <NavLink className="nxt-btn" to="/information" onClick={onKeepUpdated}>
                    <p className="spacing btn-text">KEEP ME UPDATED</p>
                </NavLink>
            ) : nextPage ? (
                <NavLink className="nxt-btn" to={getLocalizedUrl(country, nextPage.url)}>
                    <p className="spacing btn-text">NEXT&nbsp;-&nbsp;{nextPage.title.toUpperCase()}</p>
                </NavLink>
            ) : (
                <></>
            )}
        </div>
    );
}
