// Package dependencies.
import Box from "@mui/material/Box";
import React from "react";

// Local dependencies.
import { useConfiguration } from "../../common/configuration";
import { getMotorImageURL } from "../../common/motorImages";
import { MotorPlacement, MotorPlacementCode, motorPlacements, motorTypes } from "../../store/engines/motor";
import { useProductSelections, useSelectedMotorTypeCode } from "../Providers/ProductSelectionsProvider";
import "./MotorPlacementSelector.scss";

type MotorPlacementOptionProps = {
    motorPlacement: MotorPlacement;
};

export default MotorPlacementSelector;

function MotorPlacementOption({ motorPlacement: { code, name } }: MotorPlacementOptionProps) {
    const { resetDefault } = useConfiguration();
    const { motorPlacement, selectMotorPlacement } = useProductSelections();
    const selectedMotorTypeCode = useSelectedMotorTypeCode(code);
    const { series } = motorTypes[selectedMotorTypeCode];

    const setMotorType = () => {
        selectMotorPlacement(code);
        resetDefault(code, series);
    };

    const activeClassName = code === motorPlacement ? "active" : "";
    const motorPlacementClassName = name.toLowerCase();
    const combinedClasses = [activeClassName, motorPlacementClassName].join(" ");
    const backgroundImage = `url(${getMotorImageURL(code, series, "thumbnail")})`;

    return (
        <button className={combinedClasses} onClick={setMotorType} style={{ backgroundImage }}>
            <div>{name.toUpperCase()}</div>
        </button>
    );
}

function MotorPlacementSelector() {
    return (
        <Box className="motor-placement-selector header-margins" mb={2}>
            <MotorPlacementOption motorPlacement={motorPlacements[MotorPlacementCode.Outboard]}></MotorPlacementOption>
            <MotorPlacementOption motorPlacement={motorPlacements[MotorPlacementCode.Inboard]}></MotorPlacementOption>
        </Box>
    );
}
