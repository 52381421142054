// Package dependencies.
import { Typography } from "@mui/material";
import React from "react";

// Local dependencies.
import CheckboxButton from "../CheckboxButton/CheckboxButton";

interface ChargerRequestOptionsProps {
    interestedInLandBasedCharger: boolean;
    onClick?: () => void;
}

// Export ChargerRequestOptions as default component.
export default ChargerRequestOptions;

/**
 * Render all available requestable options related to chargers.
 *
 * @param props The component props.
 */
function ChargerRequestOptions({ interestedInLandBasedCharger, onClick }: ChargerRequestOptionsProps) {
    return (
        <div className="charger-margin-small upon-request">
            <Typography component="h2" variant="h6">
                UPON REQUEST
            </Typography>
            <Typography component="h3" variant="h6">
                <b>LAND BASED</b> <span style={{ fontWeight: "normal" }}>DC SUPER CHARGING STATION</span>
            </Typography>
            <CheckboxButton active={interestedInLandBasedCharger} label="I'm interested" onClick={onClick} />
        </div>
    );
}
