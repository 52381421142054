// Package dependencies.
import { ClickAwayListener, Tooltip } from "@mui/material";
import React, { useState } from "react";

// Local dependencies.
import { getSaveDesignQueryString } from "../../common/utils";
import { ProductSelections } from "../../store/engines";
import { UsageInformation } from "../../types/UsageInformation";
import "./SaveDesign.scss";

interface SaveDesignProps {
    configuration: UsageInformation;
    interestedInLandBasedCharger: boolean;
    interestedInRangeExtender: boolean;
    productSelections: ProductSelections;
}

export default SaveDesign;

function SaveDesign({
    configuration,
    productSelections,
    interestedInLandBasedCharger,
    interestedInRangeExtender,
}: SaveDesignProps) {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const handleTooltipClose = () => {
        setIsTooltipOpen(false);
    };

    const handleTooltipToggle = () => {
        setIsTooltipOpen(!isTooltipOpen);
    };

    // Get the current save design query string.
    const saveDesignQueryString = getSaveDesignQueryString(
        configuration,
        productSelections,
        interestedInLandBasedCharger,
        interestedInRangeExtender
    );

    return (
        <button className="save-design-button" onClick={handleTooltipToggle}>
            {
                <Tooltip
                    placement="bottom-end"
                    open={isTooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                        <>
                            <ClickAwayListener onClickAway={handleTooltipClose}>
                                <div style={{ padding: "20px" }}>
                                    <div>
                                        <h1 className="save-design-header">Copy this link, to save your design</h1>
                                        <div>
                                            <a
                                                className="boxtext-right-blue"
                                                href={saveDesignQueryString}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {saveDesignQueryString}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </ClickAwayListener>
                        </>
                    }
                >
                    <p className="save-design-text">Save your design</p>
                </Tooltip>
            }
        </button>
    );
}
