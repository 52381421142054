export enum ScreenTypeCode {
    Dual10Inch = "DS_10",
    Dual16Inch = "DS_16",
    Single10Inch = "SS_10",
    Single16Inch = "SS_16",
}

export interface ScreenType {
    code: ScreenTypeCode;
    dual: boolean;
    name: string;
}

export const screenTypes: Record<ScreenTypeCode, ScreenType> = {
    [ScreenTypeCode.Dual10Inch]: {
        code: ScreenTypeCode.Dual10Inch,
        dual: true,
        name: "10 Inch Dual Screen",
    },
    [ScreenTypeCode.Dual16Inch]: {
        code: ScreenTypeCode.Dual16Inch,
        dual: true,
        name: "16 Inch Dual Screen",
    },
    [ScreenTypeCode.Single10Inch]: {
        code: ScreenTypeCode.Single10Inch,
        dual: false,
        name: "10 Inch Single Screen",
    },
    [ScreenTypeCode.Single16Inch]: {
        code: ScreenTypeCode.Single16Inch,
        dual: false,
        name: "16 Inch Single Screen",
    },
};
