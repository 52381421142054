import { Store, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

// We'll be using Redux Devtools. We can use the `composeWithDevTools()`
// directive so we can pass our middleware along with it
import { composeWithDevTools } from "redux-devtools-extension";

// Import the state interface and our combined reducers/sagas.
import { ApplicationState, createRootReducer } from "./";

export default function configureStore(initialState: ApplicationState): Store<ApplicationState> {
    // create the composing function for our middlewares
    const composeEnhancers = composeWithDevTools({});
    // create the redux-saga middleware

    // We'll create our store with the combined reducers/sagas, and the initial Redux state that
    // we'll be passing from our entry point.
    const store = createStore(createRootReducer(), initialState, composeEnhancers(applyMiddleware(thunk)));

    return store;
}
