// Package dependencies.
import React, { useState } from "react";

// Local dependencies.
import { getMotorImageURL } from "../../common/motorImages";
import { getCurrentProductSelections } from "../../common/utils";
import { OrderType, ProductSelections } from "../../store/engines";
import { Buyer, UsageInformation } from "../../types/UsageInformation";
import "../buttonlist.scss";
import { useLocalization } from "../Localization/LocalizationProvider";
import { useProductSelections } from "../Providers/ProductSelectionsProvider";
import SaveDesign from "./SaveDesign";
import SummaryList from "./SummaryList";

interface DisplaySystemProps {
    buyer: Buyer;
    configuration: UsageInformation;
    interestedInLandBasedCharger: boolean;
    interestedInRangeExtender: boolean;
    isMobile: boolean;
    orderType: OrderType;
    productSelections: ProductSelections;
    totalPrice: number;
}

// Export DisplaySystem as default component.
export default DisplaySystem;

function DisplaySystem({
    configuration,
    buyer,
    interestedInLandBasedCharger,
    interestedInRangeExtender,
    isMobile,
    orderType,
    totalPrice,
}: DisplaySystemProps) {
    const { currency, products } = useLocalization();
    const selections = useProductSelections();
    const [showSelectedSystem, setShowSelectedSystem] = useState<boolean>(false);
    const handleShowSelectedSystem = () => setShowSelectedSystem(!showSelectedSystem);
    const productSelections = getCurrentProductSelections(selections, products);
    const { battery, charger, motor, screen } = productSelections;
    return (
        <>
            {isMobile && !showSelectedSystem && (
                <div className="buttonlist summary-list">
                    <button style={{ background: "#fff" }} onClick={handleShowSelectedSystem}>
                        <div className="image-box">
                            <img src={getMotorImageURL(motor.placement, motor.series, "thumbnail")} alt={"motor"}></img>
                        </div>
                        <div className="selected-system-text">
                            <div>
                                <span style={{ fontWeight: "normal" }}>see your</span>
                                <span>selected system</span>
                            </div>
                        </div>
                    </button>
                </div>
            )}
            {(!isMobile || showSelectedSystem) && (
                <>
                    <SaveDesign
                        configuration={configuration}
                        interestedInLandBasedCharger={interestedInLandBasedCharger}
                        interestedInRangeExtender={interestedInRangeExtender}
                        productSelections={productSelections}
                    />
                    <SummaryList
                        acCharger={charger}
                        battery={battery}
                        buyer={buyer}
                        currencySymbol={currency.symbol}
                        motor={motor}
                        orderType={orderType}
                        screen={screen}
                        totalPrice={totalPrice}
                    />
                </>
            )}
        </>
    );
}
