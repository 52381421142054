import { countries } from "../components/Localization/regions";
import { CountryCode } from "../components/Localization/types";
import { BatteryProduct, ChargerProduct, ProductDetails, Products } from "../store/engines";
import { BatteryPackCode, batteryPacks } from "../store/engines/battery";
import { ChargerTypeCode, chargerTypes } from "../store/engines/charger";
import { MotorTypeCode, motorTypes } from "../store/engines/motor";
import { ScreenTypeCode, screenTypes } from "../store/engines/screen";
import productsEU from "./products/EU.json";

export interface Currency {
    code: CurrencyCode;
    name: string;
    symbol: string;
}

export enum CurrencyCode {
    Euro = "EUR",
    NorwegianKrone = "NOK",
    Sterling = "GBP",
    UnitedStatesDollar = "USD",
}

export const currencies: Record<CurrencyCode, Currency> = {
    [CurrencyCode.Euro]: {
        code: CurrencyCode.Euro,
        name: "Euro",
        symbol: "\u20AC",
    },
    [CurrencyCode.NorwegianKrone]: {
        code: CurrencyCode.NorwegianKrone,
        name: "Norwegian Krone",
        symbol: "kr",
    },
    [CurrencyCode.Sterling]: {
        code: CurrencyCode.Sterling,
        name: "Sterling",
        symbol: "\u00A3",
    },
    [CurrencyCode.UnitedStatesDollar]: {
        code: CurrencyCode.UnitedStatesDollar,
        name: "United States Dollar",
        symbol: "\u0024",
    },
};

export { CountryCode };

interface ProductsConfig {
    currency: CurrencyCode;
    batteries: BatteryConfig[];
    chargers: ChargerConfig[];
    motors: MotorConfig[];
    orderFee: number;
    screens: ScreenConfig[];
}

type ProductConfig<ProductCode extends string> = Omit<ProductDetails<ProductCode>, "name">;
type BatteryConfig = ProductConfig<BatteryPackCode>;
type ChargerConfig = ProductConfig<ChargerTypeCode>;
type MotorConfig = ProductConfig<MotorTypeCode>;
type ScreenConfig = ProductConfig<ScreenTypeCode>;

export interface Config extends ProductsConfig {
    country: CountryCode;
}

export interface Locale {
    country: CountryCode;
    currency: CurrencyCode;
    products: Products;
}

export type { Products };

const defaultProductConfig = productsEU as ProductsConfig;
export const defaultLocale = getLocaleFromConfig({ country: CountryCode.Norway, ...defaultProductConfig });

export function getLocaleFromConfig({
    batteries,
    chargers,
    country,
    currency,
    motors,
    orderFee,
    screens,
}: Config): Locale {
    const products: Products = {
        batteries: batteries.map(
            (config): BatteryProduct => ({
                ...config,
                ...batteryPacks[config.code],
            })
        ),
        chargers: chargers.map(
            (config): ChargerProduct => ({
                ...config,
                ...chargerTypes[config.code],
            })
        ),
        motors: motors.map((config) => ({
            ...config,
            ...motorTypes[config.code],
        })),
        orderFee,
        screens: screens.map((config) => ({
            ...config,
            ...screenTypes[config.code],
        })),
    };
    return { country, currency, products };
}

export async function getLocale(code: CountryCode): Promise<Locale | null> {
    try {
        const country = countries[code];
        const products: ProductsConfig = (await import(`./products/${country.products}.json`)).default;
        return getLocaleFromConfig({ country: country.code, ...products });
    } catch {}
    return null;
}
