import { MotorPlacementCode } from "./motor";

// Disclaimers.
const disclaimerWeight =
    "Above weights are indicative weight for a basic system and actual weight will vary depending on the configuration of the installation.";
const disclaimerBattery = `*Battery weight (Batteries + cooling liquid). ${disclaimerWeight}`;
const disclaimerRange = `**Range is dependent on multiple factors such as hull performance, boat configuration and external conditions (wind, temperature, etc.). Range stated is indicative only and calculated based on 80% DoD (Depth of Discharge).`;
const disclaimerWeightOutboard = `*Motor weight (Motor + cables/accessories & cooling liquid) excl. batteries & propellor. ${disclaimerWeight}`;
const disclaimerWeightInboard = disclaimerWeightOutboard.replace(" & propellor", "");

export type DisclaimerTexts = {
    [key in "battery" | "motor"]: {
        [key in MotorPlacementCode]: string;
    };
};

export interface Texts {
    disclaimer: DisclaimerTexts;
    screenText: string;
    peak: string;
    continuous: string;
}

export const texts: Texts = {
    disclaimer: {
        battery: {
            [MotorPlacementCode.Outboard]: `${disclaimerBattery}\n\n${disclaimerRange}`,
            [MotorPlacementCode.Inboard]: `${disclaimerBattery}\n\n${disclaimerRange}`,
        },
        motor: {
            [MotorPlacementCode.Inboard]: `${disclaimerWeightInboard}\n\n${disclaimerRange}`,
            [MotorPlacementCode.Outboard]: `${disclaimerWeightOutboard}\n\n${disclaimerRange}`,
        },
    },
    screenText:
        "Touchscreen with FM & DAB radio, built in amplifier, weather updates, full marine navigation (Navionics card (not included)), Bluetooth, Wi-Fi and 4G connectivity with possible Over-the-Air updates.\n* Real-time motor and battery monitoring. Shows CO2 and cost savings.\n* Trip log for Trip and total including kWh used and average usage in kWh/nm.\n* Charging Management from screen.",
    peak: "All batteries and electric motors have a continuous and peak discharge. The Peak discharge is an limited time where the batteries and motor can be used with an higher current output to achieve higher power than at continuous. Sometimes the output can be up to double of that in continuous. The length of this peak discharge is mainly decided by temperature and Evoy’s smart control system will monitor and gradually reduce power to continuous discharge when these limits are reached.",
    continuous:
        "All batteries and electric motors have a continuous and peak discharge. The continuous discharge is the max current giving what power that can be used continuous. This will vary some with temperature.",
};
