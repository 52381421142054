import React from "react";
import "./MotorImage.scss";

import { getMotorImageURL } from "../../common/motorImages";
import { MotorPlacementCode, MotorSeriesCode } from "../../store/engines/motor";

interface MotorImageProps {
    placement: MotorPlacementCode;
    series: MotorSeriesCode;
}

function MotorImage({ placement, series }: MotorImageProps): JSX.Element {
    const motorClassName = placement === MotorPlacementCode.Inboard ? "inboard" : "outboard";

    return (
        <div className={`motor-image ${motorClassName}`}>
            <img src={getMotorImageURL(placement, series, "main")} alt={`${placement} motor`} />
        </div>
    );
}

export default MotorImage;
