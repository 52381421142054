// Package dependencies.
import { matchIsValidTel } from "mui-tel-input";

// Local dependencies.
import { CurrencyCode } from "../localization/helper";
import { ContactInformation, MotorPlacement, OrderType, ProductSelections } from "../store/engines";
import { batteryTypes } from "../store/engines/battery";
import { MotorPlacementCode, motorPlacements } from "../store/engines/motor";
import { Buyer } from "../types/UsageInformation";
import { getApiClient } from "./apiHelper";
import { applyVatIfNeeded, findOrderType } from "./utils";

export type FormErrors = Partial<
    Record<
        | "Businessname"
        | "Email"
        | "Description"
        | "Firstname"
        | "Lastname"
        | "Phone"
        | "LeadSource"
        | "LeadSourceOther"
        | "PrivacyPolicy"
        | "SubscribeToNewsletter",
        string[]
    >
>;

export interface OrderRequest {
    segment: string | null;
    items: OrderItem[];
    content: string | null;
    formErrors: any | null;
    isSubmitting: boolean;
    orderType: OrderType | null;
}

interface OrderRequestData {
    company: string | null;
    country: string | null;
    currency?: CurrencyCode;
    description: string | null;
    email: string | null;
    firstName: string | null;
    lastName: string | null;
    interestedInLandBasedCharger: boolean;
    interestedInRangeExtender: boolean;
    items: OrderItem[];
    motorPlacement: MotorPlacement;
    orderType: OrderType;
    phone: string | null;
    privacyPolicy: boolean;
    segment: string;
    leadSource: string | null;
    leadSourceOther: string | null;
    subscribeToNewsletter: boolean;
}

export interface OrderItem {
    code: string;
    name: string;
    price: number;
}

const regexIsValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function getOrderItems({ battery, charger, motor, screen }: ProductSelections, shouldApplyVat: boolean): OrderItem[] {
    const motorPlacement = motorPlacements[motor.placement];
    const batteryType = batteryTypes[battery.type];

    return [
        {
            code: motor.code,
            name: `${motorPlacement.name} ${motor.name}`,
            price: applyVatIfNeeded(motor.price, shouldApplyVat),
        },
        {
            code: battery.code,
            name: `${batteryType.name} ${battery.name}`,
            price: applyVatIfNeeded(battery.price, shouldApplyVat),
        },
        {
            code: charger.code,
            name: `${charger.name} Charger`,
            price: applyVatIfNeeded(charger.price, shouldApplyVat),
        },
        {
            code: screen.code,
            name: screen.name,
            price: applyVatIfNeeded(screen.price, shouldApplyVat),
        },
    ];
}

function getOrderRequest(
    productSelections: ProductSelections,
    { value: segment, vat: shouldApplyVat }: Buyer,
    {
        boatDescription: description,
        businessname: company,
        country,
        email,
        firstname: firstName,
        lastname: lastName,
        phone,
        privacyPolicy,
        subscribeToNewsletter,
        leadSource,
        leadSourceOther,
    }: ContactInformation,
    interestedInLandBasedCharger: boolean,
    interestedInRangeExtender: boolean,
    currency: CurrencyCode
): OrderRequestData {
    const items = getOrderItems(productSelections, shouldApplyVat);
    const motorPlacement =
        productSelections.motor.placement === MotorPlacementCode.Inboard
            ? MotorPlacement.Inboard
            : MotorPlacement.Outboard;

    const orderType = findOrderType(productSelections);

    return {
        company,
        country,
        currency,
        description,
        email,
        firstName,
        interestedInLandBasedCharger,
        interestedInRangeExtender,
        items,
        lastName,
        motorPlacement,
        orderType,
        phone,
        privacyPolicy,
        segment,
        leadSource,
        leadSourceOther,
        subscribeToNewsletter,
    };
}

export interface PaymentIntentMetaData {
    currency: CurrencyCode;
    interestedInLandBasedCharger: boolean;
    interestedInRangeExtender: boolean;
    items: OrderItem[];
    motorPlacement: MotorPlacement;
    orderType: OrderType;
    segment: string;
}

/**
 * Helper to retrieve required meta data for a payment intent.
 * TODO: this could be severly simplified, requires a refactor of Order on the
 * backend.
 */
export const getPaymentIntentMetaData = (
    currency: CurrencyCode,
    productSelections: ProductSelections,
    buyer: Buyer,
    interestedInLandBasedCharger: boolean,
    interestedInRangeExtender: boolean
): PaymentIntentMetaData => {
    const items = getOrderItems(productSelections, buyer.vat);
    const {
        motor: { placement },
    } = productSelections;
    const orderType = findOrderType(productSelections);
    const motorPlacement = placement === MotorPlacementCode.Inboard ? MotorPlacement.Inboard : MotorPlacement.Outboard;

    return {
        currency,
        segment: buyer.value,
        items,
        orderType,
        motorPlacement,
        interestedInLandBasedCharger,
        interestedInRangeExtender,
    };
};

export const submitOrderRequest = async (
    productSelections: ProductSelections,
    buyer: Buyer,
    contactInfo: ContactInformation,
    interestedInLandBasedCharger: boolean,
    interestedInRangeExtender: boolean,
    currencyCode: CurrencyCode
) => {
    var orderRequest = getOrderRequest(
        productSelections,
        buyer,
        contactInfo,
        interestedInLandBasedCharger,
        interestedInRangeExtender,
        currencyCode
    );

    var apiClient = getApiClient();
    return await apiClient.post<OrderRequest>("/orders", orderRequest);
};

export const getOrderValidationErrors = (contactInfo: ContactInformation, orderType: OrderType): FormErrors => {
    const formErrors: FormErrors = {};

    if (!contactInfo.boatDescription) {
        formErrors["Description"] = ["Please enter a description"];
    }
    if (!contactInfo.firstname) {
        formErrors["Firstname"] = ["Please enter first name"];
    }
    if (!contactInfo.lastname) {
        formErrors["Lastname"] = ["Please enter last name"];
    }
    if (!contactInfo.businessname) {
        formErrors["Businessname"] = ["Please enter business name"];
    }
    if (!contactInfo.email) {
        formErrors["Email"] = ["Please enter an email"];
    } else if (!regexIsValidEmail.test(contactInfo.email)) {
        formErrors["Email"] = ["Please enter a valid email"];
    }
    if (contactInfo.phone == null) {
        formErrors["Phone"] = ["Please enter a phone number"];
    } else if (!matchIsValidTel(contactInfo.phone)) {
        formErrors["Phone"] = ["Please enter a valid phone number"];
    }
    if (!contactInfo.privacyPolicy) {
        formErrors.PrivacyPolicy = ["Please read and consent to our Privacy Policy"];
    }
    if (contactInfo.leadSource == null) {
        console.log(contactInfo.leadSource);
        formErrors["LeadSource"] = ["Please tell us where you heard about us"];
    }
    if (contactInfo.leadSource == "Other") {
        if (!contactInfo.leadSourceOther) {
            formErrors["LeadSourceOther"] = ["Please enter where you heard about us"];
        }
    }

    return formErrors;
};
