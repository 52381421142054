import { StrictMode } from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import configureStore from "./store/configureStore";

import "./layout.scss";
import "./assets/hco_fonts.css";

const initialState = window.INITIAL_REDUX_STATE;
const store = configureStore(initialState);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <StrictMode>
        <App store={store} />
    </StrictMode>
);
