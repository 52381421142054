import { BatteryPackCode } from "./battery";
import { ChargerTypeCode } from "./charger";

export enum MotorSeriesCode {
    BreezeSeries = "BS",
    GaleSeries = "GS",
    StormSeries = "SS",
    HurricaneSeries = "HS",
}

export interface MotorPlacement {
    code: MotorPlacementCode;
    name: string;
}

export enum MotorPlacementCode {
    Inboard = "I",
    Outboard = "O",
}

export const motorPlacements: Record<MotorPlacementCode, MotorPlacement> = {
    [MotorPlacementCode.Inboard]: {
        code: MotorPlacementCode.Inboard,
        name: "Inboard",
    },
    [MotorPlacementCode.Outboard]: {
        code: MotorPlacementCode.Outboard,
        name: "Outboard",
    },
};

export enum MotorTypeCode {
    InboardBreezeSeries = "I_BS",
    InboardGaleSeries = "I_GS",
    InboardStormSeries = "I_SS",
    InboardHurricaneSeries = "I_HS",
    OutboardBreezeSeries = "O_BS",
    OutboardGaleSeries = "O_GS",
    OutboardStormSeries = "O_SS",
    OutboardHurricaneSeries = "O_HS",
}

export interface MotorType {
    batteries: BatteryPackCode[];
    chargers: ChargerTypeCode[];
    code: MotorTypeCode;
    continuousHP: number | null;
    continuousKW: number | null;
    name: string;
    peakHP: number;
    peakKW: number;
    placement: MotorPlacementCode;
    series: MotorSeriesCode;
    weightEquipmentInKg?: number;
    weightInKg: number | null;
}

export const motorTypes: Record<MotorTypeCode, MotorType> = {
    [MotorTypeCode.InboardBreezeSeries]: {
        batteries: [
            BatteryPackCode.V400EnergyMedium,
            BatteryPackCode.V400EnergyLarge,
            BatteryPackCode.V400EnergyXLarge,
        ],
        chargers: [ChargerTypeCode.AC1, ChargerTypeCode.AC3],
        code: MotorTypeCode.InboardBreezeSeries,
        continuousHP: 120,
        continuousKW: 90,
        name: "Breeze Series",
        peakHP: 185,
        peakKW: 140,
        placement: MotorPlacementCode.Inboard,
        series: MotorSeriesCode.BreezeSeries,
        weightEquipmentInKg: 50,
        weightInKg: 180,
    },
    [MotorTypeCode.InboardGaleSeries]: {
        batteries: [],
        chargers: [],
        code: MotorTypeCode.InboardGaleSeries,
        continuousHP: 200,
        continuousKW: 150,
        name: "Gale Series",
        peakHP: 300,
        peakKW: 225,
        placement: MotorPlacementCode.Inboard,
        series: MotorSeriesCode.GaleSeries,
        weightInKg: 215,
    },
    [MotorTypeCode.InboardStormSeries]: {
        batteries: [
            BatteryPackCode.V800EnergyMedium,
            BatteryPackCode.V800EnergyLarge,
            BatteryPackCode.V800EnergyXLarge,
        ],
        chargers: [ChargerTypeCode.AC4, ChargerTypeCode.AC5],
        code: MotorTypeCode.InboardStormSeries,
        continuousHP: 300,
        continuousKW: 222,
        name: "Storm Series",
        peakHP: 600,
        peakKW: 450,
        placement: MotorPlacementCode.Inboard,
        series: MotorSeriesCode.StormSeries,
        weightInKg: 278,
        weightEquipmentInKg: 90,
    },
    [MotorTypeCode.InboardHurricaneSeries]: {
        batteries: [
            BatteryPackCode.V800EnergyMedium,
            BatteryPackCode.V800EnergyLarge,
            BatteryPackCode.V800EnergyXLarge,
        ],
        chargers: [ChargerTypeCode.AC4, ChargerTypeCode.AC5],
        code: MotorTypeCode.InboardHurricaneSeries,
        continuousHP: 400,
        continuousKW: 300,
        name: "Hurricane Series",
        peakHP: 800,
        peakKW: 600,
        placement: MotorPlacementCode.Inboard,
        series: MotorSeriesCode.HurricaneSeries,
        weightEquipmentInKg: 110,
        weightInKg: 290,
    },
    [MotorTypeCode.OutboardBreezeSeries]: {
        batteries: [
            BatteryPackCode.V400EnergyMedium,
            BatteryPackCode.V400EnergyLarge,
            BatteryPackCode.V400EnergyXLarge,
        ],
        chargers: [ChargerTypeCode.AC1, ChargerTypeCode.AC3],
        code: MotorTypeCode.OutboardBreezeSeries,
        continuousHP: 120,
        continuousKW: 90,
        name: "Breeze Series",
        peakHP: 185,
        peakKW: 140,
        placement: MotorPlacementCode.Outboard,
        series: MotorSeriesCode.BreezeSeries,
        weightEquipmentInKg: 80,
        weightInKg: 203.5,
    },
    [MotorTypeCode.OutboardGaleSeries]: {
        batteries: [],
        chargers: [],
        code: MotorTypeCode.OutboardGaleSeries,
        continuousHP: 200,
        continuousKW: 150,
        name: "Gale Series",
        peakHP: 400,
        peakKW: 300,
        placement: MotorPlacementCode.Outboard,
        series: MotorSeriesCode.GaleSeries,
        weightInKg: null,
    },
    [MotorTypeCode.OutboardStormSeries]: {
        batteries: [
            BatteryPackCode.V800EnergyMedium,
            BatteryPackCode.V800EnergyLarge,
            BatteryPackCode.V800EnergyXLarge,
        ],
        chargers: [ChargerTypeCode.AC4, ChargerTypeCode.AC5],
        code: MotorTypeCode.OutboardStormSeries,
        continuousHP: 300,
        continuousKW: 225,
        name: "Storm Series",
        peakHP: 600,
        peakKW: 450,
        placement: MotorPlacementCode.Outboard,
        series: MotorSeriesCode.StormSeries,
        weightInKg: 265,
        weightEquipmentInKg: 75,
    },
    [MotorTypeCode.OutboardHurricaneSeries]: {
        batteries: [],
        chargers: [],
        code: MotorTypeCode.OutboardHurricaneSeries,
        continuousHP: 400,
        continuousKW: 300,
        name: "Hurricane Series",
        peakHP: 800,
        peakKW: 600,
        placement: MotorPlacementCode.Outboard,
        series: MotorSeriesCode.HurricaneSeries,
        weightInKg: null,
    },
};
