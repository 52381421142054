export interface Boat {
    boatCode: BoatCode;
    text: string;
    hull: Hull[];
}

export interface Buyer {
    buyerCode: BuyerCode;
    text: string;
    valueUnit: string;
    enova: boolean;
    value: string;
    invoice: boolean;
    vat: boolean;
    private: boolean;
}

export enum BoatCode {
    BB = "BB",
    BR = "BR",
    CC = "CC",
    DC = "DC",
    FB = "FB",
    JS = "JS",
    RIB = "RIB",
    SB = "SB",
    SD = "SD",
    SWS = "SWS",
    SPB = "SPB",
    YACHT = "YACHT",
    OTHERS = "OTHERS",
}

export enum BuyerCode {
    PNVAT = "PNVAT",
    PONVAT = "PONVAT",
    PRON = "PRON",
    PROON = "PROON",
}

export interface UsageInformation {
    boatCode: BoatCode;
    buyerCode: BuyerCode;
    consumption: number;
    fuelCode: FuelCode;
    hours: number;
    hullCode: HullCode;
    speed: number;
}

export interface Fuel {
    fuelCode: FuelCode;
    text: string;
    type: FuelType;
}

export enum FuelType {
    Diesel = "Diesel",
    Petrol = "Petrol",
}

export enum FuelCode {
    DIESEL = "DIESEL",
    PETROL = "PETROL",
}

export interface Hull {
    hullCode: HullCode;
    available: boolean;
    img: string;
    text: string;
}

export enum HullCode {
    DH = "DH",
    PH = "PH",
    SDH = "SDH",
}
